import { JsonValidateResult, TaskStatus, ValidationReport } from '@models';
import { CodeView } from '@shared/modules/code-view/models/code-view.model';
import { BehaviorSubject, Observable } from 'rxjs';

export class JsonValidation {
  constructor(
    public fileName: string,
    public status: Observable<TaskStatus>
  ) {}

  private _jsonSubject = new BehaviorSubject<JsonValidateResult | null>(null);
  jsonValidateResult = this._jsonSubject.asObservable();
  jsonCodeView?: CodeView;
  validationReport?: ValidationReport;

  finished(result: JsonValidateResult) {
    if (result?.fileName === this.fileName) {
      this.jsonCodeView = result.jsonCodeView;
      this.validationReport = result.validationReport;
    }

    this._jsonSubject.next(result);
  }
}
