export namespace ApiExportDTO {
  export interface ApiExportItem {
    name: string;
    type: ApiExportType;
    url: string;
  }

  export enum ApiExportType {
    VALIDATION = 'VALIDATION',
    RUN_FUNCTION = 'RUN_FUNCTION',
    REGULATION_REPORT = 'REGULATION_REPORT',
    INGESTION = 'INGESTION',
  }
}
