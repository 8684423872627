import { Inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { SERVER_ERROR_FALLBACK } from '@configs';
import { catchError, map, Observable, of } from 'rxjs';

import { StatusService } from '../services/status.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveServerGuard {
  constructor(
    private _statusService: StatusService,
    @Inject(SERVER_ERROR_FALLBACK) private _fallbackRoute: UrlTree
  ) {}

  canActivateChild(): Observable<boolean | UrlTree> {
    return this._statusService.getStatus().pipe(
      catchError(() => of({ ReadinessCheck: { healthy: false } })),
      map(status => {
        if (status.ReadinessCheck.healthy) {
          return true;
        }
        return this._fallbackRoute;
      })
    );
  }
}
