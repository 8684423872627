<app-status [state]="state()" />
<div class="status-text">
  <strong class="color-75">{{ state().text }}</strong>
  <small
    @inOutAnimation
    *ngIf="description() as description"
    class="color-50 text-ellipsis"
  >
    {{ description }}
  </small>
</div>
<button
  mat-stroked-button
  class="ml-16"
  @slideIn
  *ngIf="canRerun()"
  (click)="onRerun()"
>
  <fa-icon icon="arrow-rotate-right" />
  <span>Rerun</span>
</button>
