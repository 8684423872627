<div
  class="table-actions"
  [ngClass]="{
    'table-actions-available': options.canHideColumns || options.filter,
  }"
>
  <app-text-input
    class="filter"
    *ngIf="options.filter"
    [disabled]="filterDisabled"
    maxWidth="300"
    [value]="quickFilterText"
    (valueChange)="updateFilter($event)"
  >
    <button mat-icon-button textInputPrefix disabled>
      <fa-icon icon="filter" />
    </button>
  </app-text-input>

  <ng-content select="header" />

  <div class="table-actions-fixed" *ngIf="{ disabled: noData$ | async } as vm">
    <button
      *ngIf="options.canDownload"
      (click)="downloadDataset()"
      [disabled]="vm.disabled"
      class="file-download-button"
      mat-stroked-button
      title="Download"
    >
      <fa-icon icon="download" />
      <span>Download</span>
    </button>
    <app-table-column-menu
      *ngIf="options.canHideColumns"
      [disabled]="vm.disabled"
    />
  </div>
</div>

<ng-content select="[rosettaTableBanner]" />

<ng-content select="[summary]" />

<ng-template #noColsTemplate>
  <ng-content select="[rosettaNoCols]" />
</ng-template>

<ag-grid-angular
  *ngIf="initialised() && this.colDefs?.length; else noColsTemplate"
  #agGrid
  [animateRows]="true"
  [ngClass]="classes$ | async"
  [editType]="options.editType"
  [domLayout]="options.domLayout"
  [rowHeight]="options.rowHeight"
  [rowClassRules]="options.rowClassRules"
  [readOnlyEdit]="options.readOnlyEdit || null"
  [getRowId]="options.getRowId"
  [suppressRowHoverHighlight]="options.suppressRowHoverHighlight || null"
  [components]="components"
  [gridOptions]="gridOptionsMerged"
  [columnDefs]="colDefs"
  [selectionColumnDef]="selectionColDef"
  [defaultColDef]="defaultColDefMerged"
  [quickFilterText]="quickFilterText"
  [doesExternalFilterPass]="options.doesExternalFilterPass"
  [isExternalFilterPresent]="options.isExternalFilterPresent"
  [initialState]="initialState"
  (gridReady)="onGridReady($event)"
  (rowClicked)="onRowClicked($event)"
  (stateUpdated)="onStateUpdated($event)"
/>
