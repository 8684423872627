import { Pipe, PipeTransform, QueryList, TemplateRef } from '@angular/core';
import { DiagnosticPanelGroupDefDirective } from '@shared/modules/diagnostic-panel/diagnostic-panel-def.directive';
import { DiagnosticGroup } from '@shared/modules/diagnostic-panel/diagnostic.model';

@Pipe({
  name: 'diagnosticTemplate',
})
export class DiagnosticPanelGroupTemplatePipe implements PipeTransform {
  transform(
    templates: QueryList<DiagnosticPanelGroupDefDirective>,
    groupDef: DiagnosticGroup['def']
  ): TemplateRef<any> | null {
    return (
      templates.find(template => template.groupDef === groupDef)?.templateRef ||
      null
    );
  }
}
