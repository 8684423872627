import { map, OperatorFunction } from 'rxjs';
import { TestPackGridSelection } from '../test-pack-grid-selection.model';
import { TransformDTO } from '../transform-dto.model';

export const fromTestPackGridSelectionDTO = (): OperatorFunction<
  TransformDTO.TestPackGridSelection[],
  TestPackGridSelection[]
> => {
  return map(testPackGridSelections =>
    testPackGridSelections.map(({ pipelineDef, testPackDef }) => ({
      pipelineDef: {
        ...pipelineDef,
        id: pipelineDef.id.id,
      },
      testPackDef: {
        ...testPackDef,
        id: testPackDef.id.id,
      },
    }))
  );
};
