import { checkboxColDef } from './checkbox-col-defs';
import { dataColDef } from './data-col-defs';
import { sampleNameColDef } from './sample-name-col-defs';

export const getColDefMap = () =>
  ({
    checkbox: checkboxColDef(),
    sampleName: sampleNameColDef(),
    data: dataColDef(),
  }) as const;
