import { Injectable } from '@angular/core';
import { TaskService } from '@core/services';
import {
  CodeCompilationResult,
  Task,
  TaskNotification,
  TaskStatus,
} from '@models';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class StaticCompilationListenerService implements TaskListeners {
  constructor(private _taskService: TaskService) {}

  action(task: TaskNotification) {
    if (task.task !== Task.StaticJavaCompilation) {
      return;
    }

    if (task.status === 'STARTED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Started
      );
    }

    if (task.status === 'FINISHED') {
      const staticJavaCompileResult = task.payload as CodeCompilationResult;
      const staticJavaCompileStatus = staticJavaCompileResult.success
        ? TaskStatus.Finished
        : TaskStatus.Error;
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        staticJavaCompileStatus
      );
    }
  }
}
