import { ActionCellComponent } from '@shared/modules/rosetta-table/cell-components/action-cell/action-cell.component';
import { ProjectCellComponent } from '@shared/modules/rosetta-table/cell-components/project-cell/project-cell.component';
import { ProjectVersionCellComponent } from '@shared/modules/rosetta-table/cell-components/project-version-cell/project-version-cell.component';
import { TextCellComponent } from '@shared/modules/rosetta-table/cell-components/text-cell/text-cell.component';
import { PackagePlanEditorComponent } from './../editor-components/package-plan-editor/package-plan-editor.component';
import { EditRowCellComponent } from './edit-row-cell/edit-row-cell.component';

export const rosettaTableCellComponentList = {
  actionCellComponent: ActionCellComponent,
  editRowCellComponent: EditRowCellComponent,
  packagePlanEditorComponent: PackagePlanEditorComponent,
  projectCellComponent: ProjectCellComponent,
  projectVersionCellComponent: ProjectVersionCellComponent,
  textCellComponent: TextCellComponent,
};
