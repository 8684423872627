import { inject } from '@angular/core';
import { globalApiCacheClear } from '@configs';
import { LanguageServerService } from '@core/services/language-server.service';
import { Task } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppActions, TaskActions } from '@store/index';
import * as RouterActions from '@store/router/router.actions';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { catchError, filter, first, map, of, switchMap, tap } from 'rxjs';

export const goToSymbol = createEffect(
  (
    actions$ = inject(Actions),
    languageServerService = inject(LanguageServerService)
  ) => {
    return actions$.pipe(
      ofType(WorkspaceActions.goToSymbol),
      switchMap(({ classIdentification }) =>
        languageServerService.goToSymbol(classIdentification).pipe(
          map(() =>
            RouterActions.goto({
              extras: {
                queryParams: {
                  view: 'textual',
                  topPanel: true,
                },
              },
            })
          ),
          catchError(() => of(WorkspaceActions.goToSymbolFailure()))
        )
      )
    );
  },
  { functional: true }
);

export const gotoSymbolFailure = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.goToSymbolFailure),
      map(() =>
        AppActions.showBasicErrorMsg({
          message: 'Unable to find symbol in the document',
        })
      )
    );
  },
  { functional: true }
);

/*
Check if a websocket close is unexpected.
Unexpected close is when a websocket closes and the workspace state is not ready
 */
export const unexpectedWebsocketClose = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.websocketClose),
      switchMap(() =>
        store.select(WorkspaceSelectors.selectWorkspaceReady).pipe(
          first(),
          // When a websocket is disconnected during loading navigate back to the workspace manager
          filter(workspaceReady => workspaceReady),
          map(() => AppActions.disconnectFromWorkspace())
        )
      )
    );
  },
  { functional: true }
);

/*
ts-cacheable:
Clear global cache when we receive a EngineInitialisation task
*/
export const clearWorkspaceApiCache = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(TaskActions.taskFinished),
      filter(({ name }) => name === Task.ExecutionEngineInitialisation),
      tap(() => globalApiCacheClear())
    );
  },
  { functional: true, dispatch: false }
);
