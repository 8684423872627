import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { getDialogOptions } from '@configs';
import {
  DialogComponent,
  IDomainModelDetails,
  IModelDocument,
  IProjectLicenseDialogData,
  LoadingState,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { Observable, first, map, tap } from 'rxjs';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { ProjectLogoModule } from '../project-logo/project-logo.module';
import { StopPropagationModule } from '../stop-propagation/stop-propagation.module';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    LoadingSpinnerModule,
    ProjectLogoModule,
    StopPropagationModule,
  ],
  selector: 'app-project-licensing-terms-dialog',
  templateUrl: './project-licensing-terms-dialog.component.html',
  styleUrls: ['./project-licensing-terms-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLicensingTermsDialogComponent
  implements DialogComponent, OnInit, OnDestroy
{
  constructor(
    private _store: Store,
    private _dialogRef: MatDialogRef<ProjectLicensingTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProjectLicenseDialogData
  ) {}

  @ViewChildren(MatCheckbox) checkboxes?: QueryList<MatCheckbox>;

  documentArray = new FormArray<FormControl<boolean>>([]);

  isLoading$ = this._store.select(WorkspaceSelectors.selectDocumentState).pipe(
    map(state => state === LoadingState.LOADING),
    tap(isLoading => this.documentArray[isLoading ? 'disable' : 'enable']())
  );

  static options(data: IProjectLicenseDialogData): MatDialogConfig {
    return getDialogOptions('sm', {
      disableClose: data.disableClose,
      data,
    });
  }

  get firstView(): boolean {
    return this.data.modelDocumentData.isFirst;
  }

  get modelName(): string {
    return this.data.modelDocumentData.modelName;
  }

  documents(documentIndex: number): IModelDocument {
    return this.data.modelDocumentData.documents[documentIndex];
  }

  ngOnInit(): void {
    this._store.dispatch(
      WorkspaceActions.openProjectLicenseDialog({
        dialogId: this._dialogRef.id,
      })
    );

    // Assumption: read only mode not possible unless all documents accepted
    this.data.modelDocumentData.documents.forEach(() => {
      this.documentArray.push(
        new UntypedFormControl(
          { value: this.data.readOnly, disabled: this.data.readOnly },
          Validators.requiredTrue
        )
      );
    });
  }

  ngOnDestroy(): void {
    this._store.dispatch(WorkspaceActions.closeProjectLicenseDialogSuccess());
  }

  toggleCard(index: number): void {
    const checkbox = this.checkboxes?.get(index);
    if (checkbox && !checkbox.disabled) {
      checkbox.toggle();
    }
  }

  onCancel(): void {
    if (this.data.disableClose) {
      this._store.dispatch(WorkspaceActions.cancelAcceptModelDocuments());
    } else {
      this._dialogRef.close();
    }
  }

  onSubmit(): void {
    const { modelId, documents } = this.data.modelDocumentData;
    modelId &&
      this._store.dispatch(
        WorkspaceActions.acceptModelDocuments({
          modelId,
          documents,
        })
      );
  }

  getModel(modelId: ModelInstanceId): Observable<IDomainModelDetails> {
    return this._store
      .select(WorkspaceSelectors.getDomainModel(modelId))
      .pipe(first());
  }

  firstViewOrReadOnlyOrDefault(): 'first' | 'ro' | '' {
    if (this.firstView) {
      return 'first';
    } else if (this.data.readOnly) {
      return 'ro';
    }
    return '';
  }
}
