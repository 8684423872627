import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WORKSPACE_READY } from '@configs';
import { WorkspaceService } from '@core/services';
import { LanguageServerService } from '@core/services/language-server.service';
import { PanelStateService } from '@core/services/panel-state.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { ProjectLicensingTermsDialogComponent } from '@shared/modules/project-licensing-terms-dialog/project-licensing-terms-dialog.component';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { distinctUntilChanged, first, map, switchMap, tap } from 'rxjs';

/*
Check router event if workspace has changed and if it has load workspace
 */
export const loadWorkspace = createEffect(
  (actions$ = inject(Actions), workspaceService = inject(WorkspaceService)) => {
    return actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(({ payload }) => payload.routerState.params.workspaceName),
      distinctUntilChanged(),
      tap((name: string | undefined) => {
        if (name) {
          workspaceService.load({ name });
        } else {
          workspaceService.close();
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const workspaceReady = createEffect(
  (
    actions$ = inject(Actions),
    panelStateService = inject(PanelStateService)
  ) => {
    return actions$.pipe(
      ofType(WorkspaceActions.workspaceReady),
      tap(() => panelStateService.send(WORKSPACE_READY))
    );
  },
  { functional: true, dispatch: false }
);

export const setWorkspaceItemInEditor$ = createEffect(
  (
    actions$ = inject(Actions),
    languageService = inject(LanguageServerService)
  ) => {
    return actions$.pipe(
      ofType(WorkspaceActions.selectWorkspaceItem),
      tap(({ uri }) => languageService.selectItem(uri))
    );
  },
  { functional: true, dispatch: false }
);

export const switchWorkspace = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(WorkspaceActions.switchWorkspace),
      switchMap(() =>
        store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
          first(isNotNull),
          map(info =>
            WorkspaceActions.checkModelDocuments({
              modelId: info.modelId,
              disableClose: true,
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const checkModelDocuments = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    dialog = inject(MatDialog)
  ) => {
    return actions$.pipe(
      ofType(WorkspaceActions.checkModelDocuments),
      switchMap(({ modelId, disableClose }) =>
        store
          .select(WorkspaceSelectors.getModelDocumentDialogData(modelId))
          .pipe(
            first(modelDocumentData => modelDocumentData !== undefined),
            tap(modelDocumentData => {
              if (modelDocumentData && modelDocumentData.documents.length > 0) {
                dialog.open(
                  ProjectLicensingTermsDialogComponent,
                  ProjectLicensingTermsDialogComponent.options({
                    modelDocumentData,
                    disableClose,
                    readOnly: false,
                  })
                );
              }
            }),
            map(() => WorkspaceActions.checkModelDocumentsSuccess())
          )
      )
    );
  },
  { functional: true }
);
