<header mat-dialog-title>
  <h2>Downloads</h2>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <fa-icon icon="times" />
  </button>
</header>
<mat-action-list>
  <button
    mat-list-item
    class="pl-24"
    *ngFor="let d of downloads; let i = index"
    lines="1"
    (click)="download(d)"
    name="{{ d }}"
  >
    <span class="flex-row-center">
      <p class="spacer mb-0">{{ d }}</p>
      <fa-icon icon="cloud-download-alt" />
    </span>
  </button>
</mat-action-list>
