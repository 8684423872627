import { map, OperatorFunction } from 'rxjs';
import { PipelineDef } from '../pipeline-def.model';
import { TransformDTO } from '../transform-dto.model';

export const fromPipelineDefDTO = (): OperatorFunction<
  TransformDTO.PipelineDef[],
  PipelineDef[]
> => {
  return map(dtoList =>
    dtoList.map(dto => ({
      ...dto,
      id: dto.id.id,
    }))
  );
};
