import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  numberAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontsModule } from '@app/fonts/fonts.module';
import { InputValueDirective } from '@shared/directives';

@Component({
  standalone: true,
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FontsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    InputValueDirective,
  ],
})
export class TextInputComponent {
  @Input() value: string | undefined = '';
  @Input() label = 'Filter';
  @Input() disabled = false;
  @Input({ transform: numberAttribute }) maxWidth?: number;

  @Output() valueChange = new EventEmitter<string>();

  onValueChange(value = '') {
    this.value = value;
    this.valueChange.emit(value);
  }
}
