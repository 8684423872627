import { Injectable } from '@angular/core';
import { FILE_TYPE_CONFIG } from '@configs';
import { AuthService } from '@core/services';
import { FileType } from '@models';

@Injectable()
export class FileTypeService {
  constructor(private _auth: AuthService) {}

  get fileType(): FileType[] {
    return FILE_TYPE_CONFIG.filter(
      config =>
        config.permissions === undefined ||
        config.permissions.some(permission => this._auth.has(permission))
    );
  }
}
