import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Optional,
  SkipSelf,
  ViewEncapsulation,
} from '@angular/core';
import { CollapsiblePanelComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel/collapsible-panel.component';

@Component({
  selector: 'app-collapsible-panel-toggle-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `<button mat-icon-button>
    <fa-icon
      [icon]="['fas', (parent.isOpen$ | async) ? 'compress-alt' : 'expand-alt']"
    />
  </button>`,
})
export class CollapsiblePanelToggleButtonComponent {
  constructor(
    @SkipSelf() @Optional() public parent: CollapsiblePanelComponent
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();
    this.parent?.toggle();
  }
}
