import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { Store } from '@ngrx/store';
import { AppSelectors } from '@store/selectors';
import { first, Observable, switchMap } from 'rxjs';

@Injectable()
export class HideProprietaryModelsWhenPresentingInterceptor
  implements HttpInterceptor
{
  private _resources: string[] = [
    this._config.resourcePaths.models,
    this._config.resourcePaths.contributions,
    `${this._config.resourcePaths.workspaceCore}/workspaceInfos`,
  ];

  constructor(
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this._resources.includes(req.url)) {
      return this._handleResourceRequest(req, next);
    }
    return next.handle(req);
  }

  private _handleResourceRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._store.select(AppSelectors.selectHideProprietaryModels).pipe(
      first(),
      switchMap(hideProprietaryModels => {
        if (hideProprietaryModels) {
          const newRequest = this._addHideProprietaryModelsParam(
            req,
            hideProprietaryModels
          );
          return next.handle(newRequest);
        }
        return next.handle(req);
      })
    );
  }

  private _addHideProprietaryModelsParam(
    req: HttpRequest<any>,
    presenting: boolean
  ): HttpRequest<any> {
    const params = (req.params || new HttpParams()).set(
      'hide-proprietary-models',
      presenting
    );
    return req.clone({ params });
  }
}
