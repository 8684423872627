import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-recent-menu',
  templateUrl: './recent-menu.component.html',
  styleUrls: ['./recent-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'v2-style-wrapper',
  },
})
export class RecentMenuComponent {}
