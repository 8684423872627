import { Inject, Injectable, Optional } from '@angular/core';
import { TaskService } from '@core/services/task.service';
import { TaskNotification, TaskStatus } from '@models';
import { TASK_MESSAGE_LISTENERS, TaskListeners } from './task-listener.models';

@Injectable()
export class TaskMessagesService {
  constructor(
    private _taskService: TaskService,
    @Optional()
    @Inject(TASK_MESSAGE_LISTENERS)
    private _taskListeners: TaskListeners[]
  ) {}

  onTaskNotification(task: TaskNotification): void {
    if (task.status === 'CANCELLED') {
      this._onTaskCancel(task);
      return;
    }

    if (!task.payload) {
      return;
    }

    const listeners = this._taskListeners || [];
    listeners.forEach(listener => listener.action(task));
  }

  private _onTaskCancel(task: TaskNotification): void {
    this._taskService.updateAllSubTaskStatus(task.task, TaskStatus.Pending);
  }
}
