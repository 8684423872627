import { RecentWorkspace, WorkspaceId, WorkspaceInfo } from '@models';

export const updateRecentlyViewedWorkspaceList = (
  list: RecentWorkspace[],
  workspaceInfo: WorkspaceInfo | null,
  removeId?: WorkspaceId | null,
  maxLength = 3
): RecentWorkspace[] => {
  let newList = [...list];

  if (removeId) {
    newList = newList.filter(item => item.id.name !== removeId.name);
  }

  if (workspaceInfo) {
    const recentWorkspaceToAdd = {
      id: workspaceInfo.id,
      displayName: workspaceInfo.description,
      workspaceType: workspaceInfo.workspaceType,
      modelInfo: {
        modelId: workspaceInfo.modelId,
        modelVersion: workspaceInfo.modelVersion,
        modelShortname: workspaceInfo.modelShortname,
        upgradeWarning: workspaceInfo.upgradeWarning,
        ...(workspaceInfo.deprecationMessage
          ? { deprecationMessage: workspaceInfo.deprecationMessage }
          : {}),
      },
    };
    newList = [
      recentWorkspaceToAdd,
      ...newList.filter(item => item.id.name !== recentWorkspaceToAdd.id.name),
    ];
  }

  if (newList.length > maxLength) {
    newList.length = maxLength;
  }

  return newList;
};
