export enum ROW_HEIGHT {
  Small = 40,
  Medium = 64,
  Large = 96,
}

export enum MENU_ROW_HEIGHT {
  Medium = 40,
  Large = 55,
}

export enum OVERLAY_WIDTH {
  Medium = 400,
  Large = 600,
}
