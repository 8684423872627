<span matTooltip="Total samples">
  <fa-icon icon="hashtag" class="mr-4" />
  <strong class="pl-0">{{ summaryData.rows || EN_DASH }}</strong>
</span>

<span matTooltip="Missing expectations">
  <fa-icon icon="exclamation-circle" class="theme-color-yellow-alt" />
  <strong>{{ summaryData.warning || EN_DASH }}</strong>
</span>

<span matTooltip="Mismatched expectations">
  <fa-icon icon="exclamation-triangle" class="theme-color-warn" />
  <strong>{{ summaryData.error || EN_DASH }}</strong>
</span>

<app-diagnostics-summary [diagnosticsSummary]="diagnosticsSummary" />
