import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { IngestionEnvironmentApiExport } from '@models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { first, Observable, switchMap } from 'rxjs';

import { ApiExportDTO } from '../models/api-export-dto.model';

@Injectable({
  providedIn: 'root',
})
export class ApiExportApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getExports(): Observable<ApiExportDTO.ApiExportItem[]> {
    return this._store.pipe(
      WorkspaceSelectors.getWorkspaceId,
      first(),
      switchMap(workspaceId =>
        this._http.get<ApiExportDTO.ApiExportItem[]>(
          `${this._config.resourcePaths.promoted}/${workspaceId.name}/get-exports`
        )
      )
    );
  }

  getIngestionExports(): Observable<IngestionEnvironmentApiExport[]> {
    return this._store.pipe(
      WorkspaceSelectors.getWorkspaceId,
      first(),
      switchMap(workspaceId =>
        this._http.get<IngestionEnvironmentApiExport[]>(
          `${this._config.resourcePaths.promoted}/ingest/extension/environments/${workspaceId.name}`
        )
      )
    );
  }
}
