import { AppThemes } from '@configs';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/app.actions';

export const featureKey = 'app';

export interface State {
  version: string | null;
  refreshRequired: boolean;
  newReleaseNotes: boolean;
  theme: AppThemes;
  monacoReady: boolean;
  hideProprietaryModels: boolean;
}

export const initialState: Readonly<State> = {
  version: null,
  refreshRequired: false,
  newReleaseNotes: false,
  theme: AppThemes.LIGHT,
  monacoReady: false,
  hideProprietaryModels: false,
};

export const reducer = createReducer(
  initialState,
  on(actions.getAppVersionSuccess, (state, { version }) => ({
    ...state,
    version,
  })),
  on(actions.toggleTheme, state => ({
    ...state,
    theme: state.theme === AppThemes.DARK ? AppThemes.LIGHT : AppThemes.DARK,
  })),
  on(actions.updateTheme, (state, { theme }) => ({
    ...state,
    theme: theme || state.theme,
  })),
  on(actions.togglePresenting, state => ({
    ...state,
    hideProprietaryModels: !state.hideProprietaryModels,
  })),
  on(actions.monacoReady, state => ({
    ...state,
    monacoReady: true,
  })),
  on(actions.checkReleaseNotesSeen, (state, { seen }) => ({
    ...state,
    newReleaseNotes: seen,
  })),
  on(actions.seenReleaseNoteSuccess, state => ({
    ...state,
    newReleaseNotes: false,
  })),
  on(actions.appRefreshUpdate.required, state => ({
    ...state,
    refreshRequired: true,
  }))
);
