<app-collapsible-panel-toggle-button
  *ngIf="parent?.canClose"
  [@slideInOut]="showButton$ | async"
/>
<small class="panel-title mb-0" *ngIf="showTitle">{{ parentTitle }}</small>
<div
  class="flex-row flex-align-center"
  [class.justify-content-end]="contentAlign === 'end'"
>
  <ng-content></ng-content>
</div>
