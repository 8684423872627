import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingSpinnerModule } from '@shared/modules/loading-spinner/loading-spinner.module';

@Component({
  standalone: true,
  selector: 'app-link-with-arrow',
  templateUrl: './link-with-arrow.component.html',
  styleUrls: ['./link-with-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, LoadingSpinnerModule],
})
export class LinkWithArrowComponent {
  @Input() disabled = false;
  @Input() isLoading = false;
}
