import { Injectable } from '@angular/core';
import { TaskService } from '@core/services';
import {
  JsonValidateResult,
  JsonValidateStart,
  Task,
  TaskNotification,
  TaskStatus,
} from '@models';
import { JsonValidatorService } from '@workspace-engine/json-validator/services/json-validator.service';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class JsonValidationListenerService implements TaskListeners {
  constructor(
    private _jsonValidatorService: JsonValidatorService,
    private _taskService: TaskService
  ) {}

  action(task: TaskNotification) {
    if (task.task !== Task.JsonValidation) {
      return;
    }

    if (task.status === 'STARTED') {
      const validateStarted = task.payload as JsonValidateStart;
      this._taskService.updateTaskStatus(
        task.task,
        validateStarted.fileName,
        TaskStatus.Started
      );
    }

    if (task.status === 'FINISHED') {
      const validateResult = task.payload as JsonValidateResult;
      const validateStatus = validateResult.success
        ? TaskStatus.Finished
        : TaskStatus.Error;
      this._taskService.updateTaskStatus(
        task.task,
        validateResult.fileName,
        validateStatus
      );
      this._jsonValidatorService.onJsonValidateFinished(validateResult);
    }
  }
}
