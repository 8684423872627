import { Injectable } from '@angular/core';
import { TOUR_CONFIG } from '@app/config/tour.config';
import { StorageKey } from '@configs';
import { LocalStorageService } from '@core/services';
import { TourState } from 'ngx-ui-tour-core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { map, merge, take, takeUntil } from 'rxjs';

export interface RosettaTourState<T = Set<string>> {
  tourVersion: number | null;
  seenStepIds: T;
}

@Injectable()
export class RosettaTourService {
  constructor(
    private _storage: LocalStorageService,
    private _tourService: TourService
  ) {}

  private get _disabled(): boolean {
    return window.__DISABLE_ROSETTA_TOUR__;
  }

  loadTourSteps(steps: IMdStepOption[]) {
    if (this._disabled) {
      return;
    }

    steps = this._removeSeenSteps(steps);
    this._setupPopoverClass(steps);
    this._tourService.steps = [];
    this._tourService.initialize(steps, {
      enableBackdrop: false,
    });
  }

  start() {
    if (!this._disabled && this._tourService.getStatus() !== TourState.ON) {
      this._setup();
      this._tourService.start();
    }
  }

  stop() {
    this._tourService.steps = [];
    this._tourService.end();
  }

  private _getConfig(): RosettaTourState {
    const state = this._storage.getItem<RosettaTourState>(
      StorageKey.TourConfig
    );

    if (!state || state.tourVersion !== TOUR_CONFIG.version) {
      return { tourVersion: 1, seenStepIds: new Set() };
    }

    return {
      ...state,
      seenStepIds: new Set(state.seenStepIds),
    };
  }

  private _setConfig(state: RosettaTourState) {
    this._storage.setItem(StorageKey.TourConfig, {
      ...state,
      seenStepIds: Array.from(state.seenStepIds),
    });
  }

  private _getId(step: IMdStepOption): string {
    return step.stepId || step.anchorId || '';
  }

  private _setup() {
    const stepHide$ = this._tourService.stepHide$.pipe(
      map(({ step }) => [step as IMdStepOption]),
      takeUntil(this._tourService.end$)
    );
    const end$ = this._tourService.end$.pipe(
      map(() => this._tourService.steps as IMdStepOption[]),
      take(1)
    );
    merge(stepHide$, end$).subscribe(steps => this._updateTourConfig(steps));
  }

  private _checkStepSeen(step: IMdStepOption) {
    return this._getConfig()?.seenStepIds.has(this._getId(step));
  }

  private _removeSeenSteps(steps: IMdStepOption[]): IMdStepOption[] {
    return steps.filter(step => !this._checkStepSeen(step));
  }

  private _updateTourConfig(steps: IMdStepOption[]) {
    if (steps.length > 0) {
      const config = this._getConfig();
      steps.forEach(step => config.seenStepIds.add(this._getId(step)));
      this._setConfig(config);
    }
  }

  private _setupPopoverClass(steps: IMdStepOption[]): void {
    steps.forEach((step: IMdStepOption) => {
      let popoverClass = 'theme-bg';
      if (!!step.popoverClass) {
        popoverClass = `${popoverClass} ${step.popoverClass}`;
      }
      step.popoverClass = popoverClass;
    });
  }
}
