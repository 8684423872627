<header mat-dialog-title>
  <h2>Release Notes</h2>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <fa-icon icon="times" />
  </button>
</header>

<!--
  Extra wrapper required to prevent default style to
  remove top padding when content is next to the title.
-->
<div>
  <div mat-dialog-content appForceLinkTargetBlank>
    <ng-scrollbar
      class="height-full"
      *appShowSpinner="releaseNotes$ | async as releaseNotes; flexCentre: true"
    >
      <section *ngFor="let releaseNote of releaseNotes" @inOutAnimation>
        <h4>
          {{ releaseNote.tag }}
          <app-text-badge class="theme-bg-color ml-16" *ngIf="releaseNote.new"
            >New</app-text-badge
          >
        </h4>
        <div appForceLinkTargetBlank [innerHTML]="releaseNote.bodyAsHtml"></div>
      </section>
    </ng-scrollbar>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
