<header class="flex-align-center">
  <a tabindex="-1" [hidden]="hideLogo" [href]="externalLink.WEBSITE">
    <app-logo />
  </a>
  <p class="theme-color-primary mat-headline-4">
    {{ panelTitle }}
  </p>
</header>

<div class="info-panel">
  <ng-content />
</div>

<footer>
  <ng-content select="[info-panel-footer]" />
</footer>
