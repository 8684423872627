export interface SnackBarConfig {
  message: string;
  /**
   * @deprecated The field should not be used
   */
  button?: string;
  snackBarAction?: SnackBarAction;
}

export interface SnackBarAction {
  label: string;
  action: () => any;
}

export enum SnackBarActionType {
  NONE,
  LEGACY,
  ACTION,
}
