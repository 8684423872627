import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RETRY_BACKOFF_CONFIG } from '@configs';
import { Observable, retry, throwError, timer } from 'rxjs';

@Injectable()
export class RetryBackoffInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry({
        count: RETRY_BACKOFF_CONFIG.maxRetries,
        delay: (error: any, retryCount: number) => {
          if (error instanceof HttpErrorResponse && error.status !== 503) {
            return throwError(() => error);
          }
          return timer(
            this._exponentialBackoffDelay(
              retryCount,
              RETRY_BACKOFF_CONFIG.initialInterval
            )
          );
        },
      })
    );
  }

  private _exponentialBackoffDelay(
    attempt: number,
    initialDelay: number
  ): number {
    const delay = Math.min(
      initialDelay * Math.pow(2, attempt),
      RETRY_BACKOFF_CONFIG.maxDelay
    );
    return delay;
  }
}
