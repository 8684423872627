import { BehaviorSubject, map } from 'rxjs';

export type State =
  | 'init'
  | 'loading'
  | 'loaded'
  | 'success'
  | 'error'
  | 'warning';
export type Warning = 'none' | 'minor' | 'major' | 'unsupported';

export enum LoadingState {
  ERROR = 'ERROR',
  CLOSED = 'CLOSED',
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export interface IPageState {
  state: LoadingState;
  errorMessage: string | null;
}
export class ComponentState {
  private _currentState = new BehaviorSubject<State>('init');

  isInit = this._currentState.pipe(map(s => s === 'init'));
  isLoading = this._currentState.pipe(map(s => s === 'loading'));
  isLoaded = this._currentState.pipe(map(s => s === 'loaded'));
  isSuccess = this._currentState.pipe(map(s => s === 'success'));
  isError = this._currentState.pipe(map(s => s === 'error'));
  isWarning = this._currentState.pipe(map(s => s === 'warning'));

  next(newState: State) {
    this._currentState.next(newState);
  }

  get current() {
    return this._currentState.value;
  }

  get onChange() {
    return this._currentState.asObservable();
  }
}
