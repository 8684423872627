import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { editorTourSteps } from '@features/workspace/editor.tour';
import { regulationTourSteps } from '@features/workspace/regulation.tour';
import { SupportedFeatureService } from '@features/workspace/services/supported-feature.service';
import { QueryParams } from '@models';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { InOutAnimation } from '@shared';
import { RosettaTourService } from '@shared/modules/rosetta-tour/rosetta-tour.service';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { Subscription, map, startWith } from 'rxjs';

@Component({
  selector: 'app-workspace-container',
  templateUrl: './workspace-container.component.html',
  styleUrls: ['./workspace-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
})
export class WorkspaceContainerComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _supportFeatureService: SupportedFeatureService,
    private _rosettaTourService: RosettaTourService,
    @Inject(WA_WINDOW) private _window: Window
  ) {}

  private _routerParams$ = this._route.queryParamMap;
  private _sub = new Subscription();

  isLoading$ = this._store.select(WorkspaceSelectors.isConnectionNotReady);
  topPanel$ = this._routerParams$.pipe(
    map(paramMap => coerceBooleanProperty(paramMap.get(QueryParams.TOP_PANEL))),
    startWith(true)
  );
  bottomPanel$ = this._routerParams$.pipe(
    map(paramMap =>
      coerceBooleanProperty(paramMap.get(QueryParams.BOTTOM_PANEL))
    ),
    startWith(false)
  );

  ngOnInit(): void {
    this._checkNewRegulationAvailable();
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  fireSplitUpdateEvent(): void {
    this._window.dispatchEvent(new Event('resize'));
  }

  private _checkNewRegulationAvailable(): void {
    this._sub.add(
      this._supportFeatureService
        .checkSupportFor('regulation')
        .subscribe(showTour => {
          const steps = [
            ...(showTour ? regulationTourSteps : []),
            ...editorTourSteps,
          ];
          this._rosettaTourService.loadTourSteps(steps);
          this._rosettaTourService.start();
        })
    );
  }
}
