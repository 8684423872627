import { numberAttribute } from '@angular/core';

export const appendIndexesToNodes = (list: string[]): string[] => {
  const result: string[] = [];

  for (let i = 0; i < list.length; i++) {
    const current = list[i];
    const isNumber = !isNaN(numberAttribute(current));
    const nextIndex = i + 1;

    if (isNumber && nextIndex < list.length) {
      result.push(`${list[nextIndex]} (${current})`);
      i++; // Skip the next item since it's already processed
    } else {
      result.push(current);
    }
  }

  return result;
};
