import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FileTreeEvent, FileTreeNode } from '@models';
import { FileTreeService } from '@shared/modules/file-tree/file-tree.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-tree-leaf-node[node]',
  templateUrl: './file-tree-leaf-node.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    '../file-tree-node.base.scss',
    './file-tree-leaf-node.component.scss',
  ],
  host: {
    class: 'file-tree-node file-tree-leaf-node',
  },
})
export class FileTreeLeafNodeComponent implements OnInit, OnDestroy {
  constructor(
    public service: FileTreeService,
    private _elementRef: ElementRef,
    private _cdr: ChangeDetectorRef
  ) {}

  @Input() node!: FileTreeNode;
  @Input() showOptions = true;

  @Output() nodeClicked = new EventEmitter<FileTreeEvent>();

  @HostBinding('class') classAttribute?: string;

  private _sub = new Subscription();

  ngOnInit() {
    this.service.registerNode(this);
    this._updateClasses();
    this._sub.add(
      this.service.treeControl
        .modelChanged()
        .subscribe(() => this._updateClasses())
    );
  }

  ngOnDestroy() {
    this.service.unregisterNode(this);
    this._sub.unsubscribe();
  }

  get element() {
    return this._elementRef.nativeElement;
  }

  @HostListener('click')
  openNode() {
    this.onClick('open');
  }

  onClick(type: FileTreeEvent['type']) {
    this.nodeClicked.emit({ type, node: this.node });
  }

  private _updateClasses() {
    this.classAttribute = `${
      this.service.treeControl.isSelected(this.node) ? 'node-selected' : ''
    } ${'node-lock-state-' + this.node.lockState} ${
      this.service.treeControl.hasError(this.node) ? 'node-error' : ''
    }`;
    this._cdr.markForCheck();
  }
}
