import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipeFunc } from '@models';
import { isEmptyString } from '@utils';

const defaultFilterFunc: FilterPipeFunc<any> = search => {
  if (isEmptyString(search)) {
    return () => true;
  }
  const regex = new RegExp(search, 'gi');
  return value => (value || '').match(regex);
};

@Pipe({
  standalone: true,
  name: 'filter',
})
export class RosettaFilterPipe implements PipeTransform {
  transform<T>(
    list: T[],
    search: string,
    func: FilterPipeFunc<T> | undefined = defaultFilterFunc
  ): T[] {
    return list?.filter(func(search));
  }
}
