import { Injectable } from '@angular/core';
import { permissionsMapper, StorageKey, UserAbilities } from '@configs';
import { UserLoginResponse, UserPermissions } from '@features/auth/login';
import { isEmptyString } from '@utils';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _storageService: LocalStorageService,
    private _cookieService: CookieService
  ) {}

  #permissionToUserAbilityMap: Set<UserAbilities> = new Set();

  init(userPermissions: UserPermissions): void {
    this.#permissionToUserAbilityMap = permissionsMapper(userPermissions);
  }

  has(ability: UserAbilities | undefined): boolean {
    return this.#permissionToUserAbilityMap.has(ability);
  }

  hasOne(abilities: UserAbilities[] | undefined): boolean {
    return abilities?.some(ability => this.has(ability)) || false;
  }

  hasAll(abilities: UserAbilities[] | undefined): boolean {
    return abilities?.every(ability => this.has(ability)) || false;
  }

  validSession(): boolean {
    const expires_at = this._storageService.getItem<string>(
      StorageKey.ExpiresAt
    );
    if (!expires_at) {
      return false;
    }
    const timestamp = Date.parse(expires_at);
    return timestamp > Date.now();
  }

  userIsAuthenticated(): boolean {
    return (
      this.validSession() &&
      !isEmptyString(this._cookieService.get(StorageKey.AccessToken))
    );
  }

  saveUserLoginResponse(userLoginResponse: UserLoginResponse): void {
    this._cookieService.delete(StorageKey.AccessToken);
    this._storageService.removeItem(StorageKey.ExpiresAt);
    this._storageService.removeItem(StorageKey.LoginDetails);

    if (userLoginResponse?.rosettaAuth) {
      const { expiryTime, accessToken, rosettaUser } =
        userLoginResponse.rosettaAuth;
      this._storageService.setItem(StorageKey.ExpiresAt, expiryTime);
      this._storageService.setItem<UserLoginResponse>(
        StorageKey.LoginDetails,
        userLoginResponse
      );
      this._cookieService.set(StorageKey.AccessToken, accessToken, {
        expires: new Date(expiryTime),
        path: '/',
        secure: true,
      });

      this.init(rosettaUser.userPermissions);
    }
  }

  getLoginDetails(): UserLoginResponse | null {
    return this._storageService.getItem<UserLoginResponse>(
      StorageKey.LoginDetails
    );
  }
}
