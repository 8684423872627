<ng-container *ngrxLet="pipelineRunInfo$ | async as value">
  <app-inline-error *ngIf="value?.errorMessage">{{
    value.errorMessage
  }}</app-inline-error>

  <app-rosetta-table
    [tableId]="dataViewerId"
    [rowData]="value?.result.data?.rows || []"
    [colDefs]="cols()"
    [selectionColDef]="selectionCol()"
    [options]="tableOptions"
    [gridOptions]="gridOptions"
    (rowClicked)="rowClicked.emit($event)"
  >
    <header class="width-full">
      <!-- Content Project main slot -->
      <ng-content />

      <app-action-bar
        [selectedRows]="selectionChanged()"
        [loading]="loading()"
        [showChanges]="context.showChanges"
        (applyAction)="onActionBarEvent($event)"
      />

      <div class="spacer"></div>

      <mat-slide-toggle
        class="mr-16 color-50"
        [disabled]="loading()"
        [checked]="context.showChanges"
        (change)="toggleShowChanges()"
        labelPosition="before"
        >Show Changes</mat-slide-toggle
      >

      <!-- Content project extra action buttons for the table -->
      <ng-content select="[dataViewerActions]" />
    </header>

    <div rosettaNoCols class="flex-1 flex-align-center-center">
      <fa-icon
        icon="exclamation-circle"
        size="2xl"
        class="theme-color-yellow-alt"
      />
      <h2>Select data to display</h2>
    </div>

    <ng-container rosettaTableBanner>
      <ng-content select="[dataViewerBanner]" />
      <app-inline-error
        *ngIf="tabulatorUnsupported()"
        errorType="warning"
        class="mb-16"
        dataViewerBanner
        >Tabulated data cannot be displayed. You can still click on a sample row
        to view its details.</app-inline-error
      >
    </ng-container>

    <app-summary summary [summaryData]="summary$ | async" />
  </app-rosetta-table>
</ng-container>
