import { InjectionToken } from '@angular/core';
import { EngineFeatureTabs } from '@features/workspace/modules/engine/engine-config';
import { TestPackGridSelection } from './test-pack-grid-selection.model';

export const TRANSFORM_CONFIG = new InjectionToken<ITransformConfig>(
  'TransformConfig'
);

export interface TransformSelectorConfig {
  label: string;
  placeholder: string;
  key: keyof TestPackGridSelection;
  refreshOnOpen?: boolean;
  tourAnchor?: string;
}

export interface SampleConfig {
  showSelected: boolean;
}

export enum TransformType {
  Ingest = 'TRANSLATE_1_5', // TODO: rename type in backend
  Enrich = 'ENRICH',
  Report = 'REPORT',
  Projection = 'PROJECTION',
}

export interface ITransformConfig {
  type: TransformType;
  featureTabName: keyof typeof EngineFeatureTabs;
  url: string;
  pipelineSelector: TransformSelectorConfig;
  testPackSelector: TransformSelectorConfig;
  sampleInput: SampleConfig;
  sampleOutput: SampleConfig;
}
