import { LoadingState, WorkspaceInfo } from '@models';
import {
  workspaceNameFormatted,
  workspaceNameIfNotAvailable,
} from '@store/workspace/effects/workspace.effects.helper';

export const getErrorMessage = (state: {
  currentMenuItem: WorkspaceInfo | null;
  loadingState: LoadingState;
}): string => {
  const disconnectMsg = `You were disconnected from ${workspaceNameFormatted(
    state.currentMenuItem
  )}`;

  const errorMsg = `${workspaceNameIfNotAvailable(
    state.currentMenuItem
  )} is unavailable, please wait for availability or select a different project or workspace.`;

  return state.loadingState === LoadingState.ERROR ? errorMsg : disconnectMsg;
};
