import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExternalLink } from '@configs';
import { Store } from '@ngrx/store';
import * as dialogActions from '@store/actions/dialog.actions';
import { AppSelectors } from '@store/selectors';
import { IssueTrackerComponent } from '../issue-tracker/issue-tracker.component';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpMenuComponent {
  constructor(
    private _store: Store,
    private _dialog: MatDialog
  ) {}

  readonly externalLink = ExternalLink;
  newReleaseNotes$ = this._store.select(AppSelectors.newReleaseNotes);

  openIssueTracker() {
    this._dialog.open(IssueTrackerComponent, IssueTrackerComponent.options());
  }

  openReleaseNotes() {
    this._store.dispatch(dialogActions.openAppReleaseNoteDialog());
  }
}
