<!-- Templates -->
<ng-template
  #actionBtnTemplate
  let-action="action"
  let-tooltip="tooltip"
  let-icon="icon"
>
  <button
    type="button"
    class="btn-no-min-width"
    mat-stroked-button
    (click)="onClick(action)"
    matTooltip="{{ tooltip }}"
    matTooltipShowDelay="500"
  >
    <fa-icon [icon]="icon" />
  </button>
</ng-template>

<!-- Main Content -->
<app-warning-triangle
  *ngIf="rowError"
  icon="exclamation-triangle"
  class="mr-8 theme-color-warn"
/>

<div
  class="cell-value mr-16 color-75 spacer"
  [ngClass]="{ 'remove-row': isRowRemoved }"
>
  <span class="text-ellipsis">
    {{ cellValue | emptyString }}
  </span>
</div>

<div
  [ngClass]="{ 'ros-row-hover-hide': selectable }"
  [ngSwitch]="rowData.sampleRowStatus"
>
  <fa-icon
    *ngSwitchCase="sampleRowStatus.Removed"
    icon="circle-minus"
    class="theme-color-warn"
  />
  <fa-icon
    *ngSwitchCase="sampleRowStatus.Added"
    [icon]="addIcon"
    class="theme-color-success"
  />
  <ng-container *ngSwitchCase="sampleRowStatus.AddedUpstream">
    <fa-icon icon="arrow-left" class="theme-color-success" />
    <fa-icon [icon]="addIcon" class="theme-color-success" />
  </ng-container>
</div>

<div
  *ngIf="selectable"
  appStopPropagation
  class="ros-row-hover-show small-button-wrapper"
>
  <ng-container
    *ngIf="canUpdate"
    [ngTemplateOutlet]="actionBtnTemplate"
    [ngTemplateOutletContext]="{
      action: sampleRowAction.Accept,
      tooltip: 'Accept expectation changes',
      icon: 'check',
    }"
  />
  <ng-container
    *ngIf="canDiscard"
    [ngTemplateOutlet]="actionBtnTemplate"
    [ngTemplateOutletContext]="{
      action: sampleRowAction.Revert,
      tooltip: 'Discard changes',
      icon: 'undo',
    }"
  />
  <ng-container
    *ngIf="canDelete"
    [ngTemplateOutlet]="actionBtnTemplate"
    [ngTemplateOutletContext]="{
      action: sampleRowAction.Delete,
      tooltip: 'Delete sample',
      icon: 'trash',
    }"
  />
</div>
