import { FeatureTab, QueryParams } from '@models';
import { Observable, map } from 'rxjs';

import { ApiExportTab } from './api-export/api-export-tab.config';
import { EnrichTab } from './enrich/enrich-tab.config';
import { FunctionsTab } from './functions/engine-functions-tab.config';
import { ValidationTab } from './json-validator/json-validation-tab.config';
import { ProjectionTab } from './projection/projection-tab.config';
import { ReportsTab } from './reports/reports-tab.config';
import { IngestTab } from './ingest/ingest-tab.config';
import { VisualisationTab } from './visualisation/visualisation-tab.config';

const queryParams = {
  [QueryParams.BOTTOM_PANEL]: true,
};

export function isDisabled(
  tab: FeatureTab,
  $contribution: Observable<boolean>
): Observable<boolean> {
  return $contribution.pipe(
    map(isContribution => isContribution && tab.name === 'apiExport')
  );
}

export const EngineFeatureTabs = {
  // Pipeline features
  ingest: IngestTab,
  enrich: EnrichTab,
  reports: ReportsTab,
  projection: ProjectionTab,

  // Non-pipeline features
  validation: ValidationTab,
  visualisation: VisualisationTab,
  functions: FunctionsTab,
  apiExport: ApiExportTab,
} as const;

export const EngineFeatureTabList: FeatureTab[] = Object.values(
  EngineFeatureTabs
).map(tab => ({ ...tab, queryParams }));
