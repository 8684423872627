import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ANIMATION_EASING_MEDIUM } from '@configs';

export const CollapsiblePanelAnimations = [
  trigger('openClose', [
    state(
      'opened, init-opened',
      style({
        flex: '{{ratio}} 1 {{minWidth}}',
      }),
      { params: { ratio: 1, minWidth: '0%' } }
    ),
    state(
      'closed, init-closed',
      style({
        flex: '0 1 28px',
      })
    ),
    transition(
      'opened <=> closed',
      group([
        query('@fadeIn', animateChild()),
        animate(ANIMATION_EASING_MEDIUM),
      ])
    ),
  ]),
  trigger('fadeIn', [
    state('true', style({ opacity: 1 })),
    state('false', style({ opacity: 0 })),
    transition('true => false', animate('0s')),
    transition('* => true', animate('0.5s')),
  ]),
];
