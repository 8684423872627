import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ShowSpinnerDirective } from './show-spinner.directive';

@NgModule({
  declarations: [ShowSpinnerDirective, LoadingSpinnerComponent],
  imports: [MatProgressSpinnerModule],
  exports: [ShowSpinnerDirective, LoadingSpinnerComponent],
})
export class LoadingSpinnerModule {}
