import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabsFeatureDirective } from '@shared/modules/feature-toggle/labs-feature.directive';
import { FeatureToggleWrapperComponent } from '@shared/modules/feature-toggle/feature-toggle-wrapper.component';

@NgModule({
  declarations: [FeatureToggleWrapperComponent, LabsFeatureDirective],
  imports: [CommonModule, MatTooltipModule],
  exports: [FeatureToggleWrapperComponent, LabsFeatureDirective],
})
export class FeatureToggleModule {}
