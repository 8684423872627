import { RosettaSelectorBase } from '@shared/modules/rosetta-selector/rosetta-selector.base.service';
import { Observable, of } from 'rxjs';

import { PipelineDef } from '../../models/pipeline-def.model';
import { TestPackDef } from '../../models/test-pack-def.model';
import { ITransformConfig } from '../../models/transform-config.model';
import { TransformStateSyncService } from '../transform-state-sync.service';
import { TransformStorageService } from '../transform-storage.service';

export class TestPackSelector extends RosettaSelectorBase<TestPackDef> {
  constructor(
    private _transformStorage: TransformStorageService,
    private _transformStateSync: TransformStateSyncService,
    private _transformConfig: ITransformConfig
  ) {
    super(_transformConfig.testPackSelector);
  }

  override getListItemValue = (item: TestPackDef) => item.id;
  override getListItemLabel = (item: TestPackDef) => item.name;

  get value(): string {
    return this._transformStateSync.get(
      this._transformConfig.type,
      this._transformConfig.testPackSelector.key
    );
  }
  set value(_value: string) {
    // Value is set by pipeline and stored in the State Sync Service
  }

  protected fetchData(pipelineDef: PipelineDef): Observable<TestPackDef[]> {
    return pipelineDef
      ? this._transformStorage.getTestPacks(pipelineDef)
      : of([]);
  }
}
