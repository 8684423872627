import { SelectionColumnDef } from '@ag-grid-community/core';
import { cellClassRulesUtil } from '../utils/helpers';

export const checkboxColDef = (): SelectionColumnDef => ({
  pinned: 'left',
  resizable: false,
  sortable: false,
  maxWidth: 48, // Smallest width possible due to padding
  cellClassRules: cellClassRulesUtil(),
});
