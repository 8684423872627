import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontsModule } from '@app/fonts/fonts.module';
import { DATE_FORMAT } from '@configs';
import { PackagePlanDetails } from '@features/auth/login';
import { UserDetails } from '@features/auth/login/models/login.model';
import { format, parse } from 'date-fns';

@Component({
  standalone: true,
  imports: [
    FontsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  selector: 'app-package-plan-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './package-plan-editor.component.html',
  styles: [
    `
      :host {
        display: block;
        padding: 6px 12px;
      }
    `,
  ],
})
export class PackagePlanEditorComponent implements ICellEditorAngularComp {
  packagePlanDetails!: PackagePlanDetails<Date>;
  userInput: Partial<Pick<PackagePlanDetails<Date>, 'startDate' | 'endDate'>> =
    {};

  agInit({ data }: ICellEditorParams<UserDetails>) {
    const { startDate, endDate } = data.packagePlanDetails;
    this.packagePlanDetails = {
      ...data.packagePlanDetails,
      startDate: startDate ? parse(startDate, DATE_FORMAT, new Date()) : null,
      endDate: endDate ? parse(endDate, DATE_FORMAT, new Date()) : null,
    };
  }

  blurInput() {
    this.packagePlanDetails = {
      ...this.packagePlanDetails,
      ...this.userInput,
    };
  }

  dateRangeChange(
    type: 'change' | 'input',
    property: keyof Pick<PackagePlanDetails, 'startDate' | 'endDate'>,
    event: MatDatepickerInputEvent<Date>
  ) {
    const obj = type === 'change' ? this.packagePlanDetails : this.userInput;
    obj[property] = event.value;
  }

  getValue(): PackagePlanDetails {
    const { startDate, endDate } = this.packagePlanDetails;

    if (!startDate || !endDate) {
      return {
        ...this.packagePlanDetails,
        startDate: null,
        endDate: null,
        trial: false,
      };
    }

    return {
      ...this.packagePlanDetails,
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(endDate, DATE_FORMAT),
      trial: true,
    };
  }

  clearPackagePlan() {
    this.packagePlanDetails.startDate = null;
    this.packagePlanDetails.endDate = null;
    this.packagePlanDetails.trial = false;
  }

  isPopup(): boolean {
    return false;
  }
}
