<mat-toolbar appBannerise>
  <button
    mat-icon-button
    [disabled]="state.isLoading | async"
    mat-dialog-close
    tabindex="-1"
  >
    <fa-icon icon="times" />
  </button>

  <h2>Contribute Workspace</h2>
</mat-toolbar>

<ng-container *ngIf="modifiedWorkspaceItems$ | async as modifiedFiles">
  <form [formGroup]="form" (submit)="onSubmit()">
    <p>
      You are proposing changes to
      <strong>{{ modifiedFiles.length }}</strong> file{{
        modifiedFiles.length === 1 ? '' : 's'
      }}.
    </p>
    <mat-form-field>
      <mat-label>Summary</mat-label>
      <input
        matInput
        #summaryInput
        formControlName="summary"
        [maxlength]="maxSummaryLength"
      />
      <mat-hint align="end"
        >{{ summaryInput.value.length }} / {{ maxSummaryLength }}</mat-hint
      >
      <mat-error *ngIf="form.controls['summary'].hasError('required')">
        This field is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field subscriptSizing="fixed">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
      <mat-error *ngIf="form.controls['description'].hasError('required')">
        This field is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <button
      mat-stroked-button
      *appShowSpinner="(state.isLoading | async) === false"
      [disabled]="form.invalid"
      color="primary"
    >
      Submit
    </button>
  </form>

  <app-collapsible-panel-group>
    <app-collapsible-panel ratio="2" key="contribute-files" [canClose]="false">
      <app-collapsible-panel-body class="flex-column">
        <app-collapsible-panel-header contentAlign="end">
          <div appStopPropagation class="white-space-nowrap pr-8">
            <button mat-icon-button (click)="fileTree.gotoCurrentFile()">
              <fa-icon icon="crosshairs" />
            </button>
            <button mat-icon-button (click)="fileTree.collapseAll()">
              <fa-icon icon="minus-square" />
            </button>
            <button mat-icon-button (click)="fileTree.expandAll()">
              <fa-icon icon="plus-square" />
            </button>
          </div>
        </app-collapsible-panel-header>

        <app-file-tree
          #fileTree
          [workspaceItems]="modifiedWorkspaceItems$"
          [showOptions]="false"
        />
      </app-collapsible-panel-body>
    </app-collapsible-panel>

    <app-collapsible-panel ratio="3" key="contribute" [canClose]="false">
      <app-collapsible-panel-body>
        <app-collapsible-panel-header />
        <app-diff-editor />
      </app-collapsible-panel-body>
    </app-collapsible-panel>
  </app-collapsible-panel-group>
</ng-container>
