import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Inject,
  Input,
  Optional,
  QueryList,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DiagnosticPanelGroupDefDirective } from '../diagnostic-panel-def.directive';
import { DiagnosticGroup, DiagnosticItem } from '../diagnostic.model';
import { DiagnosticComponent } from '../diagnostic/diagnostic.component';
import { OpenCloseAnimation } from '../open-close.animation';

@Component({
  selector: 'app-diagnostic-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './diagnostic-panel.component.html',
  styleUrls: [
    '../diagnostic-panel.theme.scss',
    './diagnostic-panel.component.scss',
  ],
  animations: [OpenCloseAnimation],
  host: {
    class: 'diagnostic-panel',
  },
})
export class DiagnosticPanelComponent {
  @Input() set groups(data: DiagnosticGroup[]) {
    this.groups$.next(data);
  }

  @ContentChildren(DiagnosticPanelGroupDefDirective)
  templates!: QueryList<DiagnosticPanelGroupDefDirective>;

  // Keep track of which group is open or closed
  closedGroup: boolean[] = [];
  groups$ = new ReplaySubject<DiagnosticGroup[]>(1);

  constructor(
    @Attribute('heading') public heading: string,
    @Optional()
    @Inject(DiagnosticComponent)
    public parent: DiagnosticComponent | undefined
  ) {}

  onClick(panel: DiagnosticItem) {
    this.parent?.panelClicked.emit(panel);
  }

  groupHasItems(group: DiagnosticGroup): boolean {
    return group.sections.some(section => section.items.length > 0);
  }
}
