import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  NotificationActions,
  NotificationSelectors,
} from '@core/modules/notifications/store';
import { Store } from '@ngrx/store';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMenuComponent {
  hasLoaded$ = this._store.select(NotificationSelectors.selectHasLoaded);
  notifications$ = this._store
    .select(NotificationSelectors.selectNotifications)
    .pipe(shareReplay(1));
  numberNotifications$ = this.notifications$.pipe(
    map(notifications => notifications.length)
  );
  notificationError$ = this._store.select(
    NotificationSelectors.selectHasNotificationError
  );

  constructor(private _store: Store) {}

  dismissNotification(id: string) {
    this._store.dispatch(NotificationActions.dismissNotification({ id }));
  }

  fetchNotifications() {
    this._store.dispatch(NotificationActions.loadNotifications());
  }
}
