import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { RosettaSelectorComponent } from './rosetta-selector.component';
import { RosettaTourModule } from '../rosetta-tour/rosetta-tour.module';

@NgModule({
  declarations: [RosettaSelectorComponent],
  imports: [
    CommonModule,
    FontsModule,

    // Third Party
    LetDirective,

    // UI Modules
    ReactiveFormsModule,
    RosettaTourModule,

    // Material
    MatSelectModule,
  ],
  exports: [RosettaSelectorComponent],
})
export class RosettaSelectorModule {}
