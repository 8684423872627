import {
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

const INTERCOM_APP_CLASS = '.intercom-app';
const INTERCOM_BANNER_FRAME_NAME = 'intercom-banner-frame';
const INTERCOM_CONTAINER_ID = 'intercom-container';

function funToAdjustMargin(
  nodes: NodeList,
  dialogTitle: ElementRef,
  show = true
) {
  nodes.forEach(n => {
    if (
      n instanceof HTMLIFrameElement &&
      n.name === INTERCOM_BANNER_FRAME_NAME
    ) {
      adjustTitleMargin(dialogTitle, show);
    }
  });
}

function adjustTitleMargin(dialogTitle: ElementRef, show = true) {
  if (show) {
    dialogTitle.nativeElement.style.marginTop = '48px';
  } else {
    dialogTitle.nativeElement.style.marginTop = '0';
  }
}

@Directive({
  standalone: true,
  selector: '[appBannerise]',
})
export class BanneriseDirective implements OnInit, OnDestroy {
  constructor(
    private _dialogTitle: ElementRef,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  private _observerBody: MutationObserver | null = null;
  private _observerApp: MutationObserver | null = null;

  ngOnInit() {
    const heavyAppEl: HTMLIFrameElement | null =
      this._document.body.querySelector(INTERCOM_APP_CLASS);
    if (heavyAppEl === null) {
      // light intercom app present - watch for main intercom app
      this._observeNodeBody();
    } else {
      this._heavyAppObserverInit(heavyAppEl);
    }
  }

  ngOnDestroy(): void {
    if (this._observerBody !== null) {
      this._observerBody.disconnect();
    }
    if (this._observerApp !== null) {
      this._observerApp.disconnect();
    }
  }

  private _observeNodeBody() {
    this._observerBody = new MutationObserver(mutations => {
      mutations
        .filter(m => m.type === 'childList')
        .forEach(m =>
          m.addedNodes.forEach(n => {
            const el = n as HTMLElement;
            if (el.id === INTERCOM_CONTAINER_ID) {
              this._observeNodeApp(el.querySelector('.intercom-app'));
            }
          })
        );
    });
    this._observerBody.observe(this._document.body, {
      attributes: false,
      childList: true,
      subtree: false,
    });
  }

  private _observeNodeApp(elApp: HTMLElement | null) {
    if (!elApp) {
      return;
    }
    this._observerApp = new MutationObserver(mutations => {
      mutations
        .filter(m => m.type === 'childList')
        .forEach(m => {
          funToAdjustMargin(m.addedNodes, this._dialogTitle);
          funToAdjustMargin(m.removedNodes, this._dialogTitle, false);
        });
    });
    this._observerApp.observe(elApp, {
      attributes: false,
      childList: true,
      subtree: false,
    });
  }

  // check banner exists
  // watch for when banner exists or if banner already showing - watch for the banner disappearing
  private _heavyAppObserverInit(heavyAppEl: HTMLIFrameElement) {
    if (
      heavyAppEl.querySelector(
        'iframe[name=' + INTERCOM_BANNER_FRAME_NAME + ']'
      ) != null
    ) {
      adjustTitleMargin(this._dialogTitle);
    }
    this._observeNodeApp(heavyAppEl);
  }
}
