import { animate, style, transition, trigger } from '@angular/animations';
import { ANIMATION_EASING_FAST } from '@configs';

export const SlideDownAnimation = trigger('slideDown', [
  transition(':leave', [
    style({ transform: 'translateY(0%)', visibility: 'visible' }),
    animate(
      ANIMATION_EASING_FAST,
      style({ transform: 'translateY(100%)', visibility: 'hidden' })
    ),
  ]),
]);
