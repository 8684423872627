import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormConfig, getDialogOptions } from '@configs';
import { DialogComponent } from '@models';

@Component({
  selector: 'app-upgrade-plan-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        margin-bottom: -24px;
      }

      .mat-mdc-dialog-title {
        margin-bottom: 0;
      }

      .mat-mdc-dialog-content {
        // Force background to be white as hubspot forms
        // are not designed to work on dark backgrounds
        background: white;
        padding-top: 24px;
      }
    `,
  ],
  template: `
    <header mat-dialog-title>
      <h2>
        Upgrade Plan
        <small class="theme-color-primary font-weight-bold" *ngIf="data.offer"
          >Offer ends {{ data.endDate | date }}
        </small>
      </h2>
      <div class="spacer"></div>
      <button mat-icon-button mat-dialog-close tabindex="-1">
        <fa-icon icon="times" />
      </button>
    </header>
    <app-hubspot-form mat-dialog-content [formId]="data.formId" />
  `,
})
export class UpgradePlanDialogComponent implements DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: FormConfig & { offer: boolean }
  ) {}

  static options(
    formConfig: FormConfig & { offer: boolean }
  ): MatDialogConfig<FormConfig> {
    return getDialogOptions('lg', {
      data: formConfig,
    });
  }
}
