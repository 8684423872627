import {
  PATH_JOIN_CHAR,
  ELLIPSIS_CHAR,
  PATH_JOIN_CHAR_WITH_SPACE,
} from '@configs';
import { DataViewerCol } from '../models/data-viewer';

const truncateMiddleOfPath = (path: string[]): string => {
  if (path.length <= 2) {
    return path.join(PATH_JOIN_CHAR_WITH_SPACE);
  }

  const first = path[0];
  const last = path[path.length - 1];
  return `${first} ${PATH_JOIN_CHAR} ${ELLIPSIS_CHAR} ${PATH_JOIN_CHAR} ${last}`;
};

const groupColumnsByLeafNode = (
  columns: DataViewerCol[]
): Record<string, DataViewerCol[]> => {
  return columns.reduce<Record<string, DataViewerCol[]>>((acc, col) => {
    const leafNode = col.path.at(-1); // Get last element of path
    if (!leafNode) return acc; // Skip if path is empty

    if (!acc[leafNode]) {
      acc[leafNode] = [];
    }
    acc[leafNode].push(col);
    return acc;
  }, {});
};

export const setHeaderNameToShortest = (
  columns: DataViewerCol[]
): DataViewerCol[] => {
  const nodesByName = groupColumnsByLeafNode(columns);

  Object.keys(nodesByName).forEach(leafName => {
    const groupedColumns = nodesByName[leafName];

    if (groupedColumns.length === 1) {
      groupedColumns[0].headerName = leafName;
      return;
    }

    // Compare all paths in this group and assign shortest unique header names
    groupedColumns.forEach(col => {
      const path = col.path;

      if (path.length === 1) {
        col.headerName = path[0];
      }

      for (let index = 2; index <= path.length; index++) {
        const truncatedPath = path.slice(-index);
        const truncatedStr = truncatedPath.join('>');

        // Check if the truncated path is unique among the group
        const isUnique = groupedColumns.every(
          otherCol =>
            otherCol === col ||
            otherCol.path.slice(-index).join('>') !== truncatedStr
        );

        if (isUnique) {
          col.headerName = truncateMiddleOfPath(truncatedPath);
          break;
        }
      }
    });
  });

  return columns;
};
