export const ALL_MODELS = 'ALL_MODELS';
export const READ_WRITE_SERVER_KEY = 'READ_WRITE_SERVER_KEY';

export const DEFAULT_API_TIMEOUT = 5_000;
export const STATUS_POLLING_INTERVAL = 3_000;

export const MAX_FILE_UPLOAD_WS = 1024 * 1024; // This is the max size the websockets support (see RosettaCustomWebsocketBundle)
export const MAX_FILE_UPLOAD = 1024 * 1024 * 100;
export const UPLOAD_FILE_TYPES = [
  '.json',
  '.xml',
  'application/json',
  'application/xml',
];

export const APP_DOCUMENTS = 'rosetta-core';
export const FILTER_DELIMITER = '◬';
export const EN_DASH = '–';

export const PATH_JOIN_CHAR = '\u2192'; // '->';
export const PATH_JOIN_CHAR_WITH_SPACE = ` ${PATH_JOIN_CHAR} `; // ' -> ';
export const ELLIPSIS_CHAR = '\u2026'; // '...';
export const PATH_SPLIT_CHAR = '->';
export const PATH_SPLIT_CHAR_WITH_SPACE = ` ${PATH_SPLIT_CHAR} `; // ' -> ';

export const DAYS_REMAINING_THRESHOLD = 10;

export const DATE_FORMAT = 'yyyy-MM-dd';

export const LOCALE = 'en-UK';
