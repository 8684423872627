export const MONACO_LEGACY_DARK_THEME: monaco.editor.IStandaloneThemeData = {
  base: 'vs-dark',
  inherit: true,
  colors: {
    'editor.background': '#2B2B2B',
    'editor.lineHighlightBackground': '#323232',
    'editor.lineHighlightBorder': '#4B4B4B',
  },
  rules: [
    { token: 'keyword', foreground: '#CC7832' },
    { token: 'keyword.operator', foreground: '#d2d2d2' },
    { token: 'identifier', foreground: '#d2d2d2' },
    { token: 'variable', foreground: '#d2d2d2' },
    { token: 'variable.parameter', foreground: '#d2d2d2' },
    { token: 'string', foreground: '#ce9178' },
    { token: 'number', foreground: '#618AA9' },
    { token: 'comment', foreground: '#717171' },
    { token: 'description', foreground: '#608b4e' },
    { token: 'comment.block.documentation', foreground: '#608b4e' },
  ],
};

export const MONACO_LEGACY_LIGHT_THEME: monaco.editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: true,
  colors: {
    'editor.background': '#FFFFFF',
    'editor.foreground': '#000000',
    'editor.inactiveSelectionBackground': '#E5EBF1',
    'editorIndentGuide.background': '#D3D3D3',
    'editorIndentGuide.activeBackground': '#939393',
    'editor.selectionHighlightBackground': '#ADD6FF4D',
    'editorSuggestWidget.background': '#F3F3F3',
    'activityBarBadge.background': '#007ACC',
    'sideBarTitle.foreground': '#6F6F6F',
    'list.hoverBackground': '#E8E8E8',
    'input.placeholderForeground': '#ADADAD',
    'editor.lineHighlightBackground': '#fcfcfc',
    'editor.lineHighlightBorder': '#dbdbdb',
  },
  rules: [
    { token: 'keyword', foreground: '#3434d8' },
    { token: 'identifier', foreground: '#000000' },
    { token: 'variable', foreground: '#000000' },
    { token: 'string', foreground: '#a31515' },
    { token: 'number', foreground: '#618AA9' },
    { token: 'comment', foreground: '#a3a1a1' },
    { token: 'description', foreground: '#478239' },
    { token: 'comment.block.documentation', foreground: '#478239' },
    { token: 'constant.numeric.integer.decimal', foreground: '#618aa9' },
    { token: 'constant', foreground: '#000000' },
  ],
};
