<div [ngSwitch]="params.context.dataState">
  <ng-container *ngSwitchCase="dateViewState.Empty">
    <fa-icon
      icon="exclamation-circle"
      size="2xl"
      class="theme-color-yellow-alt"
    />
    <h2>Select data to display</h2>
  </ng-container>

  <ng-container *ngSwitchCase="dateViewState.HasData">
    <fa-icon icon="check-circle" size="2xl" class="theme-color-success" />
    <h2 class="font-weight-bold">No changes to show</h2>
    <p
      *ngIf="summaries$ | async as summaries"
      class="summary-wrapper theme-bg-light mb-0"
    >
      <fa-icon icon="table-columns" class="mr-8" />
      <span class="mr-24">Hidden fields contain</span>
      <span
        *ngFor="let summary of summaries; let $last = last"
        [ngClass]="{ 'mr-8': !$last }"
      >
        <fa-icon
          [icon]="summary.icon"
          class="mr-2"
          [ngClass]="summary.className"
        />
        {{ summary.count }}
      </span>
    </p>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <fa-icon
      icon="exclamation-circle"
      size="2xl"
      class="theme-color-highlight"
    />
    <h2>No data for selection</h2>
  </ng-container>
</div>
