export const editorDefaultOptions: monaco.editor.IEditorOptions = {
  automaticLayout: true,
  scrollBeyondLastLine: false,
  wrappingIndent: 'indent',
  fontFamily: '"Menlo", monospace',
};

export const SEARCH_RESULTS_MAX = 5_000;
export const HISTORY_STATE_MAX = 50;
export const HISTORY_STATE_GROUPING_DELAY_MS = 500;

export type IEditorToolbar = (typeof editorToolbar)[keyof typeof editorToolbar];
export const editorToolbar = {
  editor: [
    'formatDocument',
    'divider',
    'openCommand',
    'divider',
    'searchAll',
    'gotoSymbol',
    'divider',
    'undo',
    'redo',
    'goBack',
    'goForward',
    'zoomOut',
    'zoomIn',
    'collapse',
    'expandAll',
    'menu',
  ],
  diffEditor: [
    'undo',
    'redo',
    'goBack',
    'goForward',
    'zoomOut',
    'zoomIn',
    'collapse',
    'expandAll',
    'menu',
  ],
  readOnlyEditor: [
    'openCommand',
    'divider',
    'searchAll',
    'gotoSymbol',
    'divider',
    'goBack',
    'goForward',
    'zoomOut',
    'zoomIn',
    'collapse',
    'expandAll',
    'menu',
  ],
} as const;
