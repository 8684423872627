import { select } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { first, map, Observable, OperatorFunction } from 'rxjs';
import { isNotNull } from '../ts-helpers';

export function WorkspaceUrlPrefixOperator(
  resourcePath: string
): OperatorFunction<object, string> {
  return (source$: Observable<object>) => {
    return source$.pipe(
      select(WorkspaceSelectors.selectWorkspaceInfo),
      first(isNotNull),
      map(({ id: { name, userId } }) => `${resourcePath}/${userId}/${name}`)
    );
  };
}
