export interface Response<T> {
  success: boolean;
  content?: T;
  code?: number;
  message?: string;
}

// Response interface type guard
export const isResponse = <D>(data: any): data is Response<D> => {
  return data && (data as Response<D>).success !== undefined;
};
