import { NotificationMessagePayload } from '@models';
import { createAction, props } from '@ngrx/store';

const createType = (type: string) => `[Notification] ${type}`;

export const loadNotifications = createAction(createType('Load Notifications'));

export const logout = createAction(createType('Logout'));

export const loadNotificationsSuccess = createAction(
  createType('Load Notifications Success'),
  props<{ notifications: NotificationMessagePayload[] }>()
);

export const loadNotificationsFailure = createAction(
  createType('Load Notifications Failure'),
  props<{ error: any }>()
);

export const dismissNotification = createAction(
  createType('Dismiss Notification'),
  props<{ id: string }>()
);

export const dismissNotificationSuccess = createAction(
  createType('Dismiss Notification Success'),
  props<{ id: string }>()
);

export const dismissNotificationFailure = createAction(
  createType('Dismiss Notification Failure'),
  props<{ error: any }>()
);
