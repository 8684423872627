import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'filteredListHeight',
})
export class FilteredListHeightPipe implements PipeTransform {
  transform(
    length: number | undefined,
    itemSize: number,
    height: number
  ): number | null {
    if (!length) {
      return null;
    }
    const itemSizeLength = length * itemSize;
    const isHeightLessThanItemSize = itemSizeLength > height;
    return isHeightLessThanItemSize ? height : itemSizeLength;
  }
}
