<header mat-dialog-title>
  <h2>Upgrade Workspace</h2>
  <div class="spacer"></div>
  <button
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
    [disabled]="state.isLoading | async"
  >
    <fa-icon icon="times" />
  </button>
</header>

<div class="info">
  <table>
    <tr>
      <th scope="col">Workspace Name</th>
      <th scope="col">Current Version</th>
      <th scope="col">New Version</th>
    </tr>
    <tr *ngIf="workspaceInfo$ | async as info">
      <td>
        {{ info.description }}
      </td>
      <td>
        {{ info.modelVersion }}
      </td>
      <td>
        {{ info.canUpgrade }}
      </td>
    </tr>
  </table>
</div>

<h3 class="mb-0">Release Notes</h3>

<div mat-dialog-content>
  <div *appShowSpinner="releaseNotes$ | async as notes">
    <section *ngFor="let note of notes">
      <h4 class="tag-version-label">{{ note.tag }}</h4>
      <div [innerHTML]="note.bodyAsHtml"></div>
    </section>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close [disabled]="state.isLoading | async">
    Cancel
  </button>
  <button
    mat-stroked-button
    [disabled]="(state.isLoaded | async) === false"
    *appShowSpinner="(state.isLoading | async) === false"
    (click)="upgrade()"
  >
    <fa-icon icon="circle-arrow-up" />
    Upgrade
  </button>
</div>
