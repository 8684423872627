import { UserAbilities } from '@configs';

export enum FileTypeEnum {
  ENUM = 'enum',
  FUNC = 'func',
  RULE = 'rule',
  SYNO = 'synonym',
  TYPE = 'type',
}

export interface FileType {
  extension: FileTypeEnum;
  label: string;
  permissions?: UserAbilities[];
}
