import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleCellState } from '@shared/modules/transform/models/sample-cell-state.enum';

@Component({
  selector: 'app-cell-data',
  templateUrl: './cell-data.component.html',
  styleUrls: ['./cell-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'cell-reg-report',
  },
})
export class CellDataComponent implements ICellRendererAngularComp {
  cell?: transform.DataViewerCell;
  previousCellValue?: string | null;
  previousCellLabel = 'Was';

  readonly sampleCellState = SampleCellState;

  agInit(
    params: ICellRendererParams<
      transform.DataViewerRow,
      transform.DataViewerCell
    >
  ) {
    if (params.data?.errorMessage) {
      return;
    }

    this.cell = params.value;
    const isUpdated = this.cell?.cellState === SampleCellState.Updated;
    const { expectedBaseValue, expectedUpdatedValue } = this.cell || {};
    this.previousCellLabel = isUpdated ? 'Was' : 'Original';
    this.previousCellValue = isUpdated
      ? (expectedUpdatedValue ?? expectedBaseValue)
      : expectedBaseValue;
  }

  refresh(): boolean {
    return false;
  }
}
