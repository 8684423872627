import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { isViewOption, QueryParams, ViewOptions, CustomParams } from '@models';

@Injectable({
  providedIn: 'root',
})
export class QueryParamGuard {
  constructor(private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const viewValue = next.queryParamMap.get(QueryParams.VIEW) as ViewOptions;

    let topPanel = next.queryParamMap.get(QueryParams.TOP_PANEL) === 'true';
    let bottomPanel =
      next.queryParamMap.get(QueryParams.BOTTOM_PANEL) === 'true';

    if (
      isViewOption(viewValue) &&
      next.queryParamMap.has(QueryParams.TOP_PANEL) &&
      next.queryParamMap.has(QueryParams.BOTTOM_PANEL)
    ) {
      if (
        (topPanel && !bottomPanel) ||
        (!topPanel && bottomPanel) ||
        (topPanel && bottomPanel)
      ) {
        return true;
      }
    }

    if (topPanel === false && bottomPanel === false) {
      topPanel = true;
      bottomPanel = false;
    }

    const queryParams: CustomParams = {
      view: isViewOption(viewValue) ? viewValue : 'textual',
      topPanel,
      bottomPanel,
    };

    return this._router.createUrlTree(
      ['workspaces', next.params['workspaceName']],
      {
        queryParams,
        queryParamsHandling: 'merge',
      }
    );
  }
}
