<ng-template #emptyList>
  <h4 class="mb-0 font-weight-bold">Empty list</h4>
</ng-template>

<ng-template #emptyFilterList>
  <h4 class="mb-0 font-weight-bold">No matches</h4>
</ng-template>

<app-rosetta-menu
  [style.width.px]="width"
  [style.--item-size.px]="itemSize"
  *ngrxLet="
    data
      | filter: filterString : filterFunc
      | sortBy: sortByIteratees as dataFiltered
  "
>
  <ng-container *ngIf="data?.length; else emptyList">
    <app-text-input
      class="mb-8"
      [label]="inputPlaceholder"
      [(value)]="filterString"
    />
    <div class="filtered-list__static-content">
      <ng-container
        [ngTemplateOutlet]="staticContentTemplate"
        [ngTemplateOutletContext]="{ $implicit: dataFiltered }"
      />
    </div>
    <div
      class="filtered-list__content"
      [style.height.px]="
        dataFiltered?.length | filteredListHeight: itemSize : height
      "
    >
      <ng-scrollbar
        *ngIf="dataFiltered?.length; else emptyFilterList"
        (updated)="cdkVirtualScrollViewport.checkViewportSize()"
      >
        <!--
      FIX: cdkVirtualScrollViewport calculates viewport incorrectly
      on init, so as a workaround we are calling checkViewportSize() on update
      -->
        <cdk-virtual-scroll-viewport
          #cdkVirtualScrollViewport
          scrollViewport
          [itemSize]="itemSize"
          [maxBufferPx]="itemSize * 5"
          [minBufferPx]="itemSize * 2"
        >
          <div
            *cdkVirtualFor="
              let datum of dataFiltered;
              template: rowContentTemplate
            "
          ></div>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </div>
  </ng-container>
</app-rosetta-menu>
