import {
  AgGridEvent,
  ColDef,
  ColumnVisibleEvent,
  GridApi,
  GridOptions,
  SelectionColumnDef,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { WorkspacePersist } from '@models/persist';
import { InOutAnimation } from '@shared/animations';
import { ROW_HEIGHT } from '@shared/modules/rosetta-table/models/rosetta-table.const';
import { RosettaTableOptions } from '@shared/modules/rosetta-table/models/rosetta-table.model';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { deepEquals, deepMerge, isObjectEmpty } from '@utils';
import { Observable, distinctUntilChanged, map, share, tap } from 'rxjs';
import { PipelineRunInfo } from '../../models';
import { TestPackGrid } from '../../models/test-pack-grid.model';
import { getColDefMap } from './col-defs';
import { dataColDef } from './col-defs/data-col-defs';
import { updateSummary } from './col-defs/summary-col-defs';
import { CustomNoRowsOverlayComponent } from './components/custom-no-rows-overlay/custom-no-rows-overlay.component';
import {
  autoResizeColumnWithChanges,
  autoSizeSampleNameColumn,
  checkAllSelectedRowsAreSelectable,
  checkShowNoRowsOverlay,
  hasChangesUtil,
  setSelectableRowCount,
  setState,
  shouldDisplayCheckboxes,
} from './utils/helpers';

@Component({
  selector: 'app-transform-data-viewer',
  templateUrl: './transform-data-viewer.component.html',
  styleUrls: ['./transform-data-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
  host: {
    class: 'theme-bg',
  },
})
export class TransformDataViewerComponent implements OnInit {
  @Input({ required: true }) dataViewerId: string;
  @Input({ required: true })
  dataViewerSource$: Observable<PipelineRunInfo<TestPackGrid> | null>;
  @Input() getFileName?: () => string;

  @Output() rowClicked = new EventEmitter<transform.DataViewerRow>();
  @Output() events = new EventEmitter<transform.DataViewerEvent>();

  private _showChanges: WorkspacePersist<boolean>;
  private _nodeIds: WorkspacePersist<string[]>;

  context = new transform.DataViewerContext(this);
  private _colDefs = getColDefMap();

  // The table apis are only public so that we can use them in tests
  gridApi!: GridApi<transform.DataViewerRow>;

  readonly tableOptions: RosettaTableOptions<transform.DataViewerRow> = {
    canDownload: true,
    rowHeight: ROW_HEIGHT.Small,
    canHideColumns: true,
    rowClassRules: {
      'ros-row-error': ({ data }) => !!data?.errorMessage,
    },
    /* getRowId is needed to ensure column sort and filers persist */
    getRowId: params => params.data.sampleId,
    getFileName: () => (this.getFileName ? this.getFileName() : 'undefined'),
    isExternalFilterPresent: ({ context }): boolean => context.showChanges,
    doesExternalFilterPass: hasChangesUtil,
    columnMenuSortIteratees: ['headerName', 'headerTooltip'],
    columnMenuDescriptionDirection: 'rtl',
    columnMenuDescriptionGetter: col =>
      col.headerTooltip && col.headerName !== col.headerTooltip
        ? col.headerTooltip
        : null,
  };

  readonly gridOptions: GridOptions<transform.DataViewerRow> = {
    context: this.context,
    onColumnVisible: this._columnVisible.bind(this),
    onModelUpdated: this._modelUpdated.bind(this),
    onGridReady: event => {
      event.context = this.context;
      this.gridApi = event.api;
      this.context.readySubject.next();
    },
    onFirstDataRendered: event => {
      this._restoreSelectedRows();
      this._modelUpdated(event);
    },
    onSelectionChanged: () => {
      this._storeSelectedRows();
      this._onSelectionChanged();
    },
    onFilterChanged: params => {
      checkAllSelectedRowsAreSelectable(params.api);
      shouldDisplayCheckboxes(params.api, this.context);
    },
    noRowsOverlayComponent: CustomNoRowsOverlayComponent,
    suppressMenuHide: true,
    tooltipShowDelay: 0,
  };

  // Observables
  pipelineRunInfo$!: Observable<PipelineRunInfo<transform.DataViewer>>;
  summary$ = this.context.summarySubject.pipe(distinctUntilChanged(deepEquals));

  // Signals
  selectionChanged = signal<transform.DataViewerRow[] | null>(null);
  tabulatorUnsupported = signal(false);
  cols = signal<ColDef<transform.DataViewerRow>[] | null>(null);
  selectionCol = signal<SelectionColumnDef | null>(this._colDefs.checkbox);
  loading = signal(true);

  ngOnInit(): void {
    this._setupPersistedState();
    this.pipelineRunInfo$ = this._getPipelineRunInfoObservable();
  }

  onActionBarEvent(event: transform.DataViewerEvent): void {
    this.events.emit(event);
    this.gridApi.deselectAll();
  }

  onRowClicked(row: transform.DataViewerRow): void {
    this.rowClicked.emit(row);
  }

  toggleShowChanges(): void {
    this.context.showChanges = !this.context.showChanges;
    this._showChanges.value = this.context.showChanges;
    this.gridApi.updateGridOptions({ columnDefs: this.cols() });
    this.gridApi.onFilterChanged();
  }

  private _setupPersistedState(): void {
    this._showChanges = new WorkspacePersist<boolean>(
      `${this.dataViewerId}:show-changes`,
      {
        default: false,
      }
    );
    this._nodeIds = new WorkspacePersist<string[]>(
      `${this.dataViewerId}:node-ids`,
      {
        default: [],
        type: Array,
      }
    );

    this.context.showChanges = this._showChanges.value;
  }

  private _getPipelineRunInfoObservable(): Observable<
    PipelineRunInfo<transform.DataViewer>
  > {
    return this.dataViewerSource$.pipe(
      map(runUpdate => this._enrichRunUpdateData(runUpdate)),
      map(runUpdate => {
        if (runUpdate?.result?.data) {
          const data = runUpdate.result.data;
          this._updateContext(data);
          this._createColDefs(data);
          this.tabulatorUnsupported.set(data.tabulatorUnsupported);
        }
        return runUpdate;
      }),
      share(),
      tap(({ result }) => this.loading.set(result.data === null))
    );
  }

  private _enrichRunUpdateData(
    runUpdate: PipelineRunInfo<TestPackGrid>
  ): PipelineRunInfo<transform.DataViewer> {
    const data = runUpdate?.result?.data;
    const defaultPipelineRunUpdate: PipelineRunInfo = {
      result: {
        data: null,
        details: null,
      },
      errorMessage: null,
    };

    return deepMerge(defaultPipelineRunUpdate, {
      result: { data: data ? new transform.DataViewer(data) : null },
    });
  }

  private _updateContext(dataViewer: transform.DataViewer | null): void {
    this.context.dataState = setState(dataViewer);

    if (!this.context.initialDataLoaded) {
      this.context.initialDataLoaded = true;
    }

    if (this.context.dataState !== transform.DateViewState.HasData) {
      this._createColDefs(null);
      return null;
    }

    // Deselect rows which have had actions
    // applied on them when new data comes in
    if (this.gridApi && this.context.selectedActionRows.length) {
      this.gridApi.deselectAll();
      this.context.selectedActionRows.length = 0;
    }

    // Ensure we we reset the initial
    // sample resize when the id changes
    if (
      !deepEquals(
        dataViewer.testPackGridSelection,
        this.context.dataViewer?.testPackGridSelection
      )
    ) {
      this.context.hasSampleNameBeenResized = false;
    }

    this.context.dataViewer = dataViewer;
  }

  private _storeSelectedRows(): void {
    this._nodeIds.value = this.gridApi.getSelectedNodes().map(node => node.id);
  }

  private _restoreSelectedRows(): void {
    const nodeIds: string[] = this._nodeIds.value;
    setTimeout(() => {
      nodeIds.forEach(id => this.gridApi.getRowNode(id)?.setSelected(true));
    });
  }

  private _updateColDefs(): void {
    const colDefs = this.context.colDefs();
    const colDefsData = colDefs.length > 0 ? this._createColDef(colDefs) : [];

    const computedColDefs =
      this.context.dataViewer?.rows.length > 0
        ? [this._colDefs.sampleName, colDefsData].flat()
        : [];

    this.cols.set(computedColDefs);
  }

  private _createColDef(
    cols: transform.DataViewerCol[]
  ): ColDef<transform.DataViewerRow>[] {
    return cols.map(col => ({
      headerName: col.headerName,
      colId: col.fieldNameId,
      headerTooltip: col.toolTip,
      initialHide: col.hidden,
      ...dataColDef(),
    }));
  }

  private _createColDefs(dataViewer: transform.DataViewer | null): void {
    this.context.dataViewerCols = [];
    this.context.colDefsWithChanges = [];
    this.context.dataViewerColMap = {};

    if (dataViewer) {
      dataViewer.columns.forEach(column => {
        this.context.dataViewerColMap[column.fieldNameId] = column;
        this.context.dataViewerCols.push(column);
        if (column.hasChanges) {
          this.context.colDefsWithChanges.push(column);
        }
      });
    }

    this._updateColDefs();
  }

  private _onSelectionChanged(): void {
    this.selectionChanged.set(this.gridApi.getSelectedRows());
  }

  private _columnVisible({
    visible,
    columns,
    api,
    context,
  }: ColumnVisibleEvent<
    transform.DataViewerRow,
    transform.DataViewerContext
  >): void {
    if (!isObjectEmpty(context.dataViewerColMap) && visible !== undefined) {
      columns?.forEach(col => {
        const column = context.dataViewerColMap[col.getId()];
        if (column) {
          column.hidden = !visible;
        }
      });
    }
    checkAllSelectedRowsAreSelectable(api);
    shouldDisplayCheckboxes(api, context);
    this._updateColDefs();
  }

  private _modelUpdated(event: AgGridEvent<transform.DataViewerRow>): void {
    /* setTimeout required as all the checks below require the model update to have updated the table before running*/
    setTimeout(() => {
      checkShowNoRowsOverlay(event);
      updateSummary(event);
      setSelectableRowCount(event);
      autoResizeColumnWithChanges(event);
      shouldDisplayCheckboxes(event.api, event.context);
      autoSizeSampleNameColumn(event);
    });
  }
}
