import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { ProjectTypeComponent } from './project-type.component';

@NgModule({
  declarations: [ProjectTypeComponent],
  imports: [CommonModule, FontsModule],
  exports: [ProjectTypeComponent],
})
export class ProjectTypeModule {}
