import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AppThemes } from '@configs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(
    private _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  private _renderer: Renderer2;
  private _currentTheme?: AppThemes;

  setTheme(theme: AppThemes) {
    if (this._currentTheme === theme) {
      return;
    }
    this._currentTheme &&
      this._renderer.removeClass(this._document.body, this._currentTheme);
    this._currentTheme = theme;
    this._renderer.addClass(this._document.body, this._currentTheme);
  }
}
