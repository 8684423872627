<header mat-dialog-title>
  <h2>
    Project License Terms
    {{ firstView || data.readOnly ? '' : 'have changed' }}
  </h2>
  <div class="spacer"></div>
  <button
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
    *ngIf="!data.disableClose"
  >
    <fa-icon icon="times" />
  </button>
</header>

<div mat-dialog-content>
  <p class="intro" [ngSwitch]="firstViewOrReadOnlyOrDefault()">
    <ng-container *ngSwitchCase="'first'">
      Before you can access the <strong>{{ modelName }}</strong> project, you
      are required to accept the following.
    </ng-container>
    <ng-container *ngSwitchCase="'ro'">
      The <strong>{{ modelName }}</strong> project is governed by the following
      License Terms.
    </ng-container>
    <ng-container *ngSwitchDefault>
      The <strong>{{ modelName }}</strong> project License Terms have been
      updated. You are required to accept them to continue.
    </ng-container>
  </p>

  <ul>
    <li
      class="card cursor-pointer"
      [ngClass]="{ selected: documentArray.controls[i].valid }"
      *ngFor="let control of documentArray.controls; index as i"
      (click)="toggleCard(i)"
    >
      <ng-container *ngIf="documents(i) as document">
        <h3 class="card-title">
          <app-project-logo
            [data]="getModel(document.modelId) | async"
            size="sm"
          />
          <span class="card-title-text">{{ document.modelName }}</span>
        </h3>

        <p class="card-text">
          <mat-checkbox
            [formControl]="documentArray.controls[i]"
            color="primary"
            required
            appStopPropagation
          >
            I have read and I accept the {{ document.modelName }}
            <a [routerLink]="document.documentLink" target="_blank">{{
              document.displayName
            }}</a
            >.
          </mat-checkbox>
        </p>

        <p class="card-privacy-statement" *ngIf="document.privacyStatementLink">
          I have also read and understood the {{ document.modelName }}
          <a [href]="document.privacyStatementLink" target="_blank"
            >Privacy Statement</a
          >.
        </p>
      </ng-container>
    </li>
  </ul>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <ng-container *ngIf="!data.readOnly">
    <button
      mat-stroked-button
      (click)="onSubmit()"
      [disabled]="documentArray.invalid"
      *appShowSpinner="(isLoading$ | async) === false"
    >
      <fa-icon icon="circle-check" />
      Accept
    </button>
  </ng-container>
</div>
