import { TaskService } from '@core/services/task.service';
import { Task, TaskStatus } from '@models';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';

import { ApiExportDTO } from '../models/api-export-dto.model';
import { ApiExportService } from './api-export.service';

export interface RunPayload {
  task: Task;
  payload: any;
}
export interface IApiExport {
  name: string;
  type: ApiExportDTO.ApiExportType;
  url?: string;

  upstreamTaskStatus$: Observable<TaskStatus>;
  apiExportStatus$: Observable<TaskStatus>;

  onInit(): void;
}

export class ApiExport implements IApiExport {
  constructor(
    public name: string,
    public type: ApiExportDTO.ApiExportType,
    public url: string,
    private _taskService: TaskService
  ) {}

  upstreamTaskStatus$!: Observable<TaskStatus>;
  apiExportStatus$!: Observable<TaskStatus>;

  onInit(): void {
    this.upstreamTaskStatus$ = this._taskService.getTaskStatus(
      Task.StaticJavaCompilation
    );
    this.apiExportStatus$ = this._taskService.getTaskStatus(
      Task.StaticJavaCompilation
    );
  }
}

export class IngestionApiExport implements IApiExport {
  constructor(
    public name: string,
    public ingestionEnv: string,
    private _apiExportService: ApiExportService,
    private _taskService: TaskService
  ) {}

  private _lastRun$ = new BehaviorSubject<number>(-1);

  type = ApiExportDTO.ApiExportType.INGESTION;
  url?: string;

  lastRun$ = this._lastRun$.asObservable();
  upstreamTaskStatus$!: Observable<TaskStatus>;
  apiExportStatus$!: Observable<TaskStatus>;

  exportResult$ = this._apiExportService.getResultMap$.pipe(
    map(resultMap => resultMap[this.name]),
    tap(apiExportResult => (this.url = apiExportResult?.apiURL))
  );

  onInit() {
    // TODO: enable once API export has been refactored
    // this.upstreamTaskStatus$ = this.taskService.getTaskStatus(
    //   Task.IngestCompilation
    // );
    this.upstreamTaskStatus$ = of(TaskStatus.Finished);

    this.apiExportStatus$ = this._taskService.getSubTaskStatus(
      Task.IngestionExport,
      this.name
    );
  }

  run(): RunPayload {
    this._lastRun$.next(Date.now());
    return {
      task: Task.IngestionExport,
      payload: {
        name: this.name,
        ingestionEnvName: this.ingestionEnv,
      },
    };
  }
}
