import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { BaseWorkspaceStateService } from '@core/services';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { mapDiagnosticData } from '@shared/modules/code-view/code-view.utils';
import { selectRouterParams } from '@store/router/router.selector';
import {
  BehaviorSubject,
  Observable,
  UnaryFunction,
  catchError,
  combineLatest,
  first,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { CodeViewData, PipelineRunInfo } from '../../models';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '../../models/transform-config.model';
import { TRANSFORM_ROUTE_PARAMS } from '../../models/transform.const';
import { TransformStorageService } from '../../services/transform-storage.service';
import { TransformService } from '../../services/transform.service';

@Injectable()
export class TransformDetailsService extends BaseWorkspaceStateService {
  constructor(
    private _navigate: RosettaNavigationService,
    private _notificationService: NotificationService,
    private _transformStorageService: TransformStorageService,
    private _transformService: TransformService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {
    super();
  }

  viewModel$ = combineLatest([
    this._transformService.transformReady$,
    this._store.select(selectRouterParams),
  ]).pipe(
    first(),
    switchMap(([, params]) =>
      this._transformStorageService
        .getSelection(
          params[TRANSFORM_ROUTE_PARAMS.pipelineId],
          params[TRANSFORM_ROUTE_PARAMS.testPackId],
          params[TRANSFORM_ROUTE_PARAMS.sampleId]
        )
        .pipe(this._catchError())
    ),
    shareReplay(1)
  );

  private _rerunCodeViews$ = new BehaviorSubject<void>(null);
  private _diagnosticDataFilter$ = new BehaviorSubject<string>('');

  private _codeViewDataStream$ = this._rerunCodeViews$.pipe(
    switchMap(() =>
      this.viewModel$.pipe(
        switchMap(({ selection, sampleDef }) =>
          this._transformStorageService.codeViewData(selection, sampleDef.id)
        ),
        startWith(null)
      )
    ),
    shareReplay<PipelineRunInfo<unknown> | null>(1)
  );

  private _codeViewDataFinal$ = this._codeViewDataStream$.pipe(
    map(pipelineUpdate => {
      if (
        pipelineUpdate &&
        pipelineUpdate.result.details.currentPipeline ===
          pipelineUpdate.result.details.totalPipelines &&
        pipelineUpdate.result.data
      ) {
        return pipelineUpdate as PipelineRunInfo<CodeViewData>;
      }
      return null;
    }),
    this._codeViewError()
  );

  status$ = merge(
    this._codeViewDataStream$,
    this._transformService.markRunAsStale$
  );

  codeViewInput$ = this._codeViewDataFinal$.pipe(
    map(pipelineUpdate => pipelineUpdate?.result.data?.input)
  );

  codeViewOutput$ = this._codeViewDataFinal$.pipe(
    map(pipelineUpdate => pipelineUpdate?.result.data?.output)
  );

  diagnosticData$ = combineLatest([
    this._diagnosticDataFilter$,
    this.codeViewOutput$,
  ]).pipe(
    map(
      ([filter, diagnosticsData]) =>
        diagnosticsData && mapDiagnosticData(filter, diagnosticsData)
    )
  );

  override onWorkspaceSwitch(): void {}

  rerunCodeViews(): void {
    this._rerunCodeViews$.next();
  }

  filterDiagnostics(searchTerm: string): void {
    this._diagnosticDataFilter$.next(searchTerm);
  }

  private _catchError<T>(): UnaryFunction<Observable<T>, Observable<T | null>> {
    return catchError(() => {
      this._notificationService.showError({
        message: this._config.text.sampleNotFound,
      });
      this._navigate.updateBottomPanel([this._transformConfig.url]);
      return of(null);
    });
  }

  private _codeViewError<T>(): UnaryFunction<
    Observable<T>,
    Observable<T | null>
  > {
    return catchError<T, Observable<null>>(e => {
      this._notificationService.showError({
        message: this._config.text.codeViewError,
      });

      // eslint-disable-next-line no-console
      console.error(e);

      return of(null);
    });
  }
}
