<app-filtered-list [data]="list">
  <ng-template #staticContent>
    <mat-checkbox
      (change)="$event ? masterToggle($event) : null"
      [checked]="isAllSelected()"
      [indeterminate]="isAnySelected()"
    >
      All
    </mat-checkbox>
  </ng-template>
  <ng-template #rowContent let-item>
    <mat-checkbox
      appStopPropagation
      (change)="toggle(item)"
      [checked]="isChecked(item)"
    >
      {{ item }}
    </mat-checkbox>
  </ng-template>
</app-filtered-list>
