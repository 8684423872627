import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontsModule } from '@app/fonts/fonts.module';
import { StripHtmlPipe } from '@shared/pipes';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { NotificationService } from './services/notification.service';

@NgModule({
  declarations: [SnackBarComponent],
  providers: [NotificationService],
  imports: [
    CommonModule,
    FontsModule,

    // Material
    MatSnackBarModule,
    MatButtonModule,

    // Standalone
    StripHtmlPipe,
  ],
})
export class SnackBarModule {}
