import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageKey } from '@configs';
import { AuthService } from '@core/services';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService: CookieService,
    private _authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this._cookieService.get(StorageKey.AccessToken);
    const newRequest = !(this._authService.validSession() && accessToken)
      ? req
      : req.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

    return next.handle(newRequest);
  }
}
