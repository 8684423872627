<button
  mat-icon-button
  [appRosettaOverlayTarget]="overlay"
  *ngrxLet="numberNotifications$ as numberNotifications"
>
  <span
    [matBadge]="numberNotifications"
    [matBadgeHidden]="numberNotifications === 0"
    matBadgeSize="small"
    matBadgeColor="accent"
  >
    <fa-icon [icon]="['far', 'bell']" size="lg" />
  </span>
</button>

<app-rosetta-overlay #overlay>
  <app-rosetta-menu maxWidth="380">
    <div *appShowSpinner="hasLoaded$ | async">
      <app-inline-error *ngIf="notificationError$ | async">
        <small class="mb-0"
          >There was an error fetching your notifications.
          <a
            class="inline-link"
            href="javascript: void(0);"
            (click)="fetchNotifications()"
            >Retry</a
          ></small
        >
      </app-inline-error>

      <ng-container *ngIf="notifications$ | async as notifications">
        <div *ngIf="notifications.length === 0">
          <p class="mb-0 text-align-center">You have no new notifications</p>
        </div>

        <div class="notification" *ngFor="let notification of notifications">
          <div class="notification__text">
            <h3 class="font-weight-semi-bold mb-0">{{ notification.title }}</h3>
            <p>{{ notification.content }}</p>
          </div>

          <button
            *ngIf="notification.dismissible"
            class="notification__action"
            mat-stroked-button
            (click)="dismissNotification(notification.id)"
          >
            Dismiss
          </button>
        </div>
      </ng-container>
    </div>
  </app-rosetta-menu>
</app-rosetta-overlay>
