import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FadeInAnimation } from '@shared/animations';
import { RosettaOverlayTargetDirective } from './rosetta-overlay-target.directive';

@Component({
  selector: 'app-rosetta-overlay',
  animations: [FadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-template
    ><div [@fadeIn]="state">
      <ng-content />
    </div>
  </ng-template>`,
  styles: [
    `
      @use '@angular/cdk' as cdk;
      @include cdk.overlay();
    `,
  ],
})
export class RosettaOverlayComponent implements OnDestroy {
  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}

  @ViewChild(TemplateRef, { static: true })
  private _templateRef!: TemplateRef<any>;
  private _overlayRef?: OverlayRef;

  protected state: 'enter' | 'void' | 'exit' = 'enter';

  @Input() scrollStrategy: keyof ScrollStrategyOptions = 'reposition';

  overlayTarget!: any;

  toggle() {
    if (this._overlayRef) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this._overlayRef = this._overlay.create(this._getOverlayConfig());
    this._overlayRef.attach(
      new TemplatePortal(this._templateRef, this._viewContainerRef)
    );
    this._overlayRef.backdropClick().subscribe(() => this.close());
    this.state = 'enter';
  }

  close() {
    this.state = 'exit';
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = undefined;
    }
  }

  ngOnDestroy() {
    this.close();
  }

  private _getOverlayConfig(): OverlayConfig {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(this.overlayTarget.elementRef)
      .withPush(true)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 4,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -4,
        },
      ]);

    const scrollStrategy =
      this._overlay.scrollStrategies[this.scrollStrategy]();
    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      // This is required to ensure that there is no backdrop when using the hover directive
      hasBackdrop: this.overlayTarget instanceof RosettaOverlayTargetDirective,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }
}
