<header mat-dialog-title>
  <h2>{{ data.title || 'Confirm' }}</h2>
  <div class="spacer"></div>
  <button
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
    *ngIf="!dialogRef.disableClose"
  >
    <fa-icon icon="times" />
  </button>
</header>

<!--
  Extra wrapper required to prevent default style to
  remove top padding when content is next to the title.
-->
<div>
  <mat-dialog-content>
    <p [innerHTML]="data.message || 'Are you sure?'"></p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close *ngIf="!dialogRef.disableClose">
      {{ data.cancelButtonText || 'Cancel' }}
    </button>
    <button mat-stroked-button cdkFocusInitial [mat-dialog-close]="true">
      <fa-icon [icon]="data.confirmButtonIcon || 'circle-check'" />
      {{ data.confirmButtonText || 'Yes' }}
    </button>
  </mat-dialog-actions>
</div>
