import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { FileTreeEvent, FileTreeNode, WorkspaceItemState } from '@models';
import { FileTreeService } from '@shared/modules/file-tree/file-tree.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-file-tree[workspaceItems]',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileTreeService],
  exportAs: 'fileTree',
  host: {
    class: 'app-file-tree',
  },
})
export class FileTreeComponent implements AfterViewInit {
  constructor(
    public service: FileTreeService,
    private _cdr: ChangeDetectorRef
  ) {}

  @ViewChild(NgScrollbar) scrollbar!: NgScrollbar;

  @Input() workspaceItems!: Observable<WorkspaceItemState[]>;
  @Input({ transform: booleanAttribute }) showOptions = true;

  get dataSource() {
    return this.service.dataSource;
  }

  get treeControl() {
    return this.service.treeControl;
  }

  hasChild = (_: number, node: FileTreeNode) =>
    !!node.children && node.children.length > 0;

  ngAfterViewInit() {
    this.service.init(this.scrollbar, this.workspaceItems);
  }

  gotoCurrentFile() {
    this.service.gotoCurrentFile();
  }

  expandAll() {
    this.service.treeControl.expandAll();
    this._cdr.markForCheck();
  }

  collapseAll() {
    this.service.treeControl.collapseAll();
    this._cdr.markForCheck();
  }

  onClick(event: FileTreeEvent) {
    this.service.onClick(event);
  }
}
