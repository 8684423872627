<ng-template #noProjects>-</ng-template>

<ng-container *ngIf="modelIds.length > 0; else noProjects">
  <div class="project-cell__wrapper">
    <app-project-logo
      *ngFor="let modelId of modelIds.slice(0, maxCount)"
      [size]="size"
      [data]="modelId | model | async"
    />
  </div>
  <div class="project-cell__overflow-count" *ngIf="modelIds.length > maxCount">
    &#43; {{ modelIds.length - maxCount }}
  </div>
</ng-container>
