import { wrapIdFields } from '@utils';
import { TransientSample } from '../transient-sample.model';
import { TransformDTO } from '../transform-dto.model';
import { map, OperatorFunction } from 'rxjs';
import { TestPackDef } from '../test-pack-def.model';

export const toTransientSampleDTO = (
  transientSample?: TransientSample | null
): TransformDTO.TransientSample | null => {
  return !!transientSample
    ? {
        testPackName: transientSample?.testPackDef?.name,
        sample: wrapIdFields({
          sampleDef: transientSample.sample.sampleDef,
          json: transientSample.sample.json,
        }),
      }
    : null;
};

export const fromTransientSampleDTO = (
  testPackDef: TestPackDef
): OperatorFunction<TransformDTO.TransientSample, TransientSample> => {
  return map(transientTestPackDTO => ({
    testPackDef,
    sample: {
      sampleDef: {
        ...transientTestPackDTO.sample.sampleDef,
        id: transientTestPackDTO.sample.sampleDef.id.id,
      },
      json: transientTestPackDTO.sample.json,
    },
  }));
};
