import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-inline-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <fa-icon [ngClass]="['mr-8', iconTheme()]" size="lg" [icon]="icon()" />
    <span [ngClass]="textTheme()"><ng-content /></span>
  `,
  styles: [
    `
      @use 'partials/variables' as var;

      :host {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: var.$border-radius;
        word-break: break-word;
        font-weight: bold;

        &:empty {
          display: none;
        }
      }
    `,
  ],
  host: {
    class: 'inline-error theme-bg-light',
  },
})
export class InlineErrorComponent {
  @Input() set errorType(value: 'warning' | 'error') {
    if (value === 'warning') {
      this.isError.set(false);
      this.icon.set('exclamation-triangle');
      this.iconTheme.set('theme-color-yellow-alt');
      this.textTheme.set('theme-color-50');
    }
  }

  isError = signal(true);
  icon = signal<IconName>('exclamation-circle');
  iconTheme = signal<string>('theme-color-warn');
  textTheme = signal<string>('theme-color-warn');
}
