import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, UrlTree } from '@angular/router';
import { LOGGED_IN_FALLBACK } from '@configs';
import { AuthService } from '@core/services';
import { LoadingState, RouteData } from '@models';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/index';
import { AuthSelectors } from '@store/selectors';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { ensureArray, isNotNull } from '@utils';
import { Observable, catchError, first, map, of, switchMap, tap } from 'rxjs';

export const PermissionGuardFunc = (
  route: Route | ActivatedRouteSnapshot
): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const authService = inject(AuthService);
  const fallbackRoute = inject(LOGGED_IN_FALLBACK);

  const ability = ensureArray((route.data as RouteData).abilityName);
  const applyTo = (route.data as RouteData)?.applyTo;
  const hasAllAbilities = (route.data as RouteData)?.hasAllAbilities;

  const checkAbility$ = store.select(AuthSelectors.selectAuthFeature).pipe(
    tap(state => {
      if (!authService.userIsAuthenticated()) {
        throw new Error('User not authenticated!');
      }
      if (state.loginState.state === LoadingState.INITIAL) {
        store.dispatch(AuthActions.refreshUser());
      }
    }),
    map(state => state.user),
    first(isNotNull),
    map(() => {
      return (
        (hasAllAbilities
          ? authService.hasAll(ability)
          : authService.hasOne(ability)) || fallbackRoute
      );
    }),
    catchError(() => of(fallbackRoute))
  );

  return !applyTo
    ? checkAbility$
    : store.select(WorkspaceSelectors.isReadonlyWorkspace).pipe(
        switchMap(isReadonly => {
          if (isReadonly && applyTo === 'ro') {
            return checkAbility$;
          }

          if (!isReadonly && applyTo === 'rw') {
            return checkAbility$;
          }

          return of(true);
        })
      );
};
