import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { IDomainModelDetails } from '@models';

@Component({
  selector: 'app-project-row',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-project-logo [data]="model" size="xs" />
    {{ model?.name || 'Unknown Project' }}
    <fa-icon icon="check" size="xs" *ngIf="showAccepted && model?.accepted" />
  `,
})
export class ProjectRowComponent {
  @Input({ transform: booleanAttribute }) showAccepted = false;
  @Input() model?: IDomainModelDetails;
}
