import { map, OperatorFunction } from 'rxjs';
import { TestPackDef } from '../test-pack-def.model';
import { TransformDTO } from '../transform-dto.model';

export const fromTestPackDefsDTO = (): OperatorFunction<
  TransformDTO.TestPackDef[],
  TestPackDef[]
> => {
  return map(dtoList =>
    dtoList.map(dto => ({
      ...dto,
      id: dto.id.id,
    }))
  );
};

export const fromTestPackDefDTO = (): OperatorFunction<
  TransformDTO.TestPackDef,
  TestPackDef
> => {
  return map(dto => ({
    ...dto,
    id: dto.id.id,
  }));
};
