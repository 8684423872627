import { PATH_JOIN_CHAR_WITH_SPACE } from '@configs';
import { SampleRowAction } from '@shared/modules/transform/models/sample-action.enum';
import { SampleCellState } from '@shared/modules/transform/models/sample-cell-state.enum';
import { SampleRowState } from '@shared/modules/transform/models/sample-row-state.enum';
import { SampleRow } from '@shared/modules/transform/models/sample-row.model';
import { TestPackGridSelection } from '@shared/modules/transform/models/test-pack-grid-selection.model';
import { TestPackGrid } from '@shared/modules/transform/models/test-pack-grid.model';
import { TransformDTO } from '@shared/modules/transform/models/transform-dto.model';
import { multipleExist } from '@utils';
import { groupBy } from 'lodash-es';
import { appendIndexesToNodes } from '../../helpers/append-indexes-to-nodes.helper';
import { setHeaderNameToShortest } from '../../helpers/set-header-name-to-shortest.helper';
import { SampleRowStatus } from '../sample-row-status.enum';

export class DataViewerCol {
  constructor(cell: DataViewerCell) {
    this.addCell(cell);
    this.headerName = cell.cell.name;
    this.fieldNameId = cell.fieldNameId;
    this.path = appendIndexesToNodes(cell.path);
    this.toolTip = this.path.join(PATH_JOIN_CHAR_WITH_SPACE);
  }

  cells: DataViewerCell[] = [];
  headerName: string;
  fieldNameId: string;
  toolTip: string;
  path: string[];
  hasChanges = false;

  get hidden(): boolean {
    return this._hidden;
  }
  set hidden(value: boolean) {
    this._hidden = value;
    this.cells.forEach(cell => (cell.hidden = value));
  }
  private _hidden = false;

  addCell(cell: DataViewerCell): void {
    if (!this.hasChanges) {
      this.hasChanges = cell.hasChanges;
    }

    cell.hidden = this.hidden;
    this.cells.push(cell);
  }
}

export class DataViewerCell {
  constructor(public cell: TransformDTO.SampleCell) {}

  hidden: boolean;

  get cellState(): SampleCellState {
    return this.cell.cellState;
  }

  get fieldNameId(): string {
    return this.cell.columnId;
  }

  get path(): string[] {
    return this.cell.pathElements;
  }

  get value(): string | undefined {
    return this.cell.outputValue;
  }

  get expectedBaseValue(): string | undefined {
    return this.cell.expectedBaseValue;
  }

  get expectedUpdatedValue(): string | undefined {
    return this.cell.expectedUpdatedValue;
  }

  get hasChanges(): boolean {
    return this.cell.cellState !== SampleCellState.Valid;
  }
}

export class DataViewerRow {
  constructor(dataViewer: DataViewer, row: SampleRow) {
    this.sampleId = row.sampleId;
    this.sampleName = row.sampleName;
    this.sampleRowState = row.sampleRowState;
    this.sampleRowStatus = row.sampleRowStatus;
    this.storedOnClient = row.storedOnClient || false;
    this.diagnostics = row.diagnostics;
    this.errorMessage = row.errorMessage;

    this._createCellMap(row.cells);
    this.testPackGridSelection = dataViewer.testPackGridSelection;
    this.actions = row.actions;

    this.selectable = row.actions.length > 0;
    this.hasChanges = row.sampleRowState !== SampleRowState.Valid;
    this.cellStates = groupBy(Array.from(this.cellMap.values()), 'cellState');

    if (!dataViewer.canPerformActions && row.actions.length > 0) {
      dataViewer.canPerformActions = true;
    }
  }

  selectable: boolean;
  actions: SampleRowAction[];
  hasChanges: boolean; // Display row when show changes is enabled

  sampleId: string;
  sampleName: string;
  sampleRowState: SampleRowState;
  sampleRowStatus: SampleRowStatus;
  storedOnClient: boolean;
  diagnostics?: TransformDTO.Diagnostics;
  errorMessage?: string;

  testPackGridSelection: TestPackGridSelection;
  cellStates: Record<string, DataViewerCell[]>;
  cellMap = new Map<string, DataViewerCell>();
  cellList: DataViewerCell[] = [];

  checkAction(...actions: SampleRowAction[]): boolean {
    return multipleExist(this.actions, actions);
  }

  private _createCellMap(cells: TransformDTO.SampleCell[]): void {
    this.cellList = [];
    cells.forEach(cell => {
      const dataViewerCell = new DataViewerCell(cell);

      // TODO: create a fields ID from its path and a fieldName used to display value in column
      this.cellMap.set(cell.columnId, dataViewerCell);
      this.cellList.push(dataViewerCell);
    });
  }
}

export class DataViewer {
  constructor(testPackGrid: TestPackGrid) {
    this.canDelete = testPackGrid.rows.some(row =>
      row.actions.includes(SampleRowAction.Delete)
    );

    this.testPackGridSelection = {
      pipelineDef: testPackGrid.pipelineDef,
      testPackDef: testPackGrid.testPackDef,
    };
    this.rows = testPackGrid.rows.map(row => new DataViewerRow(this, row));
    this.columns = this._buildColumnsFromRows(this.rows);
    this.tabulatorUnsupported = this.rows.some(
      row => row.sampleRowState === SampleRowState.SkippedTabulator
    );
  }

  rows: DataViewerRow[];
  columns: DataViewerCol[];
  canDelete: boolean;
  testPackGridSelection: TestPackGridSelection;
  canPerformActions = false;
  tabulatorUnsupported = false;

  private _buildColumnsFromRows(
    dataViewerRows: DataViewerRow[]
  ): DataViewerCol[] {
    const columns = new Map<string, DataViewerCol>();
    dataViewerRows.forEach(dataViewerRow => {
      dataViewerRow.cellMap.forEach(dataViewerCell => {
        if (columns.has(dataViewerCell.fieldNameId)) {
          columns.get(dataViewerCell.fieldNameId).addCell(dataViewerCell);
        } else {
          columns.set(
            dataViewerCell.fieldNameId,
            new DataViewerCol(dataViewerCell)
          );
        }
      });
    });

    return setHeaderNameToShortest([...columns.values()]);
  }
}
