import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { COLOR_DARK } from '@configs';
import { Color, IDomainModelDetails } from '@models';
import { contrast } from '@utils';

export type Sizes = 'xs' | 'sm' | 'md' | 'lg';

@Component({
  selector: 'app-project-logo',
  templateUrl: './project-logo.component.html',
  styleUrls: ['./project-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'project-logo',
  },
})
export class ProjectLogoComponent implements OnChanges {
  @HostBinding('style.borderColor') borderColor!: Color;
  @HostBinding('class') classList: string[] = [];

  @Output() removeAction = new EventEmitter<IDomainModelDetails>();

  @Input() size: Sizes = 'md';
  @Input() data?: IDomainModelDetails | null;

  logoName?: string;
  shortnameStyle?: Record<string, any>;

  ngOnChanges({ size, data }: SimpleChanges) {
    if (data.currentValue) {
      this._setAttributes(data.currentValue, this.size);
    }
    if (size && size.firstChange) {
      this._setSize(size.currentValue);
    }
  }

  private _setSize(size: Sizes) {
    switch (size) {
      case 'xs':
        this.classList.push('tile-x-small');
        break;
      case 'sm':
        this.classList.push('tile-small');
        break;
      case 'lg':
        this.classList.push('tile-large');
        break;
    }
  }

  private _setAttributes(
    { id, primaryColor = COLOR_DARK }: IDomainModelDetails,
    size: Sizes = 'md'
  ) {
    if (!size.includes('xs')) {
      this.logoName = id;
    }

    this.borderColor = primaryColor;
    this.shortnameStyle = {
      color: contrast(primaryColor),
      backgroundColor: primaryColor,
    };
  }
}
