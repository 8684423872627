import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { CollapsiblePanelBodyComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel-body.component';
import { CollapsiblePanelGroupComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel-group.component';
import { CollapsiblePanelHeaderComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel-header/collapsible-panel-header.component';
import { CollapsiblePanelToggleButtonComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel-toggle-button.component';
import { CollapsiblePanelComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel/collapsible-panel.component';

const components = [
  CollapsiblePanelToggleButtonComponent,
  CollapsiblePanelHeaderComponent,
  CollapsiblePanelBodyComponent,
  CollapsiblePanelGroupComponent,
  CollapsiblePanelComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, FontsModule, MatButtonModule],
})
export class CollapsiblePanelModule {}
