import { WorkspaceItemState } from '@models';

export enum NavigateDirection {
  Next = 'Next',
  Previous = 'Previous',
}

export interface NavigateObject {
  direction: NavigateDirection;
  modifiedItems: WorkspaceItemState[];
}

export interface ItemProblem {
  message: string;
}

export interface WorkspaceItemDiagnostic {
  uri: string;
  problems: ItemProblem[];
}
