import { StorageKey } from '@configs';
import {
  Action,
  ActionReducer,
  INIT as INIT_ACTION,
  UPDATE as UPDATE_ACTION,
} from '@ngrx/store';
import * as RecentWorkspacesReducers from '@store/recent-workspaces/recent-workspaces.reducers';
import * as AppReducers from '@store/reducers/app.reducer';
import * as WorkspaceReducers from '@store/workspace/reducers';
import { deepMerge } from '@utils';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppState } from './reducers';

type AppStateKeys = keyof AppState;

export const mergeReducer = () => {
  const hydrateCache = new Map<AppStateKeys, any>();

  return (
    state: Partial<AppState>,
    rehydrate: Record<AppStateKeys, any> | null,
    action: Action
  ) => {
    if (hydrateCache.size > 0) {
      hydrateCache.forEach((rehydrateState, stateKey) => {
        if (state[stateKey]) {
          state = deepMerge(state, { [stateKey]: rehydrateState });
          hydrateCache.delete(stateKey);
        }
      });
    }

    if (
      (action.type === INIT_ACTION || action.type === UPDATE_ACTION) &&
      rehydrate
    ) {
      Object.keys(rehydrate).forEach(stateKey => {
        const typedStateKey = stateKey as AppStateKeys;
        if (state[typedStateKey]) {
          state = deepMerge(state, { [stateKey]: rehydrate[typedStateKey] });
          hydrateCache.delete(typedStateKey);
        } else {
          hydrateCache.set(typedStateKey, rehydrate[typedStateKey]);
        }
      });
    }

    return state;
  };
};

const localStorageSyncReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> =>
  localStorageSync({
    keys: [
      {
        [WorkspaceReducers.featureKey]: [
          'selectedWorkspaceItemId',
          'workspaceInfo',
        ],
      },
      {
        [AppReducers.featureKey]: ['theme', 'presenting'],
      },
      {
        [RecentWorkspacesReducers.featureKey]: ['data'],
      },
    ],
    rehydrate: true,
    storageKeySerializer: key => `${StorageKey.Store}:${key}`,
    mergeReducer: mergeReducer(),
  })(reducer);

export const metaReducers = [localStorageSyncReducer];
