import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import {
  GridApi,
  ICellRendererParams,
  IRowNode,
} from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';

@Component({
  standalone: true,
  imports: [CommonModule, FontsModule, MatButtonModule],
  selector: 'app-edit-row-cell',
  templateUrl: './edit-row-cell.component.html',
  styleUrls: ['./edit-row-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRowCellComponent<T extends object>
  implements ICellRendererAngularComp
{
  constructor(private _cdr: ChangeDetectorRef) {}

  private _node!: IRowNode;
  private _gridApi!: GridApi;
  private _context!: { currentRow?: EditRowCellComponent<T> };
  editMode = false;

  agInit({ node, api, context }: ICellRendererParams<T>): void {
    this._node = node;
    this._gridApi = api;
    this._context = context;
  }

  refresh(): boolean {
    return false;
  }

  editRow(): void {
    this._context.currentRow = this;
    this._enableEditMode();
    const firstColumn = this._gridApi.getColumns()?.[0];
    const colKey = firstColumn?.getColId();

    if (this._node.rowIndex === null || !colKey) {
      throw new Error('Rosetta: Unable to editor row!');
    }

    this._gridApi.setFocusedCell(this._node.rowIndex, colKey);
    this._gridApi.startEditingCell({
      rowIndex: this._node.rowIndex,
      colKey,
    });
  }

  submit(): void {
    this._gridApi.stopEditing();
  }

  cancel(): void {
    this._gridApi.stopEditing(true);
  }

  disableEditMode(): void {
    this.editMode = false;
    this._cdr.markForCheck();
  }

  private _enableEditMode(): void {
    this.editMode = true;
    this._cdr.markForCheck();
  }
}
