<ng-template #update>
  <p>Update expectations for {{ sampleLabel }}?</p>

  <p>
    Changes to the selected {{ expectationLabel }} will be recorded in your
    workspace.
  </p>
</ng-template>

<ng-template #revert>
  <p>Discard expectations for {{ sampleLabel }}?</p>

  <p>
    Changes to the selected {{ expectationLabel }} will be removed from your
    workspace.
  </p>
</ng-template>

<ng-template #delete>
  <p>Delete sample '{{ data.rows[0].sampleName }}'?</p>
</ng-template>

<header mat-dialog-title>
  <h2>{{ config[data.type].header }}</h2>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <fa-icon icon="times" />
  </button>
</header>

<!--
  Extra wrapper required to prevent default style to
  remove top padding when content is next to the title.
-->
<div>
  <mat-dialog-content [ngSwitch]="data.type">
    <ng-container
      *ngSwitchCase="sampleRowAction.Accept"
      [ngTemplateOutlet]="update"
    />
    <ng-container
      *ngSwitchCase="sampleRowAction.Revert"
      [ngTemplateOutlet]="revert"
    />
    <ng-container
      *ngSwitchCase="sampleRowAction.Delete"
      [ngTemplateOutlet]="delete"
    />
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <!-- Return undefined when closing a dialog to prevent any further action -->
  <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
  <button
    cdkFocusInitial
    mat-stroked-button
    [disabled]="itemLength < 1"
    [mat-dialog-close]="true"
  >
    <fa-icon [icon]="config[data.type].icon" />
    <span>{{ config[data.type].label }}</span>
  </button>
</mat-dialog-actions>
