<mat-form-field [style.maxWidth.px]="maxWidth">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    autocomplete="off"
    [value]="value || ''"
    [disabled]="disabled"
    (appInputValue)="onValueChange($event)"
  />
  <button
    *ngIf="value?.length"
    matIconSuffix
    mat-icon-button
    [disabled]="disabled"
    (click)="onValueChange()"
    class="mr-8"
  >
    <fa-icon icon="times" />
  </button>
  <div matIconPrefix>
    <ng-content select="[textInputPrefix]" />
  </div>
</mat-form-field>
