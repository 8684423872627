import { NotificationReducers as NotificationReducer } from '@core/modules/notifications/store';
import { RouterStateUrl } from '@core/modules/store/custom-route-serializer';
import { SettingsReducers } from '@features/settings/store';
import { SupportReducers } from '@features/support/store';
import * as ProjectsReducer from '@features/workspace-manager/store/projects/projects.reducer';
import {
  DEFAULT_ROUTER_FEATURENAME,
  routerReducer,
  RouterReducerState,
} from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import * as RecentWorkspacesReducer from '../recent-workspaces/recent-workspaces.reducers';
import * as ServerStatusReducers from '../server-status/server-status.reducers';
import * as WorkspaceReducer from '../workspace/reducers';
import * as AppReducer from './app.reducer';
import * as AuthReducer from './auth.reducer';
import * as TaskReducer from './task.reducer';

export * from './auth.reducer';
export * as TaskReducers from './task.reducer';

export type AppState = AppStateInitial & AppStateLazyLoaded;

export interface AppStateInitial {
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState<RouterStateUrl>;
  [AppReducer.featureKey]: AppReducer.State;
  [AuthReducer.featureKey]: AuthReducer.State;
  [TaskReducer.featureKey]: TaskReducer.State;
  [NotificationReducer.featureKey]: NotificationReducer.State;
  [WorkspaceReducer.featureKey]: WorkspaceReducer.State;
  [RecentWorkspacesReducer.featureKey]: RecentWorkspacesReducer.State;
  [ServerStatusReducers.featureKey]: ServerStatusReducers.State;
}
export interface AppStateLazyLoaded {
  [SupportReducers.featureKey]: SupportReducers.State;
  [SettingsReducers.featureKey]: SettingsReducers.State;
  [ProjectsReducer.projectsFeatureKey]: ProjectsReducer.State;
}

export const reducers: ActionReducerMap<AppStateInitial> = {
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
  [AppReducer.featureKey]: AppReducer.reducer,
  [AuthReducer.featureKey]: AuthReducer.reducer,
  [TaskReducer.featureKey]: TaskReducer.reducer,
  [NotificationReducer.featureKey]: NotificationReducer.reducer,
  [WorkspaceReducer.featureKey]: WorkspaceReducer.reducer,
  [RecentWorkspacesReducer.featureKey]: RecentWorkspacesReducer.reducer,
  [ServerStatusReducers.featureKey]: ServerStatusReducers.reducer,
};
