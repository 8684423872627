import { LoadingState } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';

export const featureKey = 'support';

export interface State {
  workspaceCopyState: LoadingState;
  workspaceDownloadState: LoadingState;
  workspaceUploadState: LoadingState;
}

export const initialState: Readonly<State> = {
  workspaceCopyState: LoadingState.INITIAL,
  workspaceDownloadState: LoadingState.INITIAL,
  workspaceUploadState: LoadingState.INITIAL,
};

export const reducer = createReducer(
  initialState,
  on(actions.copyWorkspace, state => ({
    ...state,
    workspaceCopyState: LoadingState.LOADING,
  })),
  on(actions.copyWorkspaceSuccess, state => ({
    ...state,
    workspaceCopyState: LoadingState.LOADED,
  })),
  on(actions.copyWorkspaceError, state => ({
    ...state,
    workspaceCopyState: LoadingState.ERROR,
  })),
  on(actions.downloadWorkspace, state => ({
    ...state,
    workspaceDownloadState: LoadingState.LOADING,
  })),
  on(actions.downloadWorkspaceSuccess, state => ({
    ...state,
    workspaceDownloadState: LoadingState.LOADED,
  })),
  on(actions.downloadWorkspaceError, state => ({
    ...state,
    workspaceDownloadState: LoadingState.ERROR,
  })),
  on(actions.uploadWorkspace, state => ({
    ...state,
    workspaceUploadState: LoadingState.LOADING,
  })),
  on(actions.uploadWorkspaceSuccess, state => ({
    ...state,
    workspaceUploadState: LoadingState.LOADED,
  })),
  on(actions.uploadWorkspaceError, state => ({
    ...state,
    workspaceUploadState: LoadingState.ERROR,
  }))
);
