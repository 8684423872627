import { APP_DOCUMENTS } from '@configs';
import { FileTreeNode, WorkspaceItemState } from '@models';
import { addUnique } from '@utils';

const sortNodes = (nodes: FileTreeNode[]): FileTreeNode[] => {
  const lang = navigator.languages[0] || navigator.language;
  const options = {
    numeric: true,
    ignorePunctuation: true,
  };
  return nodes.sort((a, b) => {
    const aHasUri = a.uri ? 0 : 1;
    const bHasUri = b.uri ? 0 : 1;

    // sort by uri first to ensure that leaf nodes are always at the top
    if (aHasUri !== bHasUri) {
      return aHasUri - bHasUri;
    }

    // sort by name second
    return a.name.localeCompare(b.name, lang, options);
  });
};

const mergeBranchToTree = (
  branch: FileTreeNode,
  tree: FileTreeNode[]
): void => {
  // is current node a child of the tree?
  const currentTreeNode = tree.find(t => t.id === branch.id);

  // if branch doesn't exist in tree, add it
  if (!currentTreeNode) {
    tree.push(branch);
    return;
  }

  // Update modified flag
  currentTreeNode.modified = branch.modified || currentTreeNode.modified;

  // is nextNode a leaf node, then merge children
  const nextNodes = branch.children;
  if (nextNodes?.length === 1 && !nextNodes[0]?.children) {
    const [nextNode] = nextNodes;

    // Check if currentTreeNode has children
    if (currentTreeNode.children) {
      // Merge the single child node with the existing children
      currentTreeNode.children = sortNodes(
        currentTreeNode.children.concat(nextNode)
      );
    }
    return;
  }

  if (!currentTreeNode.children) {
    return;
  }

  // merge children
  branch.children?.forEach(child =>
    mergeBranchToTree(child, currentTreeNode.children)
  );
};

const getNodeId = (path: string[], level: number): string => {
  return (
    path.slice(0, level + 1).join('-') + `${path[level + 1] ? '' : '-leaf'}`
  ).replace(/ /g, '_');
};

const getChildren = (
  item: WorkspaceItemState,
  path: string[],
  level: number
): { lockState: FileTreeNode['lockState']; children: FileTreeNode[] } => {
  const children = [createNamespaceBranch(item, path, level + 1)];
  const lockState = getAggregatedLockState(children);

  return {
    lockState,
    children,
  };
};

const getLeafNodeInfo = (item: WorkspaceItemState): { info: string } | null => {
  let info: string | undefined;

  if (item.info.readOnly) {
    return null;
  }

  if (item.category === 'rule') {
    info =
      'You may edit rule files, but you cannot run Reports in your workspace with your current package plan.';
  }
  if (item.category === 'synonym') {
    info =
      'You may edit synonym files, but you cannot run Ingest in your workspace with your current package plan.';
  }
  return info ? { info } : null;
};

const createNamespaceBranch = (
  item: WorkspaceItemState,
  path: string[],
  level = 0
): FileTreeNode => {
  return {
    id: getNodeId(path, level),
    name: path[level],
    lockState: getLockState(item),
    modified: item.modified,

    ...(path[level + 1]
      ? {
          ...getChildren(item, path, level),
        }
      : {
          uri: item.info.uri,
          lockState: getLockState(item),
          ...getLeafNodeInfo(item),
        }),
  };
};

export const getDocumentLink = (
  documentName: string,
  parent = APP_DOCUMENTS
): string => `/documents/${parent}/${documentName}`;

export const createWorkspaceItemNamespaceTree = (
  items: WorkspaceItemState[]
) => {
  const tree: FileTreeNode[] = [];
  items.forEach(item => {
    mergeBranchToTree(createNamespaceBranch(item, item.treePath), tree);
  });
  return sortNodes(tree);
};

export const getLockState = (
  item: WorkspaceItemState
): FileTreeNode['lockState'] => {
  if (item.info.readOnly) {
    return 'locked';
  }
  if (item.info.overridesParent) {
    return 'unlocked';
  }

  return 'none';
};

export const getAggregatedLockState = (
  nodes: FileTreeNode[]
): FileTreeNode['lockState'] => {
  const lockStatesSet = addUnique(nodes.map(node => node.lockState));
  return lockStatesSet.length === 1 ? lockStatesSet[0] : 'none';
};
