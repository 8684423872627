import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentStateService } from '@core/services';
import { StorageKey } from '@models';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { CollapsiblePanelAnimations } from '../../collapsible-panel.animations';

@Component({
  selector: 'app-collapsible-panel',
  animations: CollapsiblePanelAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './collapsible-panel.component.html',
  styleUrls: ['./collapsible-panel.component.scss'],
  host: {
    class: 'collapsible-panel',
  },
})
export class CollapsiblePanelComponent implements OnInit {
  constructor(private _cmpState: ComponentStateService<boolean>) {}

  @Input() key!: string;
  @Input({ transform: booleanAttribute }) initialState = true;
  @Input({ transform: booleanAttribute }) canClose = true;
  @Input() ratio = '1';
  @Input() minWidth = '0%';
  @Input() panelTitle!: string;

  @Output()
  panelToggled = new EventEmitter<boolean>();

  isOpen$!: BehaviorSubject<boolean>;
  isClosed$!: Observable<boolean>;
  isHover$ = new BehaviorSubject(false);
  initialLoad = true;

  private get _namespaceKey(): StorageKey {
    return { key: this.key, namespace: 'panel' };
  }

  ngOnInit() {
    this.isOpen$ = new BehaviorSubject(this.initialState);
    this.isClosed$ = this.isOpen$.pipe(map(v => !v));

    if (this._cmpState.has(this._namespaceKey)) {
      this.isOpen$.next(this._cmpState.get(this._namespaceKey) || false);
    } else {
      this._cmpState.set(this._namespaceKey, this.initialState);
    }
  }

  @HostBinding('@.disabled')
  get disableAnimations() {
    return !this.canClose;
  }

  @HostBinding('class.closed')
  get classClosed() {
    return !this.isOpen$.value;
  }

  @HostBinding('class.opened')
  get classOpened() {
    return this.isOpen$.value;
  }

  @HostBinding('@openClose')
  get animationState() {
    const isInit = this.initialLoad ? 'init-' : '';
    this.initialLoad = false;

    if (this.isOpen$.value) {
      return {
        value: `${isInit}opened`,
        params: { ratio: this.ratio, minWidth: this.minWidth },
      };
    }
    return {
      value: `${isInit}closed`,
    };
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.isHover$.next(true);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isHover$.next(false);
  }

  toggle(open = !this.isOpen$.value) {
    if (!this.canClose) {
      return;
    }
    this.isOpen$.next(open);
    this._cmpState.set(this._namespaceKey, open);
    this.panelToggled.emit(open);
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }
}
