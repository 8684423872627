import { Injectable } from '@angular/core';
import { TaskService } from '@core/services';
import {
  CodeCompilationResult,
  Task,
  TaskNotification,
  TaskStatus,
} from '@models';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class PojoCompilationListenerService implements TaskListeners {
  constructor(private _taskService: TaskService) {}

  action(task: TaskNotification) {
    if (task.task !== Task.PojoCompilation) {
      return;
    }

    if (task.status === 'STARTED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Started
      );
      // Update dependent tasks
      this._taskService.updateAllSubTaskStatus(
        Task.StaticJavaCompilation,
        TaskStatus.Pending
      );
    }

    if (task.status === 'FINISHED') {
      const pojoCompileResult = task.payload as CodeCompilationResult;
      const pojoCompileStatus = pojoCompileResult.success
        ? TaskStatus.Finished
        : TaskStatus.Error;
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        pojoCompileStatus
      );
    }
  }
}
