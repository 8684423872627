export enum UiPanel {
  Editor = 'Textual',
  Grid = 'Grid',
  Graphical = 'Graphical',

  Ingestion = 'Ingestion',
  JsonValidator = 'JsonValidator',
  ApiExport = 'ApiExport',
  Visualisation = 'Visualisation',
  Regulations = 'Regulations',
  RegulationReports = 'RegulationReports',
}
