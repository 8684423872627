import { ALL_MODELS } from '@configs';
import { IPageState, LoadingState } from '@models';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as actions from '../actions';

export const featureKey = 'settings';

export interface State {
  userProfileSaveState: IPageState;
  updatePasswordState: IPageState;
  downloadState: Record<string, IPageState>;
  userAdminSaveState: IPageState;
}

export const initialState: Readonly<State> = {
  userProfileSaveState: {
    state: LoadingState.INITIAL,
    errorMessage: null,
  },
  updatePasswordState: {
    state: LoadingState.INITIAL,
    errorMessage: null,
  },
  downloadState: {},
  userAdminSaveState: {
    state: LoadingState.INITIAL,
    errorMessage: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(actions.saveUserProfile, state =>
    produce(state, draft => {
      draft.userProfileSaveState.state = LoadingState.LOADING;
      draft.userProfileSaveState.errorMessage = null;
    })
  ),
  on(actions.saveUserProfileSuccess, state =>
    produce(state, draft => {
      draft.userProfileSaveState.state = LoadingState.LOADED;
    })
  ),
  on(actions.saveUserProfileError, state =>
    produce(state, draft => {
      draft.userProfileSaveState.state = LoadingState.ERROR;
      draft.userProfileSaveState.errorMessage = 'Unable to save changes';
    })
  ),

  on(actions.saveUpdatedPassword, state =>
    produce(state, draft => {
      draft.updatePasswordState.state = LoadingState.LOADING;
      draft.updatePasswordState.errorMessage = null;
    })
  ),
  on(actions.saveUpdatedPasswordSuccess, state =>
    produce(state, draft => {
      draft.updatePasswordState.state = LoadingState.LOADED;
    })
  ),
  on(actions.saveUpdatedPasswordError, state =>
    produce(state, draft => {
      draft.updatePasswordState.state = LoadingState.ERROR;
      draft.updatePasswordState.errorMessage = 'Unable to update password';
    })
  ),

  on(actions.downloadModelUsers, (state, { modelId }) =>
    produce(state, draft => {
      const key = modelId || ALL_MODELS;
      draft.downloadState[key] = draft.downloadState[key] || {
        state: LoadingState.INITIAL,
        errorMessage: null,
      };
      draft.downloadState[key].state = LoadingState.LOADING;
      draft.downloadState[key].errorMessage = null;
    })
  ),
  on(actions.downloadModelUsersSuccess, (state, { modelId }) =>
    produce(state, draft => {
      draft.downloadState[modelId].state = LoadingState.LOADED;
    })
  ),
  on(actions.downloadModelUsersError, (state, { modelId, message }) =>
    produce(state, draft => {
      draft.downloadState[modelId].state = LoadingState.ERROR;
      draft.downloadState[modelId].errorMessage = message;
    })
  ),

  on(actions.cleanupSessionState, () => initialState)
);
