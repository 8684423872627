import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';

@Component({
  standalone: true,
  selector: 'app-upgrade-plan-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <button mat-flat-button color="accent" class="width-full">
      <fa-icon icon="star" />
      <span class="ml-8">Upgrade Plan</span>
    </button>
  `,
  host: {
    class: 'upgrade-plan-button',
  },
  imports: [FontsModule, MatButtonModule],
})
export class UpgradePlanButtonComponent {}
