<button
  class="mr-16"
  *ngIf="actionIcon"
  mat-icon-button
  [matTooltip]="tooltip"
  (click)="action.emit()"
>
  <fa-icon [icon]="actionIcon" />
</button>
<div class="content" appStopPropagation>
  <ng-content />
</div>
