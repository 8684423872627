import { READ_WRITE_SERVER_KEY } from '@configs';
import { LoadingState } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ServerStatusReducer from './server-status.reducers';

const selectFeature = createFeatureSelector<ServerStatusReducer.State>(
  ServerStatusReducer.featureKey
);

export const selectServerStatus = createSelector(
  selectFeature,
  state => state.serverStatus
);

export const selectReadWriteServerStatus = createSelector(
  selectServerStatus,
  serverStatus => serverStatus[READ_WRITE_SERVER_KEY] || LoadingState.INITIAL
);

export const selectAggregatedReadOnlyServerStatus = createSelector(
  selectServerStatus,
  selectReadWriteServerStatus,
  (readonlyStatus, readWriteStatus) =>
    Object.keys(readonlyStatus).reduce<Record<string, LoadingState>>(
      (acc, key) => {
        const aggregateState =
          readWriteStatus === LoadingState.LOADED
            ? readonlyStatus[key]
            : readWriteStatus;
        return { ...acc, [key]: aggregateState };
      },
      {}
    )
);
