import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { NotificationService } from '@core/modules/snack-bar';

@Component({
  selector: 'app-root',
  template: `<router-outlet />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        height: 100%;
      }
    `,
  ],
})
export class AppComponent {
  constructor(private _notify: NotificationService) {}

  @HostListener('dragover', ['$event'])
  drag(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  drop(event: DragEvent) {
    this._notify.showWarning({ message: 'Unable to drop file here.' });
    event.preventDefault();
  }
}
