import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RosettaUser } from '@features/auth/login';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable()
export class GaService {
  constructor(private _gaService: GoogleAnalyticsService) {}

  init(user: RosettaUser) {
    if (!user.authId || !environment.ga || environment.ga.length === 0) {
      return;
    }

    this._gaService.gtag('config', environment.ga, {
      user_id: user.authId.slice(6), // remove "auth0|" from user ID
    });
  }
}
