import { uniq } from 'lodash-es';
import { KeysOfValue } from './ts-helpers';

export function addUnique<T extends string | number>(
  arr: T[],
  ...value: T[]
): T[] {
  return uniq([...arr, ...(value || [])]);
}

export function keyWordSort<
  T extends Record<K, string> | string,
  K extends KeysOfValue<T, string>,
>(arr: T[], startWith: string, key?: K): T[] {
  return arr.sort((value1: T, value2: T) => {
    const x = (key ? value1[key] : value1) as string;
    const y = (key ? value2[key] : value2) as string;
    const xStartsWith = x.startsWith(startWith);
    const yStartsWith = y.startsWith(startWith);

    if (xStartsWith && !yStartsWith) {
      return -1;
    }
    if (!xStartsWith && yStartsWith) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
}

export const convertToMap = <
  T extends { [key: string]: any },
  K extends keyof T,
>(
  list: any[],
  key: K
) =>
  list.reduce(
    (acc, col) => ({
      ...acc,
      [col[key]]: col,
    }),
    {}
  );

const lang = navigator.languages[0] || navigator.language;
const options = {
  numeric: true,
  ignorePunctuation: true,
};

export const naturalCompare = (a: string, b: string) => {
  return a.localeCompare(b, lang, options);
};

export const naturalSort = <
  T extends Record<K, unknown> | string,
  K extends KeysOfValue<T, string>,
>(
  list: T[],
  key?: K,
  startsWith?: string
): T[] => {
  const lang = navigator.languages[0] || navigator.language;
  const options = {
    numeric: true,
    ignorePunctuation: true,
  };
  return [...(list || [])].sort((a, b) => {
    const valueA = (key ? a[key] : a).toString();
    const valueB = (key ? b[key] : b).toString();

    if (startsWith) {
      if (valueA.startsWith(startsWith)) {
        return -1;
      } else if (valueB.startsWith(startsWith)) {
        return 1;
      }
    }
    return valueA.localeCompare(valueB, lang, options);
  });
};

export const sortByKeywordFunc = (
  a: string,
  b: string,
  keyword: string,
  placeLast = false
): number => {
  if (a === keyword && b !== keyword) {
    return placeLast ? 1 : -1;
  }
  if (a !== keyword && b === keyword) {
    return placeLast ? -1 : 1;
  }
  return a.localeCompare(b);
};

export const multipleExist = <T extends any[]>(arr: T, values?: T): boolean =>
  (values || []).some(value => arr.includes(value));

export const ensureArray = <T>(value: T | T[]): T[] =>
  Array.isArray(value) ? value : [value];

export const arraysContainSameValues = (arr1: any[], arr2: any[]): boolean => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  const elementCountMap: Map<any, number> = new Map();

  // Count occurrences of elements in arr1
  for (const element of arr1) {
    elementCountMap.set(element, (elementCountMap.get(element) || 0) + 1);
  }

  // Decrease count for elements in arr2
  for (const element of arr2) {
    const elementCount = elementCountMap.get(element);
    if (!elementCount) {
      return false; // If element not found in arr1
    }
    if (elementCount === 1) {
      elementCountMap.delete(element);
    } else {
      elementCountMap.set(element, elementCount - 1);
    }
  }

  return elementCountMap.size === 0; // If both arrays have the same elements
};

export const removeTrailingNullsAndUndefined = <T>(arr: T[]): T[] => {
  let i = arr.length - 1;

  // Iterate from the end of the array and remove null or undefined values
  while (i >= 0 && (arr[i] === null || arr[i] === undefined)) {
    i--;
  }

  // Return the array sliced up to the point where non-null/undefined values end
  return arr.slice(0, i + 1);
};
