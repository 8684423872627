<!-- Template -->
<ng-template #actionBtnTmp let-context>
  <button
    *ngIf="actions.includes(context.action) && context.condition"
    mat-stroked-button
    [title]="context.title"
    (click)="onClick(context.action)"
  >
    <fa-icon [icon]="context.icon" />
    <span>{{ context.label }}</span>
  </button>
</ng-template>

<!-- Main -->
<div @slideUp @slideDown *ngIf="selectedRowLength > 0" class="wrapper">
  <ng-container
    [ngTemplateOutlet]="actionBtnTmp"
    [ngTemplateOutletContext]="{
      $implicit: {
        action: sampleRowAction.Accept,
        title: 'Accept changes',
        label: 'Accept',
        icon: 'check',
        condition: true,
      },
    }"
  />
  <ng-container
    [ngTemplateOutlet]="actionBtnTmp"
    [ngTemplateOutletContext]="{
      $implicit: {
        action: sampleRowAction.Revert,
        title: 'Discard changes',
        label: 'Discard',
        icon: 'arrow-rotate-left',
        condition: true,
      },
    }"
  />
  <small class="selected-text ml-8">{{ selectedRowLength }} Selected</small>
</div>
