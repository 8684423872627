import { HttpErrorResponse } from '@angular/common/http';
import { getServerErrorMessage } from '@features/auth/login/services/api-error-handler';
import { isServerError } from '@models';
import { OperatorFunction, catchError, of } from 'rxjs';
import { PipelineRunInfo } from '../models';

export const pipelineCatchError = (): OperatorFunction<
  any,
  PipelineRunInfo
> => {
  return catchError(error => {
    const pipelineRunUpdate: PipelineRunInfo = {
      result: null,
      errorMessage: 'An unexpected error occurred.',
    };

    if (isServerError(error)) {
      pipelineRunUpdate.errorMessage =
        error.userMessage || 'Server error occurred.';
    } else if (error instanceof HttpErrorResponse) {
      pipelineRunUpdate.errorMessage = getServerErrorMessage(error);
    } else if (error instanceof Error) {
      pipelineRunUpdate.errorMessage = 'An error occurred.';
    } else if (typeof error === 'string') {
      pipelineRunUpdate.errorMessage = error;
    } else if (error && typeof error === 'object') {
      pipelineRunUpdate.errorMessage = JSON.stringify(error);
    }

    return of(pipelineRunUpdate);
  });
};
