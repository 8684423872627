<ng-template #noProjects>
  <small class="no-project-text mb-0 color-50">Click to assign projects</small>
</ng-template>

<div [appRosettaOverlayTarget]="overlay">
  <app-project-cell
    *ngIf="modelIds.length > 0; else noProjects"
    [modelIds]="modelIds"
    size="xs"
  />
  <fa-icon icon="chevron-down" />
</div>

<app-rosetta-overlay #overlay scrollStrategy="block">
  <app-rosetta-menu maxHeight="300">
    <mat-selection-list
      dense
      [ngModel]="modelIds"
      (ngModelChange)="setModelIds($event)"
      [compareWith]="compareWithFunc"
    >
      <mat-list-option
        *ngFor="let item of projects$ | async | keyvalue"
        [value]="item.value"
      >
        <app-project-row [model]="item.value" showAccepted="false" />
      </mat-list-option>
    </mat-selection-list>
  </app-rosetta-menu>
</app-rosetta-overlay>
