import { Injectable } from '@angular/core';
import { RootTypeService } from '@core/services/root-type.service';
import { Task, TaskNotification } from '@models';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class RootTypeCacheListenerService implements TaskListeners {
  constructor(private _rootTypeService: RootTypeService) {}

  action(task: TaskNotification): void {
    if (task.task !== Task.RootTypeCache) {
      return;
    }

    const roots = task.payload as string[];
    this._rootTypeService.onRootTypesChanged(roots);
  }
}
