<h4>Recently Viewed</h4>
<ng-container
  *ngIf="recentWorkspaces$ | async as recentWorkspaces; else emptyList"
>
  <mat-table [dataSource]="recentWorkspaces">
    <ng-container matColumnDef="logo">
      <mat-cell *matCellDef="let element">
        <app-project-logo
          [data]="element.modelInfo.modelId | model | async"
          size="sm"
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-cell *matCellDef="let element">
        <app-project-type [data]="element.workspaceType" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <mat-cell *matCellDef="let element">
        <strong>{{ element.displayName }}</strong>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="version">
      <mat-cell *matCellDef="let element">
        <app-upgrade-icon [modelInfo]="element.modelInfo" />
      </mat-cell>
    </ng-container>

    <mat-row
      (click)="open(row)"
      *matRowDef="let row; columns: displayedColumns"
    />
  </mat-table>
</ng-container>

<ng-template #emptyList>
  <p class="empty-list theme-color-hint">Recent workspace list is empty</p>
</ng-template>
