import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { RosettaConfig } from '@configs';
import { Store } from '@ngrx/store';
import { AppActions, AuthActions } from '@store/.';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    private _store: Store,
    private _ngZone: NgZone
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        // If error status is different than 401 we want to skip
        // So we check that and throw the error if it's the case
        if (error.status === 401) {
          this._ngZone.run(() => {
            this._store.dispatch(AuthActions.cleanupSessionState());
            this._store.dispatch(
              AppActions.showBasicErrorMsg({
                message: RosettaConfig.text.sessionExpired,
              })
            );
          });
        }

        return throwError(() => error);
      })
    );
  }
}
