<header mat-dialog-title>
  <h2>Change Password</h2>
  <div class="spacer"></div>
  <button
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
    [disabled]="isSaving$ | async"
  >
    <fa-icon icon="times" />
  </button>
</header>

<form [formGroup]="form">
  <div mat-dialog-content>
    <div class="field-row width-full">
      <mat-form-field class="mb-16">
        <input
          matInput
          type="password"
          placeholder="Current Password"
          formControlName="oldPassword"
          cdkFocusInitial
          required
        />
      </mat-form-field>
    </div>

    <div class="field-row width-full">
      <mat-form-field class="mb-16">
        <input
          matInput
          type="password"
          placeholder="New Password"
          formControlName="newPassword"
          required
        />
        <mat-error *ngIf="form.controls['newPassword'].hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.controls['newPassword'].hasError('minlength')">
          Password must be at least 8 characters
        </mat-error>
        <mat-error
          *ngIf="
            !form.controls['newPassword'].hasError('minlength') &&
            form.controls['newPassword'].hasError('pattern')
          "
        >
          Password is not strong enough, it should include a special character,
          Lowercase & Uppercase letters, Numbers
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row width-full">
      <mat-form-field>
        <input
          matInput
          type="password"
          placeholder="Retype New Password"
          formControlName="confirmPassword"
          required
        />
        <mat-error
          *ngIf="form.controls['confirmPassword'].hasError('mustMatch')"
        >
          Passwords must match
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button [disabled]="isSaving$ | async" mat-dialog-close>
      Cancel
    </button>
    <button
      *appShowSpinner="(isSaving$ | async) === false"
      mat-stroked-button
      [disabled]="form.invalid"
      (click)="onSave()"
    >
      <fa-icon icon="circle-check" />
      Save
    </button>
  </div>
</form>
