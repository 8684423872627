import { map, OperatorFunction } from 'rxjs';
import { TestPackGrid } from '../test-pack-grid.model';
import { TransformDTO } from '../transform-dto.model';

export const fromTestPackGridDTO = (): OperatorFunction<
  TransformDTO.TestPackGrid,
  TestPackGrid
> => {
  return map(testPackGrid => ({
    pipelineDef: {
      ...testPackGrid.pipelineDef,
      id: testPackGrid.pipelineDef.id.id,
    },
    testPackDef: {
      ...testPackGrid.testPackDef,
      id: testPackGrid.testPackDef.id.id,
    },
    rows: testPackGrid.rows.map(row => ({
      ...row,
      sampleId: row.sampleId.id,
    })),
  }));
};
