import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RosettaTourTemplateComponent } from './rosetta-tour-template.component';
import { RosettaTourService } from './rosetta-tour.service';
import { MatButtonModule } from '@angular/material/button';

/**
 * Add the Rosetta tour module to each section/module you
 * wish to have a new independent tour collection.
 *
 * You will need to add a new <tour-template> to the root component of that module.
 */
@NgModule({
  declarations: [RosettaTourTemplateComponent],
  imports: [CommonModule, FontsModule, TourMatMenuModule, MatButtonModule],
  providers: [RosettaTourService],
  exports: [TourMatMenuModule, RosettaTourTemplateComponent],
})
export class RosettaTourModule {}
