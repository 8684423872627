import { LoadingState } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as NotificationReducers from './notification.reducer';

const selectFeature = createFeatureSelector<NotificationReducers.State>(
  NotificationReducers.featureKey
);

const selectNotifications = createSelector(
  selectFeature,
  state => state.notifications
);

const selectHasNotificationError = createSelector(
  selectFeature,
  state => state.loadingState === LoadingState.ERROR
);

const selectHasLoaded = createSelector(
  selectFeature,
  state => state.loadingState !== LoadingState.LOADING
);

export const NotificationSelectors = {
  selectFeature,
  selectNotifications,
  selectHasNotificationError,
  selectHasLoaded,
};
