import {
  UntypedFormGroup,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';

// custom validator to check that two fields match
export const mustMatch = (controlName: string, matchingControlName: string) => {
  return (formGroup: UntypedFormGroup): ValidatorFn | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (
      !matchingControl ||
      (matchingControl?.errors && !matchingControl.errors['mustMatch'])
    ) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }

    return null;
  };
};

export const mustContain = <T>(
  list: T[],
  cb: (item: T, value: string) => boolean
) => {
  return (control: UntypedFormControl) => {
    const errorKey = 'mustContain';

    if (control.errors && !control.errors[errorKey]) {
      // return if another validator has already found an error on the control
      return undefined;
    }

    if (list.some(item => cb(item, control.value))) {
      return null;
    }
    return { [errorKey]: true };
  };
};
