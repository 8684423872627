<ng-container *ngrxLet="newReleaseNotes$ as newReleaseNotes">
  <button mat-icon-button [matMenuTriggerFor]="helpMenu">
    <span class="position-relative">
      <fa-icon [icon]="['far', 'question-circle']" size="lg" />
      <app-text-badge
        size="sm"
        class="text-badge-icon theme-bg-color"
        *ngIf="newReleaseNotes"
      />
    </span>
  </button>

  <mat-menu #helpMenu="matMenu" xPosition="before" class="help-menu">
    <ng-template matMenuContent>
      <a mat-menu-item [href]="externalLink.DOCUMENTATION" target="_blank">
        <fa-icon icon="book-reader" [fixedWidth]="true" />
        <span>Documentation</span>
      </a>

      <button
        mat-menu-item
        (click)="openReleaseNotes()"
        class="position-relative"
      >
        <app-text-badge
          class="text-badge-new theme-bg-color"
          size="sm"
          *ngIf="newReleaseNotes"
          ><small>New</small></app-text-badge
        >
        <fa-icon icon="list" [fixedWidth]="true" />
        <span>Release Notes</span>
      </button>

      <button mat-menu-item (click)="openIssueTracker()">
        <fa-icon [icon]="['far', 'comments']" [fixedWidth]="true" />
        <span>Issues Tracker</span>
      </button>

      <a mat-menu-item [href]="externalLink.STATUS" target="_blank">
        <fa-icon icon="broadcast-tower" [fixedWidth]="true" />
        <span>Status Page</span>
      </a>
    </ng-template>
  </mat-menu>
</ng-container>
