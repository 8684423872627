import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { DiagnosticPanelGroupDefDirective } from '@shared/modules/diagnostic-panel/diagnostic-panel-def.directive';
import { DiagnosticPanelGroupTemplatePipe } from '@shared/modules/diagnostic-panel/diagnostic-panel-template.pipe';
import { DiagnosticPanelComponent } from '@shared/modules/diagnostic-panel/diagnostic-panel/diagnostic-panel.component';
import { DiagnosticComponent } from '@shared/modules/diagnostic-panel/diagnostic/diagnostic.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ChipLabelComponent } from '../chip-label.component';

@NgModule({
  declarations: [
    DiagnosticPanelGroupDefDirective,
    DiagnosticPanelComponent,
    DiagnosticComponent,
    DiagnosticPanelGroupTemplatePipe,
  ],
  exports: [DiagnosticComponent],
  imports: [
    CommonModule,
    FontsModule,
    MatButtonModule,
    MatRippleModule,
    NgScrollbarModule,
    ChipLabelComponent,
  ],
})
export class DiagnosticPanelModule {}
