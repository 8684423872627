import { map, OperatorFunction } from 'rxjs';
import { SampleDef } from '../sample-def.model';
import { TransformDTO } from '../transform-dto.model';

export const fromSampleDefDTO = (): OperatorFunction<
  TransformDTO.SampleDef,
  SampleDef
> => {
  return map(sampleDef => ({
    ...sampleDef,
    id: sampleDef.id.id,
  }));
};
