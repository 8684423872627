import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { FilteredListComponent } from '@shared/modules/filtered-list/filtered-list.component';
import { StopPropagationModule } from '@shared/modules/stop-propagation/stop-propagation.module';

@Component({
  standalone: true,
  imports: [FilteredListComponent, MatCheckboxModule, StopPropagationModule],
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent implements IFilterAngularComp {
  list!: string[];

  private _params!: IFilterParams;
  private _selected = new Set<string>();
  private _field!: string;

  agInit(params: IFilterParams & { list: string[]; field?: string }): void {
    this._params = params;
    this.list = params?.list || [];
    this._field = params?.field || params.column.getColId();
  }

  doesFilterPass({ data }: IDoesFilterPassParams): boolean {
    const value = this._field
      .split('.')
      .reduce((obj, key) => obj?.[key], data)
      ?.toLowerCase();
    return !this._selected.has(value);
  }

  isFilterActive(): boolean {
    return this._selected.size > 0;
  }

  getModel() {}

  setModel() {}

  toggle(item: string) {
    item = item.toLowerCase();
    if (this._selected.has(item)) {
      this._selected.delete(item);
    } else {
      this._selected.add(item);
    }
    this._updateFilter();
  }

  isChecked(item: string) {
    return !this._selected.has(item.toLowerCase());
  }

  masterToggle(event: MatCheckboxChange) {
    event.checked ? this._selected.clear() : this._selectAll();
    this._updateFilter();
  }

  isAllSelected() {
    return this._selected.size === 0;
  }

  isAnySelected() {
    return this._selected.size !== 0 && this._selected.size < this.list.length;
  }

  private _updateFilter() {
    this._params.filterChangedCallback();
  }

  private _selectAll() {
    this.list.forEach(name => this._selected.add(name.toLowerCase()));
  }
}
