import { WorkspaceGroup } from '@models';
import { createAction, props } from '@ngrx/store';

export const loadProjects = createAction('[Workspaces] Load Projects');

export const loadProjectsSuccess = createAction(
  '[Workspaces] Load Projects Success',
  props<{ projects: WorkspaceGroup[] }>()
);

export const loadProjectsFailure = createAction(
  '[Workspaces] Load Projects Failure',
  props<{ error: string }>()
);
