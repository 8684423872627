import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <mat-spinner
      mode="indeterminate"
      color="primary"
      [diameter]="diameter"
      [strokeWidth]="3"
    />
  `,
  styles: [
    `
      // We can't use :host here as there is no view encapsulation
      .loading-spinner {
        display: inline-block;
        vertical-align: middle;

        .mat-mdc-progress-spinner {
          margin: auto;
        }

        &-centre {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    `,
  ],
  host: {
    class: 'loading-spinner',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '[class.loading-spinner-centre]': 'flexCentre',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '[class]': 'classes',
  },
})
export class LoadingSpinnerComponent {
  @Input() diameter = 36;
  @Input({ transform: booleanAttribute }) flexCentre = false;
  @Input() classes: string;
}
