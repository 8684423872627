/*
Use numeric values so that we can easily check:
- Ranges
- Passed a certain state
*/
export enum StatusStateName {
  Error = -1,
  Empty = 0,
  Started = 1,
  Finished = 2,
  Stale = 3,
}
