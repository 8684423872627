import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _destroyRef: DestroyRef
  ) {}

  title!: string;
  subtitle!: string;

  ngOnInit() {
    this._router.events
      .pipe(
        // Start with NavigationEnd event to emit initial value on page load
        startWith(new NavigationEnd(0, '', '')),
        // Filter out events that aren't NavigationEnd
        filter(event => event instanceof NavigationEnd),
        // Take until the component is destroyed
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        const routeData = this._route.snapshot.firstChild?.data;
        this.title = routeData['settings'].title;
        this.subtitle = routeData['settings'].subtitle;
      });
  }
}
