import { RouterStateUrl } from '@core/modules/store/custom-route-serializer';
import {
  DEFAULT_ROUTER_FEATURENAME,
  RouterReducerState,
} from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { collectUrlTreeSegments } from '@utils';

export const selectRouter = createFeatureSelector<
  RouterReducerState<RouterStateUrl>
>(DEFAULT_ROUTER_FEATURENAME);

export const selectRouterUrl = createSelector(selectRouter, params => {
  return params?.state.url;
});

export const selectRouterPathSegments = createSelector(selectRouterUrl, url =>
  collectUrlTreeSegments(url)
);

export const selectRouterParams = createSelector(selectRouter, ({ state }) => {
  return state.params;
});
