import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WorkspaceMenuItem } from '@models';
import { Store } from '@ngrx/store';
import { selectRecentWorkspaces } from '@store/recent-workspaces/recent-workspaces.selectors';
import * as RouterActions from '@store/router/router.actions';

@Component({
  selector: 'app-workspace-recent-list',
  templateUrl: './workspace-recent-list.component.html',
  styleUrls: ['./workspace-recent-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceRecentListComponent {
  readonly displayedColumns = ['logo', 'type', 'displayName', 'version'];

  recentWorkspaces$ = this._store.select(selectRecentWorkspaces);

  constructor(private _store: Store) {}

  open(item: WorkspaceMenuItem) {
    this._store.dispatch(RouterActions.gotoWorkspace({ workspaceId: item.id }));
  }
}
