<mat-form-field>
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      placeholder="Start date"
      [value]="packagePlanDetails.startDate"
      (dateChange)="dateRangeChange('change', 'startDate', $event)"
      (dateInput)="dateRangeChange('input', 'startDate', $event)"
      (blur)="blurInput()"
    />
    <input
      matEndDate
      placeholder="End date"
      [value]="packagePlanDetails.endDate"
      (dateChange)="dateRangeChange('change', 'endDate', $event)"
      (dateInput)="dateRangeChange('input', 'endDate', $event)"
      (blur)="blurInput()"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconPrefix [for]="picker" />
  <button matIconSuffix mat-icon-button (click)="clearPackagePlan()">
    <fa-icon icon="circle-xmark" />
  </button>
  <mat-date-range-picker #picker />
</mat-form-field>
