import { GetQuickFilterTextParams } from '@ag-grid-community/core';

type QuickFilterObjects = (params: GetQuickFilterTextParams) => string;

export const COLUMN_TYPE_IGNORE = 'ignoreColumn';

export const getQuickFilterObjects = (
  keys: string[],
  path?: string
): QuickFilterObjects => {
  return params => {
    const value = path
      ? path.split('.').reduce<any>((obj, key) => obj[key], params)
      : params.value;

    if (params.colDef.hide) {
      return '';
    }

    return keys
      .map(key => value[key])
      .join(' ')
      .toLowerCase();
  };
};
