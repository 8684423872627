import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  pairwise,
  scan,
  shareReplay,
  startWith,
  Subject,
} from 'rxjs';

interface SearchItem {
  key: string;
  value: string;
}

interface SearchData {
  list: SearchItem[];
  count: number;
  index: number;
}

const defaultSearchData: SearchData = { list: [], count: 0, index: 0 };

@Injectable()
export class CodeViewSearchService {
  private _searchList: SearchItem[] = [];
  private _searchTermSubject = new Subject<string>();
  private _positionSubject = new BehaviorSubject<number>(0);

  private _sourceStream$ = combineLatest([
    this._searchTermSubject.pipe(debounceTime(500)),
    this._positionSubject,
  ]).pipe(
    distinctUntilChanged(),
    startWith([]),
    pairwise(),
    scan((data, [prev, next]) => {
      const [prevSearchTerm] = prev;
      const [searchTerm, position] = next;

      if (searchTerm.trim().length < 1) {
        return defaultSearchData;
      }

      let { list, count, index } = data;

      if (prevSearchTerm === searchTerm) {
        const newIndex = index + position;
        index = newIndex > count - 1 ? 0 : newIndex < 0 ? count - 1 : newIndex;
      } else {
        const regex = new RegExp(searchTerm, 'i');
        list = this._searchList.filter(({ value }) => regex.test(value));
        count = list.length;
        index = 0;
      }

      return {
        list,
        count,
        index,
      };
    }, defaultSearchData),
    shareReplay<SearchData>(1)
  );

  add(value: string, key: string) {
    this._searchList.push({ value, key });
  }

  remove(key: string) {
    const regex = new RegExp(key, 'i');
    const index = this._searchList.findIndex(({ key: itemKey }) =>
      regex.test(itemKey)
    );
    this._searchList.splice(index, 1);
  }

  search(value: string) {
    this._searchTermSubject.next(value);
  }

  next() {
    this._positionSubject.next(1);
  }

  prev() {
    this._positionSubject.next(-1);
  }

  current() {
    return this._sourceStream$.pipe(map(s => s.list[s.index]));
  }

  count() {
    return this._sourceStream$.pipe(map(s => s.count));
  }

  position() {
    return this._sourceStream$.pipe(map(s => s.index + 1));
  }
}
