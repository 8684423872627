import { WorkspaceId, WorkspaceInfo } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createAction, props } from '@ngrx/store';

export const addRecentWorkspace = createAction(
  '[Recent Workspaces] Add Recent Workspace',
  props<{ add: WorkspaceInfo | null }>()
);

export const removeRecentWorkspace = createAction(
  '[Recent Workspaces] Remove Recent Workspace',
  props<{ remove: WorkspaceId | null }>()
);

export const checkRecentWorkspaces = createAction(
  '[Recent Workspaces] Check Recent Workspaces',
  props<{ modelIds: ModelInstanceId[] }>()
);
