import { NgModule } from '@angular/core';
import { NotificationWebsocketService } from '@core/modules/notifications/notification-websocket.service';
import { NotificationReducers } from '@core/modules/notifications/store';
import { NotificationWebsocketEffects } from '@core/modules/notifications/store/notification-websocket.effects';
import { NotificationEffects } from '@core/modules/notifications/store/notification.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  providers: [NotificationWebsocketService],
  imports: [
    StoreModule.forFeature(
      NotificationReducers.featureKey,
      NotificationReducers.reducer
    ),
    EffectsModule.forFeature([
      NotificationEffects,
      NotificationWebsocketEffects,
    ]),
  ],
})
export class NotificationModule {}
