import { NotificationMessagePayload, LoadingState } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as websocketActions from './notification-websocket.actions';
import * as actions from './notification.actions';

export const featureKey = 'notification';

export interface State {
  loadingState: LoadingState;
  error: string | null;
  notifications: NotificationMessagePayload[];
}

export const initialState: State = {
  loadingState: LoadingState.INITIAL,
  error: null,
  notifications: [],
};

export const reducer = createReducer(
  initialState,

  on(websocketActions.notificationError, state => ({
    ...state,
    loadingState: LoadingState.ERROR,
    error: 'Unable to retrieve user notifications, please try again later',
  })),

  on(actions.loadNotifications, state => ({
    ...state,
    loadingState: LoadingState.LOADING,
  })),
  on(actions.loadNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    loadingState: LoadingState.LOADED,
    notifications: state.notifications.concat(notifications),
  })),
  on(websocketActions.notificationBell, (state, { payload: notification }) => ({
    ...state,
    loadingState: LoadingState.LOADED,
    notifications: [...state.notifications, notification],
  })),
  on(
    actions.loadNotificationsFailure,
    actions.dismissNotificationFailure,
    (state, action) => ({
      ...state,
      loadingState: LoadingState.ERROR,
      error: action.error,
    })
  ),

  on(actions.dismissNotificationSuccess, (state, action) => ({
    ...state,
    notifications: state.notifications.filter(({ id }) => id !== action.id),
  })),

  on(actions.logout, () => initialState)
);
