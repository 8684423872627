import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { BaseWorkspaceStateService } from '@core/services';
import { Task } from '@models';
import { TransformType } from '@shared/modules/transform/models/transform-config.model';
import { WorkspaceUrlPrefixOperator } from '@utils';
import {
  BehaviorSubject,
  Observable,
  catchError,
  first,
  map,
  merge,
  of,
  reduce,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';

type SupportFeatures = Map<string, boolean>;

@Injectable()
export class SupportedFeatureService extends BaseWorkspaceStateService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {
    super();
  }

  override onWorkspaceSwitch(): void {
    this._supportedFeaturesCache.next(null);
  }

  private _supportFeatures = [
    // This method has been copied from the RegulationApiService so that we can find out if a model
    // supports the new regulation panel before opening it. It can be removed when this has been fully rolled out.
    {
      featureTabName: 'regulation',
      resourcePath: this._config.resourcePaths.bsp,
      endpoint: '/regulation/body-corpus-list',
    },
    {
      featureTabName: 'ingest',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Ingest}/pipelines`,
    },
    {
      featureTabName: 'reports',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Report}/pipelines`,
    },
    {
      featureTabName: 'projection',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Projection}/pipelines`,
    },

    {
      featureTabName: 'enrich',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Enrich}/pipelines`,
    },
  ] as const;

  private _supportedFeaturesCache = new BehaviorSubject<SupportFeatures | null>(
    null
  );

  private _fetchSupportedFeatures: Observable<SupportFeatures> =
    this.workspaceSwitchAndReady(Task.ExecutionEngineInitialisation).pipe(
      switchMap(() =>
        merge(
          ...this._supportFeatures.map(
            ({ featureTabName, resourcePath, endpoint }) =>
              this._checkFeatureSupported(
                featureTabName,
                resourcePath,
                endpoint
              )
          )
        ).pipe(
          take(this._supportFeatures.length),
          reduce((acc, curr) => {
            acc.set(curr.featureTabName, curr.isSupported);
            return acc;
          }, new Map<string, boolean>()),
          tap(supportedFeatures => {
            this._supportedFeaturesCache.next(supportedFeatures);
          })
        )
      ),
      // Ensure a single observable is shared across multiple subscribers
      share()
    );

  checkSupportFor(featureTabName: string): Observable<boolean> {
    return this._supportedFeaturesCache.pipe(
      first(),
      switchMap(cache => (cache ? of(cache) : this._fetchSupportedFeatures)),
      map(supportedFeatures => supportedFeatures.get(featureTabName))
    );
  }

  private _checkFeatureSupported(
    featureTabName: string,
    resourcePath: string,
    endpoint: string
  ): Observable<{ featureTabName: string; isSupported: boolean }> {
    return this._store.pipe(
      WorkspaceUrlPrefixOperator(resourcePath),
      switchMap(url => this._http.get<any>(`${url}${endpoint}`)),
      map(() => ({ featureTabName, isSupported: true })),
      catchError(() => of({ featureTabName, isSupported: false }))
    );
  }
}
