import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { CodeViewComponent } from '@shared/modules/code-view/code-view.component';
import { Diagnostic } from '@shared/modules/diagnostic-panel/diagnostic.model';
import { map, tap } from 'rxjs';

import {
  DiagnosticsSummaryData,
  toDiagnosticChartData,
} from '../../components/diagnostic-chart/diagnostic.models';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '../../models/transform-config.model';
import { TransformDTO } from './../../models/transform-dto.model';
import { TransformDetailsService } from './transform-details.service';

@Component({
  selector: 'app-transform-details',
  templateUrl: './transform-details.component.html',
  styleUrls: ['./transform-details.component.scss'],
  host: { class: 'transform-details' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  // This is here so that the service gets destroyed when the component is closed
  providers: [TransformDetailsService],
})
export class TransformDetailsComponent {
  constructor(
    private _service: TransformDetailsService,
    private _navigate: RosettaNavigationService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  readonly namespace = this._transformConfig.featureTabName;
  readonly sampleInput = this._transformConfig.sampleInput;
  readonly sampleOutput = this._transformConfig.sampleOutput;

  @ViewChild('inputEditor')
  readonly inputCodeView: CodeViewComponent;

  @ViewChild('outputEditor')
  readonly outputCodeView: CodeViewComponent;

  vm$ = this._service.viewModel$;
  status$ = this._service.status$;
  codeViewInput$ = this._service.codeViewInput$;
  codeViewOutput$ = this._service.codeViewOutput$;
  diagnosticData$ = this._service.diagnosticData$.pipe(
    tap(diagnostic => this._updateDiagnosticCharts(diagnostic))
  );
  inputSerialisation$ = this.vm$.pipe(
    map(vm =>
      this._getLanguage(vm.selection.pipelineDef.inputSerialisationFormat)
    )
  );
  outputSerialisation$ = this.vm$.pipe(
    map(vm =>
      this._getLanguage(vm.selection.pipelineDef.outputSerialisationFormat)
    )
  );

  diagnosticsSummary: DiagnosticsSummaryData;

  goBack(): void {
    this._navigate.updateBottomPanel([this._transformConfig.url]);
  }

  filterDiagnostics(searchTerm: string): void {
    this._service.filterDiagnostics(searchTerm);
  }

  rerun(): void {
    this._service.rerunCodeViews();
  }

  private _getLanguage(
    format: TransformDTO.SerialisationFormat
  ): 'json' | 'xml' {
    if (format === TransformDTO.SerialisationFormat.XML) {
      return 'xml';
    }
    return 'json';
  }

  private _updateDiagnosticCharts(diagnostic: Diagnostic): void {
    this.diagnosticsSummary = new Map();

    const mapping = diagnostic?.get('mapping');
    if (mapping?.length > 0) {
      this.diagnosticsSummary.set('mapping', toDiagnosticChartData(mapping));
    }

    const validation = diagnostic?.get('validation');
    if (validation?.length > 0) {
      this.diagnosticsSummary.set(
        'validation',
        toDiagnosticChartData(validation)
      );
    }
  }
}
