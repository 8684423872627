import { Inject, Injectable } from '@angular/core';
import {
  DEFAULT_API_TIMEOUT,
  HEART_BEAT,
  IRosettaConfig,
  MonacoThemeConfig,
  MonacoThemes,
  OVERRIDE_NAMESPACE_SYNTAX,
  ROSETTA_CONFIG,
  StorageKey,
} from '@configs';
import { rewriteProjectWorkspaceUrlPath } from '@core/interceptors/helper-interceptor';
import { LocalStorageService, WorkspaceApiService } from '@core/services';
import { LanguageServerMiddlewareService } from '@core/services/language-server-middleware.service';
import { WebSocketService } from '@core/services/websocket.service';
import {
  NavigateDirection,
  NavigateObject,
} from '@features/workspace/models/rosetta-core.model';
import {
  DocumentSymbol,
  SymbolIdentifier,
  Workspace,
  WorkspaceInfo,
  WorkspaceItem,
} from '@models';
import { Store } from '@ngrx/store';
import { NodeNameType } from '@shared/modules/code-view/models/code-view.model';
import { AppActions } from '@store/.';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { LANGUAGE_ROSETTA } from '@workspace-design/textual/models/editor.const';
import {
  ConnectionState,
  ServiceOverridesFunctions,
} from '@workspace-design/textual/models/rosetta-editor.model';
import {
  MONACO_LOADER,
  MonacoLoader,
} from '@workspace-design/textual/services/monaco-loader';
import {
  CloseAction,
  DocumentSymbolRequest,
  ErrorAction,
  MessageTransports,
  MonacoLanguageClient,
  MonacoServices,
  State,
  StateChangeEvent,
} from 'monaco-languageclient';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  delay,
  first,
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import { Uri } from 'vscode';
import {
  WebSocketMessageReader,
  WebSocketMessageWriter,
  toSocket,
} from 'vscode-ws-jsonrpc';
import { rosettaServicesInstall } from './language-server-services-install.service';

@Injectable()
export class LanguageServerService {
  constructor(
    private _webSocketService: WebSocketService,
    private _workspaceApiService: WorkspaceApiService,
    private _store: Store,
    private _storage: LocalStorageService,
    private _middlewareService: LanguageServerMiddlewareService,
    @Inject(MONACO_LOADER) private _loader: MonacoLoader,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _webSocket: WebSocket | null = null;
  private _closingSocket = false;
  private _socketState$ = new BehaviorSubject<number>(WebSocket.CLOSED);

  languageClient: MonacoLanguageClient | null = null;
  diffEditor?: monaco.editor.IStandaloneDiffEditor;
  diffNav?: monaco.editor.IDiffNavigator;

  editorSubject$ =
    new ReplaySubject<monaco.editor.IStandaloneCodeEditor | null>(1);

  setTheme(config: Partial<MonacoThemeConfig>): void {
    const themeConfig = this._storage.getItem<MonacoThemeConfig>(
      StorageKey.ThemeConfig
    );

    const updatedThemeConfig = {
      isDark: false,
      themeName: MonacoThemes.ROSETTA,
      ...themeConfig,
      ...config,
    };
    const { isDark, themeName } = updatedThemeConfig;
    const name = `${themeName}-${isDark ? 'dark' : 'light'}`;
    this._storage.setItem(StorageKey.ThemeConfig, updatedThemeConfig);

    monaco.editor.setTheme(name);

    this.updateEditorOptions({
      extraEditorClassName: name,
    });
  }

  async loadMonaco(): Promise<void> {
    await this._loader.loadMonaco();
    this._store.dispatch(AppActions.monacoReady());
  }

  overrides(): void {
    this._loader.runServiceOverridesInit(this._getServiceOverrides());
  }

  createModels(items: WorkspaceItem[]): void {
    items.forEach((item: WorkspaceItem) => {
      monaco.editor.createModel(
        item.contents,
        LANGUAGE_ROSETTA,
        monaco.Uri.parse(item.info.uri)
      );
    });
  }

  connectToServer(workspace: Workspace): void {
    if (this._webSocket) {
      this._store.dispatch(
        WorkspaceActions.websocketError(
          this._config.text.MultipleWebsocketConnections
        )
      );
      return;
    }

    this._installClientServices();

    const webSocket = this._webSocketService.create(
      this._getWebSocketUrl(workspace.info)
    );

    webSocket.addEventListener('close', () => {
      this._store.dispatch(WorkspaceActions.websocketClose());
      return this._socketState$.next(webSocket.readyState);
    });
    webSocket.addEventListener('open', () =>
      this._socketState$.next(webSocket.readyState)
    );
    webSocket.addEventListener('error', () => {
      this._store.dispatch(WorkspaceActions.websocketError());
      return this._socketState$.next(webSocket.readyState);
    });

    webSocket.onopen = () => {
      const socket = toSocket(webSocket);
      const reader = new WebSocketMessageReader(socket);
      const writer = new WebSocketMessageWriter(socket);
      this.languageClient = this._createLanguageClient(workspace, {
        reader,
        writer,
      });
      this.languageClient.start();
      this._startLanguageClientListeners(this.languageClient);

      // Store existing onmessage handlers so we can intercept the callback
      const onMessage = webSocket.onmessage.bind(webSocket);
      webSocket.onmessage = (event: MessageEvent) => {
        if (event.data !== HEART_BEAT) {
          onMessage(event);
        }
      };

      // Set workspace store loading state to await diagnostic
      // to trigger next step in connection sequence
      this._store.dispatch(
        WorkspaceActions.updateWorkspaceLoadingState({
          state: ConnectionState.AwaitingDiagnostics,
        })
      );

      this._webSocket = webSocket;
    };
  }

  closeSocket(): void {
    /*
    IMPORTANT: The editor subject needs to be cleared before the
    websocket so all subsequent actions happen on the new editor
    */
    this.editorSubject$.next(null);

    if (this.languageClient && !this._closingSocket) {
      this._closingSocket = true;
      this._closeModels();
      this.languageClient.diagnostics?.dispose();
      this.languageClient.stop(DEFAULT_API_TIMEOUT).finally(() => {
        this._closingSocket = false;
        this._webSocket?.close(1000);
        this._webSocket = null;
        this.languageClient = null;
      });
    }
  }

  goToSymbol(
    classIdentification: SymbolIdentifier | NodeNameType
  ): Observable<void> {
    return this._getSymbolIdentifier(classIdentification).pipe(
      first(),
      switchMap(symbol =>
        from(this._goToSymbolWithSymbolIdentifier(symbol) || of(undefined))
      )
    );
  }

  diffNavigate(navObj: NavigateObject): void {
    if (this.diffEditor && this.diffNav) {
      // Diff navigator in editor toolbar
      if (navObj.direction === NavigateDirection.Next) {
        this.diffNav.next();
      } else {
        this.diffNav.previous();
      }
    }
  }

  isSocketState(state: number): Observable<boolean> {
    return this._socketState$.pipe(map(socketState => socketState === state));
  }

  updateModelContents(uri: string, contents: string): void {
    const model = monaco.editor.getModel(monaco.Uri.parse(uri));
    if (model) {
      model.setValue(contents);
    }
  }

  selectItem(uri: string | undefined): void {
    if (!uri) {
      throw new Error('Unable to set model due to missing "uri".');
    }

    this.editorSubject$.pipe(first(isNotNull)).subscribe(editor => {
      editor.setModel(monaco.editor.getModel(monaco.Uri.parse(uri)));
    });
  }

  updateEditorOptions(
    newOptions: monaco.editor.IEditorOptions &
      monaco.editor.IGlobalEditorOptions
  ): void {
    this.editorSubject$.pipe(first(isNotNull)).subscribe(editor => {
      editor.updateOptions(newOptions);
    });
  }

  setSymbolLocation(data: {
    documentRange: DocumentSymbol['range'];
    uri: string;
  }): void {
    if (!data) {
      return;
    }
    const range = this._convertToMonacoRange(data.documentRange);
    this._selectItemByUri(data.uri);
    this.selectTextItem(range);
  }

  selectTextItem(range: monaco.Range): void {
    this.editorSubject$.pipe(delay(100), first(isNotNull)).subscribe(editor => {
      editor.setPosition({
        lineNumber: range.startLineNumber,
        column: range.startColumn,
      });
      editor.focus();
      editor.setSelection(range);
      editor.revealLineInCenter(range.startLineNumber, 1);
    });
  }

  addOverrideSyntax(uri: string): void {
    this.editorSubject$.pipe(first(isNotNull)).subscribe(editor => {
      const editorContent = editor.getValue();
      if (!editorContent.startsWith(OVERRIDE_NAMESPACE_SYNTAX)) {
        editor.setValue(`${OVERRIDE_NAMESPACE_SYNTAX} ${editorContent}`);
      }
    });
  }

  private _closeModels(): void {
    monaco.editor.getModels().forEach(model => model.dispose());
  }

  private _getModelSymbols(
    uri: string
  ): Promise<Array<DocumentSymbol>> | undefined {
    return this.languageClient?.sendRequest(DocumentSymbolRequest.method, {
      textDocument: {
        uri,
      },
    });
  }

  private _installClientServices(): void {
    try {
      MonacoServices.get();
    } catch {
      rosettaServicesInstall();
    }
  }

  private _getWebSocketUrl(info: WorkspaceInfo): string {
    return rewriteProjectWorkspaceUrlPath(
      `${this._config.sockets.languageClient}/${info.urlPath}`,
      info
    );
  }

  private _startLanguageClientListeners(
    languageClient: MonacoLanguageClient
  ): void {
    languageClient.onDidChangeState((e: StateChangeEvent) => {
      const stateMap = {
        [State.Running]: ConnectionState.Connected,
        [State.Stopped]: ConnectionState.Disconnected,
        [State.Starting]: ConnectionState.Connecting,
      };

      if (stateMap[e.newState]) {
        this._store.dispatch(
          WorkspaceActions.updateWorkspaceLoadingState({
            state: stateMap[e.newState],
          })
        );
      }
    });
  }

  private _createLanguageClient(
    workspace: Workspace,
    transports: MessageTransports
  ): MonacoLanguageClient {
    return new MonacoLanguageClient({
      name: 'Rosetta Language Client',
      clientOptions: {
        // use a language id as a document selector
        documentSelector: [LANGUAGE_ROSETTA],
        // disable the default error handler
        errorHandler: {
          error: () => ({ action: ErrorAction.Continue }),
          closed: () => ({ action: CloseAction.DoNotRestart }),
        },
        initializationOptions: {
          // project root directory
          workspaceFolders: [Uri.parse(workspace.info.workingUri)],
        },
        initializationFailedHandler: () => {
          return false;
        },
        workspaceFolder: {
          name: workspace.info.id.name,
          index: 1,
          uri: Uri.parse(workspace.info.workingUri),
        },
        middleware: this._middlewareService.getMiddleware(
          workspace.items.length
        ),
      },
      connectionProvider: {
        get: () => Promise.resolve(transports),
      },
    });
  }

  private async _goToSymbolWithSymbolIdentifier(
    classIdentification: SymbolIdentifier
  ): Promise<void> | undefined {
    const symbols = await this._getModelSymbols(classIdentification.uri);
    const symbolLocation = this._findSymbolLocation(
      symbols,
      classIdentification
    );
    if (!symbolLocation) {
      throw Error('Unable to find symbol location');
    }
    return this.setSymbolLocation(symbolLocation);
  }

  private _selectItemByUri(
    uri: string,
    restoreViewState?: (currentEditor: monaco.editor.ICodeEditor) => void
  ): void {
    // Ensure dispatch runs inside ngZones when navigation to an item
    this._store.dispatch(
      WorkspaceActions.selectWorkspaceItem({
        uri,
      })
    );

    if (restoreViewState) {
      this.editorSubject$.pipe(first(isNotNull)).subscribe(editor => {
        restoreViewState(editor);
      });
    }
  }

  private _getServiceOverrides(): ServiceOverridesFunctions {
    return {
      openEditor: (uri, restoreViewState) =>
        this._selectItemByUri(uri.toString(), restoreViewState),
      findModel: (uri: Uri) =>
        monaco.editor
          .getModels()
          .find(model => model.uri.toString() === uri.toString()),
      readonlyMessage: () => {
        return this._store.select(WorkspaceSelectors.selectReadOnlyMessage);
      },
    };
  }

  // Monaco is one-based but the LSP is zero-based.
  private _convertToMonacoRange(range: DocumentSymbol['range']): monaco.Range {
    return new monaco.Range(
      range.start.line + 1,
      range.start.character + 1,
      range.end.line + 1,
      range.end.character + 1
    );
  }

  private _findSymbolLocation(
    symbols: DocumentSymbol[],
    classIdentification: SymbolIdentifier
  ): {
    documentRange: DocumentSymbol['range'];
    uri: string;
  } | null {
    for (const symbol of symbols) {
      const result = this._findSymbolLocationRecursive(
        symbol,
        classIdentification
      );
      if (result !== null) {
        return result;
      }
    }
    return null;
  }

  private _findSymbolLocationRecursive(
    symbol: DocumentSymbol,
    classIdentification: SymbolIdentifier
  ): {
    documentRange: DocumentSymbol['range'];
    uri: string;
  } | null {
    if (symbol.name === classIdentification.fqn) {
      return { documentRange: symbol.range, uri: classIdentification.uri };
    }
    return this._findSymbolLocation(symbol.children || [], classIdentification);
  }

  private _getSymbolIdentifier(
    obj: SymbolIdentifier | NodeNameType
  ): Observable<SymbolIdentifier> {
    const isNodeNameType = (item: any): item is NodeNameType =>
      item.type !== undefined;

    if (!isNodeNameType(obj)) {
      return of(obj);
    }

    return this._workspaceApiService.getSymbolFromType(obj);
  }
}
