<button
  appStopPropagation
  mat-icon-button
  (click)="toggle()"
  [ngSwitch]="showNative"
>
  <fa-icon *ngSwitchCase="true" icon="laptop-code" matTooltip="View as code" />
  <fa-icon
    *ngSwitchDefault
    icon="align-justify"
    matTooltip="View as formatted document"
  />
</button>

<button appStopPropagation mat-icon-button (click)="download.emit()">
  <fa-icon icon="download" matTooltip="Download" />
</button>
