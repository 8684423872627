const themeColors = {
  darkest: '#2B2B2B',
  darker: '#323232',
  dark: '#777777',
  offBlue: '#618AA9',
  leafGreen: '#478239',

  // Rosetta Palette
  rosWhite: '#ffffff',
  rosDarkPink: '#cc1598', //#c4297d
  rosLightPink: '#e94591',
  rosLime: '#c9d646',
  rosDarkLime: '#8c9621',
  rosLime900: '#585b38',
  rosLightGreen: '#96cca6',
  rosDarGreen: '#23974a', // #65a270
  rosLightBlue: '#dceff2',
  rosDarkBlue: '#576f86',
  rosHighlightBlue: '#29c2e3',
  rosHighlightBlue100: '#85dcef',
  rosHighlightBlue700: '#0096ad',
  rosRed: '#c4293e',
  rosRed100: '#e1707f',
  rosGrey10: '#c0c0c0',
  rosGrey50: '#9a9a9a',
  rosGrey100: '#737373',
  rosGrey500: '#414141',
  rosGrey700: '#0d0d0d',
} as const;

export type SEMANTIC_TOKEN_SCOPES_KEYS = keyof typeof SEMANTIC_TOKEN_SCOPES;
export const SEMANTIC_TOKEN_SCOPES = {
  type: ['entity.name.type.type.rosetta'],
  enum: ['entity.name.type.enum.rosetta'],
  basicType: ['entity.name.type.builtin.basic-type.rosetta'],
  recordType: ['entity.name.type.builtin.record-type.rosetta'],
  qualifiedType: ['entity.name.type.builtin.qualified-type.rosetta'],
  calculationType: ['entity.name.type.builtin.calculation-type.rosetta'],
  documentCorpus: ['entity.name.document.corpus.rosetta'],
  documentSegment: ['entity.name.document.segment.rosetta'],
  property: ['variable.single.other.member.rosetta'],
  'property.multiCardinality': ['variable.multi.other.member.rosetta'],
  metaMember: ['variable.single.other.member.meta.rosetta'],
  enumMember: ['variable.single.other.enummember.rosetta'],
  variable: ['variable.single.other.alias.rosetta'],
  'variable.multiCardinality': ['variable.multi.other.alias.rosetta'],
  parameter: ['variable.single.parameter.input.rosetta'],
  'parameter.multiCardinality': ['variable.multi.parameter.input.rosetta'],
  output: ['variable.single.parameter.output.rosetta'],
  'output.multiCardinality': ['variable.multi.parameter.output.rosetta'],
  inlineParameter: ['variable.single.parameter.inline.rosetta'],
  'inlineParameter.multiCardinality': [
    'variable.multi.parameter.inline.rosetta',
  ],
  function: ['entity.name.function.single.rosetta'],
  'function.multiCardinality': ['entity.name.function.multi.rosetta'],
  'function.defaultLibrary': ['entity.name.function.single.builtin.rosetta'],
  rule: ['entity.name.rule.single.rosetta'],
  'rule.multiCardinality': ['entity.name.rule.multi.rosetta'],
  implicitVariable: ['constant.language.single.rosetta'],
  'implicitVariable.multiCardinality': ['constant.language.multi.rosetta'],
} as const;

export const MONACO_DARK_THEME: monaco.editor.IStandaloneThemeData = {
  base: 'vs-dark',
  inherit: true,
  colors: {
    'editor.background': themeColors.darkest,
    'editor.lineHighlightBackground': themeColors.darker,
    'editor.lineHighlightBorder': themeColors.dark,

    focusBorder: themeColors.rosDarkLime,
    'textBlockQuote.border': themeColors.rosDarkLime,
    'inputValidation.infoBorder': themeColors.rosDarkLime,
    'button.background': themeColors.rosDarkLime,
    'progressBar.background': themeColors.rosDarkLime,
    'inputValidation.infoBackground': themeColors.rosDarkLime,

    'textLink.foreground': themeColors.rosDarkLime,
    'textLink.activeForeground': themeColors.rosDarkLime,
    'editorInfo.foreground': themeColors.rosDarkBlue,
    'pickerGroup.foreground': themeColors.rosDarkLime,

    'list.focusOutline': themeColors.rosLime,
    'list.inactiveFocusOutline': themeColors.rosLime,
    'list.activeSelectionBackground': themeColors.rosLime900,
    'list.activeSelectionForeground': themeColors.rosGrey10,
    'list.highlightForeground': themeColors.rosDarkLime,
  },
  rules: [
    { token: 'keyword', foreground: themeColors.rosLime },
    {
      token: 'keyword.control.conditional',
      fontStyle: 'italic bold',
    },
    { token: 'constant', foreground: themeColors.rosLime },
    { token: 'variable', foreground: themeColors.rosGrey10 },
    {
      token: 'variable.multi',
      foreground: themeColors.rosGrey10,
      fontStyle: 'bold',
    },
    { token: 'entity', foreground: themeColors.rosGrey10 },
    {
      token: 'entity.name.function.multi',
      foreground: themeColors.rosGrey10,
      fontStyle: 'bold',
    },
    {
      token: 'entity.name.rule.multi',
      foreground: themeColors.rosGrey10,
      fontStyle: 'bold',
    },
    { token: 'string', foreground: themeColors.rosDarGreen },
    {
      token: 'punctuation.definition.string',
      foreground: themeColors.rosDarGreen,
    },

    { token: 'comment', foreground: themeColors.rosGrey100 },
    {
      token: 'punctuation.definition.comment',
      foreground: themeColors.rosGrey100,
    },

    {
      token: 'comment.block.documentation',
      foreground: themeColors.rosHighlightBlue700,
    },
  ],
};

export const MONACO_LIGHT_THEME: monaco.editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: true,
  colors: {
    'editor.background': themeColors.rosWhite,
    'editor.foreground': themeColors.rosGrey700,

    'editor.inactiveSelectionBackground': '#E5EBF1',
    'editorIndentGuide.background': '#D3D3D3',
    'editorIndentGuide.activeBackground': '#939393',
    'editor.selectionHighlightBackground': themeColors.rosLightBlue,
    'editorSuggestWidget.background': '#F3F3F3',

    'activityBarBadge.background': themeColors.rosLightPink,
    'sideBarTitle.foreground': '#6F6F6F',
    'list.hoverBackground': '#E8E8E8',
    'input.placeholderForeground': '#ADADAD',
    'editor.lineHighlightBackground': '#fcfcfc',
    'editor.lineHighlightBorder': '#dbdbdb',
    'editor.monacoListRows': themeColors.rosLightPink,

    focusBorder: themeColors.rosLightPink,
    'textBlockQuote.border': themeColors.rosLightPink,
    'inputValidation.infoBorder': themeColors.rosLightPink,
    'button.background': themeColors.rosLightPink,
    'progressBar.background': themeColors.rosLightPink,
    'inputValidation.infoBackground': themeColors.rosLightPink,

    'textLink.foreground': themeColors.rosLightPink,
    'textLink.activeForeground': themeColors.rosLightPink,
    'editorInfo.foreground': themeColors.rosDarkBlue,
    'pickerGroup.foreground': themeColors.rosLightPink,

    'list.focusOutline': themeColors.rosDarkPink,
    'list.inactiveFocusOutline': themeColors.rosDarkPink,
    'list.focusHighlightForeground': themeColors.rosLightPink,
    'list.activeSelectionBackground': themeColors.rosHighlightBlue100,
    'list.activeSelectionForeground': themeColors.rosGrey700,
    'list.highlightForeground': themeColors.rosLightPink,
  },
  rules: [
    { token: 'keyword', foreground: themeColors.rosDarkPink },
    {
      token: 'keyword.control.conditional',
      fontStyle: 'italic bold',
    },
    { token: 'constant', foreground: themeColors.rosDarkPink },
    { token: 'string', foreground: themeColors.rosGrey500 },
    { token: 'entity', foreground: themeColors.rosGrey700 },
    {
      token: 'entity.name.function.multi',
      foreground: themeColors.rosGrey700,
      fontStyle: 'bold',
    },
    {
      token: 'entity.name.rule.multi',
      foreground: themeColors.rosGrey700,
      fontStyle: 'bold',
    },
    { token: 'variable', foreground: themeColors.rosGrey700 },
    {
      token: 'variable.multi',
      foreground: themeColors.rosGrey700,
      fontStyle: 'bold',
    },
    { token: 'punctuation.accessor', foreground: themeColors.rosDarkPink },

    { token: 'comment', foreground: themeColors.rosGrey50 },
    {
      token: 'punctuation.definition.comment',
      foreground: themeColors.rosGrey50,
    },

    {
      token: 'punctuation.definition.string',
      foreground: themeColors.rosDarGreen,
    },
    {
      token: 'string.quoted.double',
      foreground: themeColors.rosDarGreen,
    },

    {
      token: 'comment.block.documentation',
      foreground: themeColors.rosHighlightBlue700,
    },

    { token: 'identifier', foreground: themeColors.rosGrey700 },
    { token: 'number', foreground: themeColors.offBlue },
    { token: 'description', foreground: themeColors.rosGrey500 },
  ],
};
