import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmationData,
} from './confirm-dialog/confirm-dialog.component';

@Injectable()
export class ConfirmationService {
  constructor(private _dialog: MatDialog) {}

  open(
    data?: ConfirmationData,
    disableClose = false
  ): MatDialogRef<ConfirmDialogComponent, boolean> {
    const dialog = ConfirmDialogComponent;
    const dialogRef = this._dialog.open(dialog, dialog.options(data));
    dialogRef.disableClose = disableClose;
    return dialogRef;
  }
}
