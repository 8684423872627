import { Injectable } from '@angular/core';
import { BaseWorkspaceStateService } from '@core/services';
import { TaskStatus, TaskStatusKey } from '@models';
import { Store } from '@ngrx/store';
import { TaskSelectors } from '@store/selectors';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  Observable,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
} from 'rxjs';

@Injectable({
  // TODO: Move this to the workspace module
  providedIn: 'root',
})
export class WorkspaceStateManagerService {
  constructor(private _store: Store) {
    this.workspaceSwitch$
      .pipe(skip(1)) // SKIP the initial value as this is not a workspace switch
      .subscribe(() => this._workspaceSwitched());
  }

  private _services = new Set<BaseWorkspaceStateService>();

  /*
  workspaceSwitch is the source observable for all other observables
  so we need to use `shareReplay(1)` to ensure we always get an initial
  value to trigger our streams
  */
  workspaceSwitch$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceId)
    .pipe(
      distinctUntilChanged((prev, next) => prev?.name === next?.name),
      filter(isNotNull),
      shareReplay(1)
    );

  isTaskFinished(task: TaskStatusKey): Observable<boolean> {
    return this._store.select(TaskSelectors.selectTaskStatus(task)).pipe(
      map(taskStatus => TaskStatus.Finished === taskStatus),
      distinctUntilChanged()
    );
  }

  register(service: BaseWorkspaceStateService): void {
    this._services.add(service);
  }

  deregister(service: BaseWorkspaceStateService): void {
    this._services.delete(service);
  }

  private _workspaceSwitched(): void {
    this._services.forEach(service => service.onWorkspaceSwitch());
  }
}
