import { Color } from '@models';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';

type ChartColourTypes =
  | 'finished'
  | 'finishedStale'
  | 'error'
  | 'warning'
  | 'default';

export const chartColours: Readonly<Record<ChartColourTypes, Color>> = {
  finished: '#20AC13', // Green
  finishedStale: '#949494', // Grey
  error: '#D83737', // red
  warning: '#FFD600', // Yellow
  default: '#C4C4C4', // Light Grey
};

export const pieChartFactory = (
  override?: Partial<GoogleChartInterface>
): GoogleChartInterface => ({
  chartType: GoogleChartType.PieChart,
  firstRowIsData: true,
  dataTable: [
    ['Complete', 0],
    ['Not Complete', 100],
  ],
  options: getChartOptionsFactory({
    slices: [{ color: chartColours.finished }, { color: chartColours.default }],
  }),
  ...override,
});

export const getChartOptionsFactory = (override: any) => ({
  width: 40,
  height: 40,
  pieHole: 0.8,
  pieSliceBorderColor: 'transparent',
  legend: 'none',
  backgroundColor: 'transparent',
  chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
  enableInteractivity: false,
  tooltip: {
    trigger: 'none',
  },
  pieSliceText: 'none',
  pieStartAngle: 0,
  ...override,
});
