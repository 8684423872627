import { Inject, Injectable } from '@angular/core';
import { FEEDBACK_UPDATE_INTERVAL } from '@configs';
import { environment } from '@env/environment';
import { RosettaUser } from '@features/auth/login';
import * as Intercom from '@intercom/messenger-js-sdk';
import { InitType } from '@intercom/messenger-js-sdk/dist/types';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(@Inject(WA_WINDOW) private _window: Window) {}

  private _sub = new Subscription();
  private _intercom: typeof Intercom;
  private _intercomLoaded = false;

  async load(user: RosettaUser): Promise<void> {
    if (!environment.intercomConfig.enabled || this._intercomLoaded) {
      if (this._intercom) {
        this._boot(user);
      }
      return;
    }

    await import('@intercom/messenger-js-sdk')
      .then(Intercom => {
        this._intercom = Intercom;
        this._init(user);
        this._checkForUpdates();
        this._intercomLoaded = true;
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error loading Intercom:', error);
      });
  }

  stop(): void {
    if (this._intercom) {
      this._intercom.shutdown();
      this._sub.unsubscribe();
    }
  }

  update(): void {
    if (this._intercom) {
      this._intercom.update({});
    }
  }

  private _userConfig(user: RosettaUser): InitType {
    return {
      app_id: environment.intercomConfig.appId,
      env: this._window.location.hostname,
      widget: {
        activator: '#intercom',
      },
      user_id: user.authId,
      user_hash: user.userHash,
      email: user.email,
    };
  }

  private _init(user: RosettaUser): void {
    this._intercom.Intercom(this._userConfig(user));
  }

  private _boot(user: RosettaUser): void {
    this._intercom.boot(this._userConfig(user));
  }

  private _checkForUpdates(): void {
    this._sub.add(
      timer(0, FEEDBACK_UPDATE_INTERVAL).subscribe(() => this.update())
    );
  }
}
