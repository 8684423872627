import { map, OperatorFunction } from 'rxjs';
import { TestPackSampleId } from '../test-pack-sample-id.model';
import { TransformDTO } from '../transform-dto.model';

export const fromTestPackSampleIdDTO = (): OperatorFunction<
  TransformDTO.TestPackSampleId,
  TestPackSampleId
> => {
  return map(testPackSampleId => ({
    selection: {
      pipelineDef: {
        ...testPackSampleId.selection.pipelineDef,
        id: testPackSampleId.selection.pipelineDef.id.id,
      },
      testPackDef: {
        ...testPackSampleId.selection.testPackDef,
        id: testPackSampleId.selection.testPackDef.id.id,
      },
    },
    sampleDef: {
      ...testPackSampleId.sampleDef,
      id: testPackSampleId.sampleDef.id.id,
    },
  }));
};
