<div class="spacer text-ellipsis">
  <button mat-icon-button class="node-expand-btn mr-5">
    <fa-icon
      [icon]="
        parent.treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'
      "
      [fixedWidth]="true"
    />
  </button>
  <span class="text-ellipsis">{{ node.name }}</span>
</div>
<fa-icon
  class="node-lock-icon ml-8 mr-16"
  *ngIf="node.lockState === 'locked'"
  icon="lock"
  [fixedWidth]="true"
/>
<fa-icon
  *ngIf="node.modified"
  class="node-status pr-16 pl-8"
  icon="circle"
  size="xs"
/>
