import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExternalLink, InternalLink } from '@configs';
import { PackagePlan } from '@models';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/.';
import { AppSelectors, AuthSelectors } from '@store/selectors';
import { contrast } from '@utils';
import { map } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  userInitials$ = this._store.select(AuthSelectors.selectUserInitials);
  userColor$ = this._store.select(AuthSelectors.generateUserColour);
  textColor$ = this.userColor$.pipe(map(colour => contrast(colour)));
  coreVersion$ = this._store.select(AppSelectors.selectAppVersion);
  showUpgrade$ = this._store
    .select(AuthSelectors.getPackagePlan)
    .pipe(map(packagePlan => packagePlan !== PackagePlan.Enterprise));

  readonly internalLink = InternalLink;
  readonly externalLink = ExternalLink;

  constructor(private _store: Store) {}

  logout() {
    this._store.dispatch(AuthActions.logout());
  }
}
