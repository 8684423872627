import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { Alignment } from '@models';
import { CollapsiblePanelComponent } from '@shared/modules/collapsible-panel/components/collapsible-panel/collapsible-panel.component';
import { SlideInOutAnimations } from '@shared/modules/collapsible-panel/slide-in-out.animations';
import { EMPTY, map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-collapsible-panel-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './collapsible-panel-header.component.html',
  styleUrls: ['./collapsible-panel-header.component.scss'],
  animations: [SlideInOutAnimations],
  host: {
    class: 'collapsible-panel-header flex-row flex-align-center',
  },
})
export class CollapsiblePanelHeaderComponent implements OnInit {
  showButton$: Observable<string> = EMPTY;
  parentTitle?: string;

  @Input() contentAlign: Alignment = 'start';
  @Input({ transform: booleanAttribute }) showTitle = true;

  @HostBinding('class.panel-header-can-close')
  get panelHeaderCanClose() {
    return this.parent?.canClose;
  }

  @HostListener('click')
  onClick() {
    this.parent?.close();
  }

  constructor(@Optional() public parent: CollapsiblePanelComponent) {}

  ngOnInit() {
    if (this.parent) {
      this.parentTitle = this.parent.panelTitle;
      this.showButton$ = this.parent.isHover$.pipe(
        startWith('hide'),
        map(isHover => (isHover ? 'show' : 'hide'))
      );
    } else {
      this.showTitle = false;
    }
  }
}
