import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RosettaOverlayHoverTargetDirective } from './rosetta-overlay-hover-target.directive';
import { RosettaOverlayTargetDirective } from './rosetta-overlay-target.directive';
import { RosettaOverlayComponent } from './rosetta-overlay.component';

@NgModule({
  declarations: [
    RosettaOverlayComponent,
    RosettaOverlayTargetDirective,
    RosettaOverlayHoverTargetDirective,
  ],
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [
    RosettaOverlayComponent,
    RosettaOverlayHoverTargetDirective,
    RosettaOverlayTargetDirective,
  ],
})
export class RosettaOverlayModule {}
