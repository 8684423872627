export interface ServerError {
  readonly timestamp: string;
  readonly userMessage: string;
  readonly supportMessage: string[];
  readonly causedBy?: string;
  readonly errorFiles?: string[];
}

export const isServerError = (error: any): error is ServerError => {
  return (
    error &&
    typeof error === 'object' &&
    error.timestamp &&
    error.userMessage &&
    error.supportMessage &&
    Array.isArray(error.supportMessage) &&
    (error.causedBy === undefined || typeof error.causedBy === 'string') &&
    (error.errorFiles === undefined ||
      Array.isArray(error.errorFiles) ||
      error.errorFiles === null)
  );
};
