import { Injectable } from '@angular/core';
import { NotificationService } from '@core/modules/snack-bar';
import { SupportApiService } from '@features/support/services/support-api.service';
import { SupportActions } from '@features/support/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as WorkspaceActions from '@store/workspace/actions';
import { saveAs } from 'file-saver';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

@Injectable()
export class SupportEffects {
  constructor(
    private _actions$: Actions,
    private _supportApiService: SupportApiService,
    private _notify: NotificationService
  ) {}

  copyWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SupportActions.copyWorkspace),
      mergeMap(({ payload }) =>
        this._supportApiService.copyWorkspace(payload).pipe(
          map(() => SupportActions.copyWorkspaceSuccess()),
          catchError(response =>
            of(
              SupportActions.copyWorkspaceError({
                message: response.error,
              })
            )
          )
        )
      )
    );
  });

  copySuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SupportActions.copyWorkspaceSuccess),
      tap(() => {
        this._notify.showSuccess({ message: 'Workspace copy success!' });
      }),
      map(() => WorkspaceActions.loadWorkspaces())
    );
  });

  copyError$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(SupportActions.copyWorkspaceError),
        tap(({ message }) => {
          this._notify.showError({ message });
        })
      );
    },
    { dispatch: false }
  );

  downloadWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SupportActions.downloadWorkspace),
      mergeMap(({ workspaceId }) =>
        this._supportApiService.downloadAndZipWorkspace(workspaceId).pipe(
          tap(data => saveAs(data, `${workspaceId.name}.zip`)),
          map(() => SupportActions.downloadWorkspaceSuccess()),
          catchError(response =>
            of(
              SupportActions.downloadWorkspaceError({
                message: response.error,
              })
            )
          )
        )
      )
    );
  });

  downloadOrUploadError$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(
          SupportActions.downloadWorkspaceError,
          SupportActions.uploadWorkspaceError
        ),
        tap(({ message }) => {
          this._notify.showError({ message });
        })
      );
    },
    { dispatch: false }
  );

  uploadSuccess$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(SupportActions.uploadWorkspaceSuccess),
        tap(() => {
          this._notify.showSuccess({ message: 'Workspace upload success!' });
        })
      );
    },
    { dispatch: false }
  );
}
