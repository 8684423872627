import { TOP_LEVEL_SCOPES } from '@configs';
import { INITIAL, Registry, StackElement } from 'monaco-textmate';
import {
  SEMANTIC_TOKEN_SCOPES,
  SEMANTIC_TOKEN_SCOPES_KEYS,
} from '@app/config/monaco-themes.config';

/**
 * Patch for https://github.com/zikaari/monaco-editor-textmate/issues/24.
 * Monaco currently doesn't support multiple scopes per token, which TextMate requires.
 * In this patch, instead of considering all scopes, we pass the appropriate scope to Monaco.
 * (appropriate = specific to our use case)
 */

/**
 * Wires up monaco-editor with monaco-textmate
 *
 * @param monacoNsps monaco namespace this operation should apply to (usually the `monaco` global unless you have some other setup)
 * @param registry TmGrammar `Registry` this wiring should rely on to provide the grammars
 * @param languages `Map` of language ids (string) to TM names (string)
 */
export function wireTmGrammars(
  monacoNsps: typeof monaco,
  registry: Registry,
  languages: Map<string, string>,
  editor?: monaco.editor.IEditor
) {
  const loggedErrors = new Set<string>();
  return Promise.all(
    Array.from(languages.keys()).map(async languageId => {
      const grammar = await registry.loadGrammar(languages.get(languageId));
      return monacoNsps.languages.setTokensProvider(languageId, {
        getInitialState: () => new TokenizerState(INITIAL),
        tokenize: (line: string, state: TokenizerState) => {
          // tryCatch block added with error message
          // cache to prevent the console from being spammed
          try {
            const res = grammar.tokenizeLine(line, state.ruleStack);
            return {
              endState: new TokenizerState(res.ruleStack),
              tokens: res.tokens.map(token => {
                const appropriateScopes = findAppropriateScopes(token.scopes);
                return {
                  ...token,
                  scopes: editor
                    ? TMToMonacoToken(
                        (editor as any)['_themeService']._theme.tokenTheme,
                        appropriateScopes
                      )
                    : appropriateScopes[appropriateScopes.length - 1],
                };
              }),
            };
          } catch (error: any) {
            if (!loggedErrors.has(error.message)) {
              loggedErrors.add(error.message);
              throw error;
            }
            return {
              endState: new TokenizerState(state.ruleStack),
              tokens: [],
            };
          }
        },
      });
    })
  );
}

class TokenizerState {
  constructor(private _ruleStack: StackElement) {}

  public get ruleStack(): StackElement {
    return this._ruleStack;
  }

  public clone(): TokenizerState {
    return new TokenizerState(this._ruleStack);
  }

  public equals(other: any): boolean {
    if (
      !other ||
      !(other instanceof TokenizerState) ||
      other !== this ||
      other._ruleStack !== this._ruleStack
    ) {
      return false;
    }
    return true;
  }
}

export function TMToMonacoToken(tokenTheme: any, scopes: string[]) {
  if (scopes.length === 0) {
    return '';
  }
  let scopeName = '';
  // get the scope name. Example: cpp , java, haskell
  for (let i = scopes[0].length - 1; i >= 0; i -= 1) {
    const char = scopes[0][i];
    if (char === '.') {
      break;
    }
    scopeName = char + scopeName;
  }

  // iterate through all scopes from last to first
  for (let i = scopes.length - 1; i >= 0; i -= 1) {
    const scope = scopes[i];

    /**
     * Try all possible tokens from high specific token to low specific token
     *
     * Example:
     * 0 meta.function.definition.parameters.cpp
     * 1 meta.function.definition.parameters
     *
     * 2 meta.function.definition.cpp
     * 3 meta.function.definition
     *
     * 4 meta.function.cpp
     * 5 meta.function
     *
     * 6 meta.cpp
     * 7 meta
     */
    for (let i = scope.length - 1; i >= 0; i -= 1) {
      const char = scope[i];
      if (char === '.') {
        const token = scope.slice(0, i);

        if (tokenTheme._match(token + '.' + scopeName)._foreground > 1) {
          return token + '.' + scopeName;
        }
        if (tokenTheme._match(token)._foreground > 1) {
          return token;
        }
      }
    }
  }

  if (scopes.length === 0) {
    return '';
  }
  return scopes[scopes.length - 1];
}

/**
 * Hack to change the order of token scopes so that we can control the specificity
 */
function findAppropriateScopes(originalScopes: string[]): string[] {
  const copiedScopes = [...originalScopes];
  const newScopes: string[] = [];
  const topLevelSet = new Set(TOP_LEVEL_SCOPES);
  for (let i = 0; i < copiedScopes.length; i++) {
    if (topLevelSet.has(copiedScopes[i])) {
      newScopes.push(copiedScopes[i]);
      copiedScopes.splice(i--, 1);
    }
  }
  return copiedScopes.concat(newScopes);
}

export function semanticTokenToScopes(
  type: string,
  modifiers: string[]
): string[] {
  const result: string[] = [];
  const typeScopes = SEMANTIC_TOKEN_SCOPES[type as SEMANTIC_TOKEN_SCOPES_KEYS];
  if (typeScopes) {
    result.push(...typeScopes);
  }
  const currentModifiers: string[] = [];
  for (const modifier of modifiers) {
    currentModifiers.push(modifier);
    const wildcardSemanticToken = ['*'].concat(currentModifiers).join('.');
    const wildcardScopes =
      SEMANTIC_TOKEN_SCOPES[
        wildcardSemanticToken as SEMANTIC_TOKEN_SCOPES_KEYS
      ];
    if (wildcardScopes) {
      result.push(...wildcardScopes);
    }
    const semanticToken = [type].concat(currentModifiers).join('.');
    const scopes =
      SEMANTIC_TOKEN_SCOPES[semanticToken as SEMANTIC_TOKEN_SCOPES_KEYS];
    if (scopes) {
      result.push(...scopes);
    }
  }
  return result;
}
