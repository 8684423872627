import { NavigationExtras } from '@angular/router';
import { CustomParams, WorkspaceId } from '@models';
import { createAction, props } from '@ngrx/store';

/*
Remove "relativeTo" from type as redux can't serialise an ActivatedRoute
 */
export type SafeNavigationExtras = Omit<
  NavigationExtras & { queryParams?: CustomParams },
  'relativeTo'
>;

export const gotoWorkspaceManager = createAction(
  '[Router] Go To Workspace Manager'
);

export const gotoWorkspace = createAction(
  '[Router] Go To Workspace',
  props<{ workspaceId: WorkspaceId }>()
);

export const goto = createAction(
  '[Router] Go To',
  props<{ path?: any[]; extras?: SafeNavigationExtras }>()
);

export const openBottomPanel = createAction(
  '[Router] Open Bottom Panel',
  props<{ path: any[] }>()
);

export const gotoProjectAdmin = createAction(
  '[Router] Goto Project Admin',
  props<{ modelId: string }>()
);
