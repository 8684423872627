<ng-scrollbar>
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="file-tree small-button-wrapper"
  >
    <!-- Template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node">
      <app-file-tree-leaf-node
        [node]="node"
        [showOptions]="showOptions"
        (nodeClicked)="onClick($event)"
      />
    </mat-tree-node>

    <!-- Template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <app-file-tree-node matTreeNodeToggle [node]="node" />

      <div
        [class.file-tree-invisible]="!treeControl.isExpanded(node)"
        role="group"
      >
        <ng-container matTreeNodeOutlet />
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</ng-scrollbar>
