import { Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import { first } from 'rxjs';
import { UpgradePlanDialogComponent } from '../modules/upgrade-plan-dialog/upgrade-plan-dialog.component';

@Directive({
  standalone: true,
  selector: '[appClickToOpenUpgradeDialog]',
})
export class ClickToOpenUpgradeDialogDirective {
  constructor(
    private _dialog: MatDialog,
    private _store: Store
  ) {}

  @HostListener('click')
  onCLick() {
    this._store
      .select(AuthSelectors.selectFormConfig)
      .pipe(first())
      .subscribe(formConfig => {
        this._dialog.open(
          UpgradePlanDialogComponent,
          UpgradePlanDialogComponent.options(formConfig)
        );
      });
  }
}
