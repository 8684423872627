<div class="node-container" *ngIf="node$ | async as node">
  <small [matTooltip]="breadCrumbTrail">
    <span *ngFor="let p of node.path">
      <ng-template #notClickable>{{ p.name }}</ng-template>
      <a
        [appGoToSymbol]="p"
        *ngIf="modelContent && p.type; else notClickable"
        >{{ p.name }}</a
      >
    </span>
  </small>
  <div class="spacer"></div>
  <app-copy-to-clipboard [path]="breadCrumbTrail" />
</div>
