export function round(value: number, precision = 2): number {
  if (isNaN(value)) {
    return 0;
  }
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

export function nullAddition(
  x: number | null,
  y: number | null
): number | null {
  if (x === null && y === null) {
    return null;
  }

  if (x === null) {
    return y;
  }

  if (y === null) {
    return x;
  }

  return x + y;
}
