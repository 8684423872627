import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { InlineErrorComponent } from './inline-error.component';

@NgModule({
  declarations: [InlineErrorComponent],
  imports: [CommonModule, FontsModule],
  exports: [InlineErrorComponent],
})
export class InlineErrorModule {}
