import { Injectable } from '@angular/core';
import { BaseWorkspaceStateService } from '@core/services';
import { TaskService } from '@core/services/task.service';
import {
  APIExportResult,
  IngestionEnvironmentApiExport,
  WorkspaceId,
} from '@models';
import { TaskActions } from '@store/.';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { createUrl, isNotNull } from '@utils';
import {
  ApiExport,
  IApiExport,
  IngestionApiExport,
} from '@workspace-engine/api-export/services/api-export';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  first,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
  zip,
} from 'rxjs';

import { ApiExportApiService } from '../api/api-export-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiExportService extends BaseWorkspaceStateService {
  constructor(
    private _taskService: TaskService,
    private _api: ApiExportApiService
  ) {
    super();
  }

  private _selectedSubject = new BehaviorSubject<IApiExport | null>(null);
  private _apiExportSubject = new Subject<IApiExport[]>();
  private _isAnyExportRunningSubject = new BehaviorSubject(false);
  private _resultMapSubject = new BehaviorSubject<
    Record<string, APIExportResult>
  >({});

  getResultMap$ = this._resultMapSubject.asObservable();
  isAnyExportRunning$ = this._isAnyExportRunningSubject.asObservable();

  /*
  Fetch API exports only when subscribed and then returned cached result until subject emits
  */
  getApiExports$ = this._apiExportSubject.pipe(
    startWith([]),
    switchMap(() =>
      zip(this._loadExportApis(), this._loadIngestionExportApis()).pipe(
        map(([apiExports, ingestionApiExports]) => [
          ...apiExports,
          ...ingestionApiExports,
        ])
      )
    ),
    tap(apiExports => apiExports.forEach(apiExport => apiExport.onInit())),
    shareReplay(1)
  );

  selectedApiExport$ = combineLatest([
    this.getApiExports$,
    this._selectedSubject,
  ]).pipe(
    map(([apiExports, selectedApiExport]) => selectedApiExport || apiExports[0])
  );

  run(ingestionExport: IngestionApiExport): void {
    this._isAnyExportRunningSubject.next(true);
    this._store.dispatch(
      TaskActions.submitTask({ data: ingestionExport.run() })
    );
  }

  updateApiExport(result: APIExportResult): void {
    this._store
      .select(WorkspaceSelectors.selectWorkspaceId)
      .pipe(
        first(isNotNull),
        tap(workspaceId => {
          const results = this._resultMapSubject.getValue();
          this._resultMapSubject.next({
            ...results,
            [result.name]: this._payloadMapper(result, workspaceId),
          });
        })
      )
      .subscribe();
  }

  select(apiExport: IApiExport): void {
    this._selectedSubject.next(apiExport);
  }

  reduceOrUndefined(
    resultName: string,
    results: APIExportResult[]
  ): APIExportResult | undefined {
    return results
      .filter(({ name }) => name === resultName)
      .reduce<APIExportResult | undefined>((prev, current) => {
        if (prev?.timestamp && current?.timestamp) {
          return prev?.timestamp > current?.timestamp ? prev : current;
        }
        return current;
      }, undefined);
  }

  updateRunningStatus(status: boolean): void {
    this._isAnyExportRunningSubject.next(status);
  }

  override onWorkspaceSwitch(): void {
    this._resultMapSubject.next({});
    this._apiExportSubject.next([]);
  }

  private _payloadMapper(
    payload: APIExportResult,
    workspaceId: WorkspaceId
  ): APIExportResult {
    return {
      ...payload,
      ...(payload.apiURL
        ? {
            apiURL: this._apiExportUrl(payload.apiURL, workspaceId),
          }
        : {}),
    };
  }

  private _apiExportUrl(apiUrl: string, workspaceId: WorkspaceId): string {
    const updatedApiUrl = apiUrl
      .replace(/\/(read-only(-[0-9a-zA-Z]+)+)\/?/, '/' + workspaceId.name + '/')
      .replace(/\/$/, '')
      .replace(/\d{8}-\d{6}/, 'latest');
    return createUrl(updatedApiUrl).toString();
  }

  private _loadExportApis(): Observable<ApiExport[]> {
    return this._api.getExports().pipe(
      switchMap(apiExports =>
        this._store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
          first(),
          map(workspaceId =>
            apiExports.map(apiExport => {
              apiExport.url = this._apiExportUrl(apiExport.url, workspaceId);
              return new ApiExport(
                apiExport.name,
                apiExport.type,
                apiExport.url,
                this._taskService
              );
            })
          )
        )
      )
    );
  }

  private _loadIngestionExportApis(): Observable<IngestionApiExport[]> {
    return this._api.getIngestionExports().pipe(
      map(envList => this._createIngestionApiExports(envList)),
      catchError(() => of([]))
    );
  }

  private _createIngestionApiExports(
    envList: IngestionEnvironmentApiExport[]
  ): IngestionApiExport[] {
    return envList.map(
      env =>
        new IngestionApiExport(
          env.displayName,
          env.name,
          this,
          this._taskService
        )
    );
  }
}
