export interface NotificationMessagePayload {
  id: string;
  title: string;
  content: string;
  dismissible: boolean;
}

export interface NotificationMessage {
  type: string;
  payload?: NotificationMessagePayload;
}

export const isNotificationMessage = (
  message: any
): message is NotificationMessage => 'type' in message && 'payload' in message;
