import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { WorkspaceApiService } from '@core/services';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceAccessGuard {
  constructor(
    private _wsApi: WorkspaceApiService,
    private _store: Store
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const currentWorkspace = next.params['workspaceName'];

    return this._wsApi.workspaceExists(currentWorkspace).pipe(
      map(exists => {
        if (exists) {
          return true;
        }
        throw Error();
      }),
      catchError(() => {
        this._store.dispatch(
          WorkspaceActions.workspaceExistsError({
            workspaceName: currentWorkspace,
          })
        );
        return of(false);
      })
    );
  }
}
