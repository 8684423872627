import { ALL_MODELS } from '@configs';
import { LoadingState } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { SettingsReducers } from '@features/settings/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectFeature = createFeatureSelector<SettingsReducers.State>(
  SettingsReducers.featureKey
);

const selectUserProfileSaveState = createSelector(
  selectFeature,
  state => state.userProfileSaveState.state
);

const selectUpdatePasswordState = createSelector(
  selectFeature,
  state => state.updatePasswordState.state
);

const selectDownloadState = (modelId: ModelInstanceId = ALL_MODELS) =>
  createSelector(
    selectFeature,
    (state: SettingsReducers.State) =>
      state.downloadState[modelId] || {
        state: LoadingState.INITIAL,
        errorMessage: null,
      }
  );

const isUserProfileSaving = createSelector(
  selectUserProfileSaveState,
  state => state === LoadingState.LOADING
);
const isUpdatingPassword = createSelector(
  selectUpdatePasswordState,
  state => state === LoadingState.LOADING
);

export const SettingsSelectors = {
  selectFeature,
  selectUserProfileSaveState,
  selectUpdatePasswordState,
  selectDownloadState,
  isUserProfileSaving,
  isUpdatingPassword,
};
