import { CORE_RO, CORE_RW, ResourceType } from '@configs';
import { WorkspaceInfo, WorkspaceMenuItem, WorkspaceType } from '@models';

export const rewriteProjectWorkspaceUrlPath = (
  resourceUrl: string,
  info: WorkspaceInfo | WorkspaceMenuItem
) => {
  if (info.workspaceType === WorkspaceType.Project) {
    return rewriteDynamicUrlPath(resourceUrl, info);
  }
  return resourceUrl.replace(ResourceType.Dynamic, CORE_RW);
};

export const rewriteDynamicUrlPath = (
  resourceUrl: string,
  info: WorkspaceInfo | WorkspaceMenuItem
) => {
  return resourceUrl.replace(
    ResourceType.Dynamic,
    `${CORE_RO}-${info.modelId.toLowerCase()}${
      info.fixedVersionId ? `-${info.fixedVersionId.toLowerCase()}` : ''
    }`
  );
};
