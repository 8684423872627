import { InjectionToken } from '@angular/core';
import { StatusStateName } from '@models';
import { StatusConfig } from '../status/status.config';

export type Statuses = Record<StatusStateName, StatusConfig>;

export const defaultConfig: Statuses = {
  [StatusStateName.Empty]: {
    themeClass: 'theme-color-yellow-alt',
    icon: 'exclamation-circle',
    classes: ['fa-sm'],
    text: 'Selection required',
  },
  [StatusStateName.Started]: {
    themeClass: 'theme-color-primary',
    icon: 'circle-notch',
    classes: ['fa-spin'],
    styles: {
      '--fa-animation-duration': '.7s',
    },
    text: 'Running',
  },
  [StatusStateName.Finished]: {
    themeClass: 'theme-color-success',
    icon: 'circle-check',
    classes: ['fa-bounce'],
    styles: {
      '--fa-animation-iteration-count': '1',
    },
    text: 'Up to date',
  },
  [StatusStateName.Stale]: {
    themeClass: 'theme-color-yellow-alt',
    icon: 'exclamation-circle',
    classes: ['fa-beat-fade'],
    styles: {
      '--fa-animation-iteration-count': '1',
    },
    text: 'Out of date',
  },
  [StatusStateName.Error]: {
    themeClass: 'theme-color-warn',
    icon: 'circle-xmark',
    classes: ['fa-beat-fade'],
    styles: {
      '--fa-animation-iteration-count': '1',
    },
    text: 'Failed',
  },
};

export const STATUS_COMPONENT_CONFIG = new InjectionToken<Statuses>(
  'StateComponentConfig'
);
