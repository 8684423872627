import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { SnackBarActionType, SnackBarConfig } from '@models';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-snack-bar-component',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  constructor(
    private _store: Store,
    private _snackBar: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarConfig
  ) {}

  readonly snackBarActionType = SnackBarActionType;

  close(type: SnackBarActionType) {
    switch (type) {
      case SnackBarActionType.LEGACY:
        this._snackBar.dismissWithAction();
        break;
      case SnackBarActionType.ACTION:
        if (this.data.snackBarAction) {
          this._store.dispatch(this.data.snackBarAction.action());
        }
        this._snackBar.dismiss();
        break;
      default:
        this._snackBar.dismiss();
    }
  }
}
