import { Injectable, OnDestroy, inject } from '@angular/core';
import { Task, TaskStatusKey } from '@models';
import { Store } from '@ngrx/store';
import {
  Observable,
  filter,
  first,
  share,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { WorkspaceStateManagerService } from './workspace-state-manager.service';

@Injectable()
export abstract class BaseWorkspaceStateService implements OnDestroy {
  // Inject these dependencies using inject instead of constructor
  // to avoid extended classes having to inject them
  protected _store = inject(Store);
  protected _workspaceStateManager = inject(WorkspaceStateManagerService);

  // The constructor is only used to initialise the
  // service and should not be used to inject services
  constructor() {
    this._workspaceStateManager.register(this);
  }

  abstract onWorkspaceSwitch(): void;

  /*
  This observable emits true once per workspace when the specified task finishes.
  Later subscribers will receive the same value.

  ## Marble diagram
  values: { a: "workspaceA", b: "workspaceB", p: Task.Pending, s: Task.Started, f: Task.Finished, t: true }
  workspace switch: --a---------b-----
  task finished:    --p-s-f-----p-s-f-
  emits:            ------t---------t-
  shareReplay(1)
  */
  workspaceSwitchAndReady(
    modelChangeTaskTrigger: TaskStatusKey = Task.PojoCompilation
  ): Observable<true> {
    return this._workspaceStateManager.workspaceSwitch$.pipe(
      switchMap(() =>
        this._workspaceStateManager.isTaskFinished(modelChangeTaskTrigger).pipe(
          first(Boolean),
          // Start with false to ensure the shareReplay is updated as soon as the workspace switches
          startWith(false)
        )
      ),
      shareReplay(1),
      filter(Boolean)
    );
  }

  /*
  This observable emits true after a model change.
  Later subscribers will not receive the same value.

  ## Marble diagram
  values: { t: true }
  emits: -t-------t-
  share()
  */
  onModelChanged(
    modelChangeTaskTrigger: TaskStatusKey = Task.PojoCompilation
  ): Observable<boolean> {
    return this._workspaceStateManager
      .isTaskFinished(modelChangeTaskTrigger)
      .pipe(filter(Boolean), share());
  }

  ngOnDestroy(): void {
    this._workspaceStateManager.deregister(this);
  }
}
