import { UiPanel } from './ui-panel.model';
import { ServiceName } from './panel-state';

export type DependencyMap = Map<UiPanel, ServiceName[]>;

export const dependencyMap = new Map([
  [UiPanel.Grid, [ServiceName.GridView]],
  [UiPanel.Editor, [ServiceName.Rosetta]],
  [UiPanel.Ingestion, [ServiceName.Ingestion]],
  [UiPanel.JsonValidator, [ServiceName.Static]],
  [UiPanel.ApiExport, [ServiceName.Static, ServiceName.Ingestion]],
  [UiPanel.Regulations, [ServiceName.Static, ServiceName.Regulations]],
  [UiPanel.RegulationReports, [ServiceName.Static, ServiceName.Regulations]],
  [UiPanel.Visualisation, [ServiceName.Static]],
]);
