import { CREATE_NAMESPACE_SYNO } from '@app/config/permissions.config';
import { FileType, FileTypeEnum } from '@models';

export const FILE_TYPE_CONFIG: FileType[] = [
  {
    extension: FileTypeEnum.ENUM,
    label: `Enumeration`,
  },
  {
    extension: FileTypeEnum.FUNC,
    label: `Function`,
  },
  {
    extension: FileTypeEnum.RULE,
    label: `Rule`,
  },
  {
    extension: FileTypeEnum.SYNO,
    label: `Synonym`,
    permissions: [CREATE_NAMESPACE_SYNO],
  },
  {
    extension: FileTypeEnum.TYPE,
    label: `Type`,
  },
];
