import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { HEX } from '@models';
import { contrast, lightenDarkenColor } from '@utils';

@Component({
  selector: 'app-text-badge',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content />',
  styles: [
    `
      @use 'mixins';

      :host {
        display: inline;
        border-radius: 100px;
        vertical-align: middle;
        z-index: 1;
        transform: scale(0.6);
        @include mixins.transitionFast(transform);
        background-color: var(--badgeBgColor);
        color: var(--badgeColor);

        &:not(:empty) {
          padding: 0 var(--badgeSize);
        }

        &:empty {
          min-width: var(--badgeSize);
          min-height: var(--badgeSize);
        }

        &.active {
          transform: none;
        }
      }
    `,
  ],
  host: {
    class: 'text-badge mat-small font-weight-bold',
  },
})
export class TextBadgeComponent implements OnInit {
  constructor(
    @Attribute('size') private _size: 'sm' | 'md',
    private _element: ElementRef
  ) {}

  @Input() color?: HEX;

  @HostBinding('style.--badgeBgColor') get badgeBgColor(): HEX | null {
    return this.color ? lightenDarkenColor(this.color, 30) : null;
  }

  @HostBinding('style.--badgeColor') get badgeColor(): HEX | null {
    return this.color ? contrast(this.badgeBgColor) : null;
  }

  @HostBinding('style.--badgeSize') get badgeSize(): string {
    const sizeMap = {
      sm: '0.5rem',
      md: '1rem',
    };
    return sizeMap[this._size] || sizeMap['md'];
  }

  ngOnInit(): void {
    requestAnimationFrame(() => {
      this._element.nativeElement.classList.add('active');
    });
  }
}
