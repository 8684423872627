import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { ComponentStateService } from '@core/services';
import { debounceTime, fromEvent, Subscription } from 'rxjs';
import { StorageKey } from '../../models/storage-key.model';

@Directive({
  standalone: true,
  selector: '[appScrollPosition]',
})
export class ScrollPositionDirective implements AfterViewInit, OnDestroy {
  constructor(
    private _cmpState: ComponentStateService,
    private _element: ElementRef<HTMLElement>
  ) {}

  @Input('appScrollPosition') key!: string;

  private _sub = new Subscription();

  private get _namespaceKey(): StorageKey {
    return { key: this.key, namespace: 'scroll' };
  }

  ngAfterViewInit() {
    this._scrollPositionHandler();
    setTimeout(() => this.applySavedScroll());
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  applySavedScroll() {
    const { top, left } = this._cmpState.get(this._namespaceKey) || {
      top: 0,
      left: 0,
    };
    this._element.nativeElement.scrollTop = top;
    this._element.nativeElement.scrollLeft = left;
  }

  clear() {
    this._cmpState.remove(this._namespaceKey);
  }

  private _scrollPositionHandler() {
    this._sub.add(
      fromEvent(this._element.nativeElement, 'scroll')
        .pipe(debounceTime(1000))
        .subscribe(event => this._savePosition(event))
    );
  }

  private _savePosition(event: Event) {
    const target = event.target as Element;
    this._cmpState.set(this._namespaceKey, {
      top: target.scrollTop,
      left: target.scrollLeft,
    });
  }
}
