import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FontsModule } from '@app/fonts/fonts.module';
import { getDialogOptions } from '@configs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DialogComponent } from '@models';

export interface ConfirmationData {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  confirmButtonIcon?: IconProp;
  cancelButtonText?: string;
}

@Component({
  standalone: true,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FontsModule, MatDialogModule, MatButtonModule],
})
export class ConfirmDialogComponent implements DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData
  ) {}

  static options(data?: ConfirmationData) {
    return getDialogOptions('xs', { data });
  }
}
