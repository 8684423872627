import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  DownloadType,
  WORKSPACE_DOWNLOAD_TYPES,
  getDialogOptions,
} from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { WorkspaceApiService } from '@core/services';
import { DialogComponent } from '@models';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-download-workspace-dialog',
  templateUrl: './download-workspace-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadWorkspaceDialogComponent implements DialogComponent {
  constructor(
    private _wsApi: WorkspaceApiService,
    private _notify: NotificationService
  ) {}

  downloads = WORKSPACE_DOWNLOAD_TYPES;

  static options(): MatDialogConfig {
    return getDialogOptions('xs');
  }

  download(downloadType: DownloadType): void {
    this._wsApi
      .downloadAndZipWorkspace(downloadType)
      .pipe(
        catchError(error =>
          this._notify.showError({
            message: error.error.message,
          })
        )
      )
      .subscribe();
  }
}
