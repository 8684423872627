import { IDomainModelDetails, IDomainModelResponse, Warning } from '@models';
import { RosettaConfig } from '@configs';

export interface UpgradeInfo {
  upgradeWarning: Warning;
  isProdVersion: boolean;
  upgradeVersion?: string;
}

// TODO: Review the upgrade logic especially for unsupported versions

/*
 Set Upgrade Warnings for a specific workspace

 Only allow upgrade to the same major version and same version label e.g x.x.x-dev.0 when available

 Use cases:
 1. Workspace version is the latest version available
  - No upgrade
  - No message

 2. Workspace version is NOT the latest version available
  - Allow upgrade
  - Message: Upgrade available

 3. Workspace version is NOT in the available version list but there are versions available
  - Allow upgrade
  - Message Unsupported

 4. Workspace version is NOT in the available version list and no versions available
  - No upgrade
  - No message
*/

export const setUpgradeWarning = (
  workspaceVersion: string,
  model?: IDomainModelDetails | IDomainModelResponse
): UpgradeInfo => {
  const modelVersions = model?.versions || [];
  const majorVersion = Number(workspaceVersion.match(/^\d+/)?.[0]);
  const patternProd = RosettaConfig.regex.prodVersionPattern(majorVersion);
  const patternDev = RosettaConfig.regex.devVersionPattern(majorVersion);
  const isProdVersion = patternProd.test(workspaceVersion);
  const pattern = isProdVersion ? patternProd : patternDev;
  const versions = modelVersions.filter(version => pattern.test(version));

  const upgradeInfo: UpgradeInfo = {
    upgradeWarning: 'none',
    isProdVersion,
  };

  if (model?.deprecationMessage) {
    return {
      ...upgradeInfo,
      upgradeWarning: 'unsupported',
    };
  }

  if (versions.length < 1 || workspaceVersion === versions[0]) {
    return upgradeInfo;
  }

  return {
    ...upgradeInfo,
    upgradeWarning: versions.includes(workspaceVersion) ? 'minor' : 'major',
    upgradeVersion: versions[0],
  };
};
