import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import { AppSelectors } from '@store/selectors';

@Component({
  standalone: true,
  imports: [CommonModule, FontsModule, MatButtonModule, MatTooltipModule],
  selector: 'app-refresh-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<button
    class="theme-bg-primary pr-16 pl-16"
    *ngIf="refreshRequired()"
    mat-button
    matTooltip="New app version available, click to update"
    (click)="onClick()"
  >
    <fa-icon
      animation="shake"
      icon="arrow-rotate-right"
      class="mr-8 theme-fa-icon"
    />
    <span>Refresh to <strong>Update</strong></span>
  </button>`,
  styles: [
    `
      :host {
        --fa-animation-duration: 3s;
      }
    `,
  ],
})
export class RefreshButtonComponent {
  constructor(private _store: Store) {}

  refreshRequired = toSignal(this._store.select(AppSelectors.refreshRequired));

  onClick(): void {
    this._store.dispatch(AppActions.appRefreshUpdate.refresh());
  }
}
