import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import * as WorkspaceActions from '@store/workspace/actions';
import { isNotNull } from '@utils';
import { first, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsToAcceptGuard {
  constructor(private _store: Store) {}

  /*
   * Checks that a user has documents to accepts,
   * otherwise loads last used workspace.
   */

  canActivate(): Observable<boolean> {
    return this._store.select(AuthSelectors.selectDocumentsToAccept).pipe(
      first(isNotNull),
      map(documents => documents.length > 0),
      tap(hasDocumentsToAccept => {
        if (!hasDocumentsToAccept) {
          this._store.dispatch(WorkspaceActions.loadLastWorkspace());
        }
      })
    );
  }
}
