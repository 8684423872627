import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ANIMATION_EASING_FAST } from '@configs';

export const FadeInAnimation = trigger('fadeIn', [
  state(
    'enter',
    style({ opacity: 1, transform: 'scale(1)', transformOrigin: 'top center' })
  ),
  state(
    'void, exit',
    style({
      opacity: 0,
      transform: 'scale(0.5)',
      transformOrigin: 'top center',
    })
  ),
  transition('* => enter', [
    style({ opacity: 0, transform: 'scale(0.5)' }),
    animate(
      ANIMATION_EASING_FAST,
      style({ opacity: 1, transform: 'scale(1)' })
    ),
  ]),
  transition('* => void, * => exit', [
    animate(
      ANIMATION_EASING_FAST,
      style({ opacity: 0, transform: 'scale(0.5)' })
    ),
  ]),
]);
