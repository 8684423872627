<ng-template #iconTmpl>
  <fa-icon
    *ngIf="iconData"
    [fixedWidth]="true"
    [icon]="iconData.icon"
    [style.color]="iconData.color"
    [matTooltip]="iconData.tooltip"
    [ngClass]="'upgrade-icon-small'"
    size="sm"
    class="mr-4"
  />
</ng-template>

<small class="mat-small">
  {{ modelId }} / {{ modelInfo?.modelVersion }}

  <ng-container *ngIf="showWarning">
    <span *ngIf="fullMessage; else iconTmpl" class="upgrade-icon-full warn-bg">
      <ng-container [ngTemplateOutlet]="iconTmpl" />
      <span class="font-weight-bold">{{ fullMessage }}</span>
    </span>
  </ng-container>
</small>
