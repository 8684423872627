import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { LanguageServerService } from '@core/services/language-server.service';
import { defaultConfig } from '../../models/code-view.config';
import { CodeView } from '../../models/code-view.model';
import { isEmptyString } from '@utils';

@Component({
  selector: 'app-code-view-native',
  templateUrl: './code-view-native.component.html',
  styleUrls: ['./code-view-native.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeViewNativeComponent implements OnDestroy {
  constructor(private _languageServerService: LanguageServerService) {}

  @ViewChild('editorContainer') editorContainer!: ElementRef;

  @Input() language: 'xml' | 'json' = 'json';
  @Input() set codeView(data: CodeView | null) {
    if (data) {
      this._setModel(data?.nativeCode);
    }
  }

  private _editor?: monaco.editor.IStandaloneCodeEditor;

  ngOnDestroy(): void {
    this._disposeEditor();
  }

  private _setModel(nativeCode: string): void {
    if (isEmptyString(nativeCode)) {
      if (this._editor?.getModel()) {
        this._editor.setModel(null);
      }
      return;
    }

    if (!this._editor) {
      this._initEditor(nativeCode);
      return;
    }

    if (this._editor.getModel()) {
      this._editor.getModel()?.setValue(nativeCode);
      return;
    }

    this._editor.setModel(monaco.editor.createModel(nativeCode, this.language));
  }

  private _initEditor(nativeCode: string | null = null): void {
    if (!nativeCode) {
      return;
    }

    this._languageServerService.loadMonaco().then(() => {
      const options: monaco.editor.IStandaloneEditorConstructionOptions = {
        language: this.language,
        ...defaultConfig,
      };
      this._editor = monaco.editor.create(
        this.editorContainer.nativeElement,
        options
      );

      this._editor.updateOptions({ readOnly: true });
      this._editor.setModel(
        monaco.editor.createModel(nativeCode, this.language)
      );
    });
  }

  private _disposeEditor(): void {
    if (this._editor) {
      this._editor.dispose();
    }
  }
}
