import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  countryList,
  institutionListTuples,
  rolesListTuples,
  usesListTuples,
} from '@features/auth/register/models/form.data';
import {
  UserRegistration,
  UserRegistrationCredentials,
} from '@features/auth/register/models/register.model';
import { LoadingState } from '@models';
import { deepEquals, mustContain } from '@utils';
import { BehaviorSubject, filter, first, map, merge, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileFormComponent implements OnDestroy {
  constructor(private _fb: UntypedFormBuilder) {}

  form!: UntypedFormGroup;

  @Output() save = new EventEmitter<UserRegistrationCredentials>();
  @Output() updatePassword = new EventEmitter<void>();

  @Input() set isSaving(state: LoadingState) {
    if (!state) {
      return;
    }

    this.isSavingState$.next(state);

    if (state === LoadingState.LOADING) {
      this._waitForSave();
    }
  }

  @Input() set user(data: UserRegistrationCredentials | undefined) {
    if (!data) {
      return;
    }
    this._user = data;
    this._init(data);
  }
  get user() {
    return this._user;
  }
  private _user: UserRegistrationCredentials | undefined;
  private _originalUserDetails: UserRegistration | undefined;
  private _sub = new Subscription();

  isSavingState$ = new BehaviorSubject(LoadingState.INITIAL);
  isSaving$ = this.isSavingState$.pipe(
    map(state => state === LoadingState.LOADING)
  );

  readonly otherFields = [
    'title',
    'role',
    'organizationName',
    'intendedUse',
  ] as const;
  readonly institutions = institutionListTuples;
  readonly roles = rolesListTuples;
  readonly uses = usesListTuples;
  readonly countries = countryList;
  readonly loadingState = LoadingState;

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  onUpdatePassword() {
    this.updatePassword.emit();
  }

  onSave() {
    this.save.emit(
      this._setOtherFieldsCase(Object.assign({}, this.user, this.form.value))
    );
  }

  hasFormChanged(): boolean {
    return (
      this.form &&
      deepEquals(this.form.getRawValue(), this._originalUserDetails)
    );
  }

  // TODO: This is a workaround for hardcoding on the server
  private _setOtherFieldsCase(
    data: UserRegistrationCredentials,
    toLowerCase = true
  ): UserRegistrationCredentials {
    this.otherFields.forEach(key => {
      if (data[key]?.toLowerCase() === 'other') {
        data[key] = toLowerCase ? 'other' : 'Other';
      }
    });
    return data;
  }

  private _init(user: UserRegistrationCredentials) {
    this._initForm(user);
    this._setOriginalUserDetails();
    this._changeListeners();

    // Mark all fields as touched so that errors are displayed immediately
    this.form.markAllAsTouched();
  }

  private _setOriginalUserDetails() {
    this._originalUserDetails = { ...this.form.getRawValue() };
  }

  private _changeListeners() {
    this._sub.add(
      merge(
        ...this.otherFields.map(field =>
          this.form.get(field)?.valueChanges.pipe(map(value => [field, value]))
        )
      ).subscribe(([field, value]) =>
        value.toLowerCase() === 'other'
          ? this._enableOtherField(this._getOtherField(field))
          : this._disableOtherField(this._getOtherField(field))
      )
    );
  }

  private _initForm(user: UserRegistrationCredentials) {
    this.form = this._fb.group({
      title: [user.title, Validators.required],
      titleOther: {
        value: user.titleOther,
        disabled: user.title.toLowerCase() !== 'other',
      },
      firstName: [user.firstName, Validators.required],
      surname: [user.surname, Validators.required],
      emailAddress: {
        value: user.emailAddress,
        disabled: true,
      },
      country: [user.country, Validators.required],
      state: [user.state],
      organizationName: [
        user.organizationName,
        [
          Validators.required,
          mustContain(this.institutions, ([_, key], value) => value === key),
        ],
      ],
      organizationNameOther: {
        value: user.organizationNameOther,
        disabled: user.organizationName?.toLowerCase() !== 'other',
      },
      role: [
        user.role,
        [
          Validators.required,
          mustContain(this.roles, ([_, key], value) => value === key),
        ],
      ],
      roleOther: {
        value: user.roleOther,
        disabled: user.role.toLowerCase() !== 'other',
      },
      jobTitle: [user.jobTitle, Validators.required],
      intendedUse: [
        user.intendedUse,
        [
          Validators.required,
          mustContain(this.uses, ([_, key], value) => value === key),
        ],
      ],
      intendedUseOther: {
        value: user.intendedUseOther,
        disabled: user.intendedUse.toLowerCase() !== 'other',
      },
    });
  }

  private _getOtherField(fieldName: string): string {
    return `${fieldName}Other`;
  }

  private _enableOtherField(fieldName: string) {
    const field = this.form.get(fieldName);
    field.enable();
    field.setValidators(Validators.required);
    field.updateValueAndValidity();
  }

  private _disableOtherField(fieldName: string) {
    const field = this.form.get(fieldName);
    field.disable();
    field.setValidators(null);
    field.updateValueAndValidity();
  }

  private _waitForSave() {
    this._sub.add(
      this.isSavingState$
        .pipe(
          filter(state => state !== LoadingState.LOADING),
          first(),
          filter(state => state !== LoadingState.ERROR)
        )
        .subscribe(() => this._setOriginalUserDetails())
    );
  }
}
