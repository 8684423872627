import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'kebabToHuman',
})
export class KebabToHumanPipe implements PipeTransform {
  static readonly dash = /-/g;

  transform(value: string): string {
    const words = value.toLowerCase().split(KebabToHumanPipe.dash);
    const upperWords = words.map(word => {
      return word[0].toUpperCase() + word.slice(1);
    });
    return upperWords.join(' ');
  }
}
