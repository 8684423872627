import { Injectable } from '@angular/core';
import { TaskService } from '@core/services';
import { APIExportResult, Task, TaskNotification, TaskStatus } from '@models';
import { ApiExportService } from '@workspace-engine/api-export/services/api-export.service';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class ExportListenerService implements TaskListeners {
  constructor(
    private _taskService: TaskService,
    private _apiExportService: ApiExportService
  ) {}

  private _exportTasks = [Task.IngestionExport];

  action(task: TaskNotification) {
    if (this._exportTasks.indexOf(task.task) < 0) {
      return;
    }

    if (task.status === 'STARTED' || task.status === 'FINISHED') {
      this._apiExportService.updateApiExport(task.payload);
    }

    if (task.status === 'FINISHED' || task.status === 'ERROR') {
      this._apiExportService.updateRunningStatus(false);
    }

    if (task.status === 'STARTED') {
      const apiExportStart = task.payload as APIExportResult;
      this._taskService.updateTaskStatus(
        task.task,
        apiExportStart.name,
        TaskStatus.Started
      );
    }

    if (task.status === 'FINISHED') {
      const apiExportFinish = task.payload as APIExportResult;
      this._taskService.updateTaskStatus(
        task.task,
        apiExportFinish.name,
        TaskStatus.Finished
      );
    }
  }
}
