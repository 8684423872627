import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { GaService } from './ga.service';

@NgModule({
  imports: [
    Ng2GoogleChartsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule.forRoot({ exclude: [/.*\/redirect\?.*/] }),
  ],
  providers: [GaService],
})
export class GaModule {}
