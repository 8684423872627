import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { FilterPipeFunc, IDomainModelDetails } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { FilteredListComponent } from '@shared/modules/filtered-list/filtered-list.component';
import { ProjectRowModule } from '@shared/modules/project-row/project-row.module';
import { MODEL_DATA$ } from '@shared/pipes';
import { Observable, first, map, shareReplay, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FilteredListComponent,
    MatCheckboxModule,
    ProjectRowModule,
  ],
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFilterComponent implements IFilterAngularComp {
  constructor(
    private _cdr: ChangeDetectorRef,
    @Inject(MODEL_DATA$)
    private _modelData$: Observable<Map<ModelInstanceId, IDomainModelDetails>>
  ) {
    this._setProjects();
  }

  domainModels$!: Observable<IDomainModelDetails[]>;
  params!: IFilterParams;
  filteredProjects = new Set<string>();

  private _allProjectIds: string[] = [];
  private _modelCount = -1;

  filterFunc: FilterPipeFunc<IDomainModelDetails> = search => {
    const regex = new RegExp(search, 'gi');
    return item => item?.id.match(regex) || false;
  };

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  doesFilterPass(params: IDoesFilterPassParams<{ projects: any[] }>): boolean {
    return !params.data.projects
      .map(p => p.id)
      .filter(id => this._allProjectIds.includes(id)) // filter out any projects that are not in the list of all projects
      .every(id => this.filteredProjects.has(id));
  }

  isFilterActive(): boolean {
    return this.filteredProjects.size > 0;
  }

  getModel(): string[] {
    return Array.from(this.filteredProjects.values());
  }

  setModel(models: string[] | null): void {
    if (models === null) {
      return;
    }
    this._allProjectIds.forEach(id => {
      if (!models.includes(id)) {
        this.filteredProjects.add(id);
      }
    });
    this._cdr.detectChanges();
    this._updateFilter();
  }

  projectToggle(domainModel: IDomainModelDetails): void {
    if (this.filteredProjects.has(domainModel.id)) {
      this.filteredProjects.delete(domainModel.id);
    } else {
      this.filteredProjects.add(domainModel.id);
    }
    this._updateFilter();
  }

  isChecked(domainModel: IDomainModelDetails): boolean {
    return !this.filteredProjects.has(domainModel.id);
  }

  masterToggle(event: MatCheckboxChange): void {
    if (event.checked) {
      this.filteredProjects.clear();
    } else {
      this._allProjectIds.forEach(id => this.filteredProjects.add(id));
    }
    this._updateFilter();
  }

  isAllSelected(): boolean {
    return this.filteredProjects.size === 0;
  }

  isAnySelected(): boolean {
    return (
      this.filteredProjects.size !== 0 &&
      this.filteredProjects.size < this._modelCount
    );
  }

  private _updateFilter(): void {
    this.params.filterChangedCallback();
  }

  private _setProjects(): void {
    this.domainModels$ = this._modelData$.pipe(
      first(),
      tap(dms => {
        this._modelCount = dms.size;
        this._allProjectIds = Array.from(dms.keys());
      }),
      map(dms => Array.from(dms.values())),
      shareReplay(1)
    );
  }
}
