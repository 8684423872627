import { Directive, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageServerService } from '@core/services/language-server.service';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { CustomParams, QueryParams, SymbolIdentifier } from '@models';
import { NodeNameType } from '../modules/code-view/models/code-view.model';

@Directive({
  standalone: true,
  selector: '[appGoToSymbol]',
})
export class GoToSymbolDirective {
  constructor(
    private _languageServerService: LanguageServerService,
    private _navigate: RosettaNavigationService,
    private _route: ActivatedRoute
  ) {}

  @Input() appGoToSymbol?: SymbolIdentifier | NodeNameType;

  @HostListener('click', ['$event'])
  goto(event: MouseEvent) {
    if (!this.appGoToSymbol) {
      return;
    }

    event.stopPropagation();
    const queryParams: CustomParams = {
      [QueryParams.VIEW]: 'textual',
    };
    this._navigate.to([], {
      queryParams,
      relativeTo: this._route,
      queryParamsHandling: 'merge',
    });

    this._languageServerService.goToSymbol(this.appGoToSymbol).subscribe();
  }
}
