import { AppDocuments } from '@models';
import { APP_DOCUMENTS } from './app.config';

export const InternalLink = {
  ADMIN_USER: '/settings/user-administration',
  PROJECT_ADMIN: '/settings/project-administration',
  AVAILABILITY_AND_SUPPORT: `/documents/${APP_DOCUMENTS}/${AppDocuments.AVAILABILITY_AND_SUPPORT}`,
  DOCUMENTS: '/documents',
  PASSWORD_RESET: '/password-reset',
  REGISTER: '/register',
  SUBSCRIPTION_FORM: `/documents/${AppDocuments.SUBSCRIPTION_FORM}`,
  SUPPORT: '/support',
  ERROR: '/error',
  TERMS_OF_SERVICE: `/documents/${APP_DOCUMENTS}/${AppDocuments.TERMS_OF_SERVICE}`,
  USER_PROFILE: '/settings/user-profile',
  WORKSPACE_MANAGER: '/workspace-manager',
  WORKSPACES: '/workspaces',
} as const;

export const ExternalLink = {
  DOCUMENTATION: 'https://docs.rosetta-technology.io/',
  PRICING_PAGE: 'https://rosetta-technology.io/pricing',
  PRIVACY_STATEMENT: `https://regnosys.com/regnosys-privacy-statement/`,
  STATUS: 'https://status.rosetta-technology.io/',
  WEBSITE: 'https://rosetta-technology.io/',
} as const;
