<button
  mat-icon-button
  matTooltip="Copy to clipboard"
  [cdkCopyToClipboard]="path"
  (cdkCopyToClipboardCopied)="onCopied($event)"
>
  <fa-icon
    *ngIf="iconData$ | async as data"
    [style.color]="data.color"
    [icon]="data.icon"
  />
</button>
