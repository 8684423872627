import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RenderTemplateDirective } from '@shared/directives';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appShowWhenWorkspace]',
})
export class ShowWhenWorkspaceDirective
  extends RenderTemplateDirective
  implements OnInit, OnDestroy
{
  private _sub = new Subscription();

  @Input('appShowWhenWorkspace') workspaceType!: 'read-only' | 'read-write';

  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._sub.add(
      this._store
        .select(WorkspaceSelectors.isReadonlyWorkspace)
        // eslint-disable-next-line @ngrx/no-store-subscription
        .subscribe(isReadOnly => {
          const shouldRender =
            this.workspaceType === 'read-only' ? isReadOnly : !isReadOnly;
          return this.render(shouldRender);
        })
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
