export const enumToList = <T extends object>(enumObject: T) =>
  Object.entries(enumObject)
    .filter(([key]) => isNaN(parseInt(key)))
    .map(([_, value]) => value);

export const fistEnumValue = <T extends object>(enumObject: T) =>
  Object.values(enumObject)[0];

// Reverse enum mapping where the keys are enum values and values are the enum keys for quick lookups
export function createEnumKeyValueMap<T extends Record<string, string>>(
  enumType: T
): Record<string, keyof T> {
  return Object.fromEntries(
    Object.entries(enumType).map(([key, value]) => [value, key as keyof T])
  );
}

export type EnumValues<T extends Record<string, string>> = T[keyof T];
