import { Injectable } from '@angular/core';

@Injectable()
export class EditorStateService {
  private _viewStates = new Map<string, monaco.editor.IEditorViewState>();

  save(editor: monaco.editor.IEditor, uri: string) {
    const viewState = editor.saveViewState();

    if (viewState && uri) {
      this._viewStates.set(this._createUri(editor, uri), viewState);
    }
  }

  restore(editor: monaco.editor.IEditor, uri: string): boolean {
    const viewState = this._viewStates.get(this._createUri(editor, uri));

    if (viewState) {
      editor.restoreViewState(viewState);
      editor.focus();
    }

    return viewState !== undefined;
  }

  clear() {
    this._viewStates.clear();
  }

  private _createUri(editor: monaco.editor.IEditor, uri: string) {
    return editor.getEditorType() + '//' + uri;
  }
}
