import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PATH_JOIN_CHAR_WITH_SPACE } from '@configs';
import { Subject } from 'rxjs';
import { FlatCodeViewNode, NodeNameType } from '../../models/code-view.model';

@Component({
  selector: 'app-code-view-selected',
  templateUrl: './code-view-selected.component.html',
  styleUrls: ['./code-view-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeViewSelectedComponent {
  @Input() modelContent = false;
  @Input()
  set node(data: FlatCodeViewNode | null) {
    this.node$.next(data);
    this.breadCrumbTrail = this.buildBreadCrumbTrail(data?.path);
  }
  node$ = new Subject<FlatCodeViewNode | null>();
  breadCrumbTrail!: string;

  buildBreadCrumbTrail(path: NodeNameType[] = []): string {
    return path.map(p => p.name).join(PATH_JOIN_CHAR_WITH_SPACE);
  }
}
