import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FontsModule } from '@app/fonts/fonts.module';
import { ThemeToggleComponent } from './theme-toggle.component';

@NgModule({
  declarations: [ThemeToggleComponent],
  imports: [CommonModule, FontsModule, MatSlideToggleModule],
  exports: [ThemeToggleComponent],
})
export class ThemeToggleModule {}
