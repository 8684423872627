<input
  #inputElement
  type="file"
  [multiple]="multiple"
  [accept]="accept"
  [formControl]="inputFormControl"
  (change)="onFileChange($event)"
/>
<button
  class="width-full height-full"
  mat-stroked-button
  (click)="inputElement.click()"
  [disabled]="isUploading || disabled"
  *appShowSpinner="!showSpinner || isUploading === false"
>
  <fa-icon class="mr-8" [icon]="icon" />
  <span>{{ label }}</span>
  <span class="projected-content text-ellipsis">
    <ng-content />
  </span>
</button>
