import { COLOR_DARK, COLOR_WHITE } from './../../config/colors.config';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { HEX } from '@models';
import { contrast } from '@utils';

const LIGHT_GREY: HEX = '#808080';

@Component({
  standalone: true,
  selector: 'app-chip-label',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '{{ value }}',
  styles: [
    `
      :host {
        display: inline;
        padding: 0 16px;
        border-radius: 12px;
        vertical-align: middle;

        font: 12px;
        line-height: 18px;
        font-weight: bold;
      }
    `,
  ],
  host: {
    class: 'chip-label',
  },
})
export class ChipLabelComponent {
  @Input() value: string | number = '';
  @Input() color: HEX = LIGHT_GREY;
  @Input() useLightForeground?: boolean;

  @HostBinding('style.background')
  get backgroundColorStyle() {
    return this.color;
  }

  @HostBinding('style.color')
  get colorStyle() {
    if (typeof this.useLightForeground === 'boolean') {
      return this.useLightForeground ? COLOR_WHITE : COLOR_DARK;
    }
    return contrast(this.color);
  }
}
