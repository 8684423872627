import { Injectable } from '@angular/core';
import { GridViewGenerationResult } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RosettaGridService {
  private _gridViewGeneration =
    new BehaviorSubject<GridViewGenerationResult | null>(null);

  onGridViewGenerationFinished(
    gridViewGenerationRunResult: GridViewGenerationResult
  ) {
    this._gridViewGeneration.next(gridViewGenerationRunResult);
  }

  onGridViewGenerationStarted() {
    // TOOD - do something with start
  }

  get gridViewGeneration$(): Observable<GridViewGenerationResult | null> {
    return this._gridViewGeneration.asObservable();
  }

  clearGridViewGeneration() {
    this._gridViewGeneration.next(null);
  }
}
