import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FileTreeNode } from '@models';
import { FileTreeComponent } from '@shared/modules/file-tree/file-tree/file-tree.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-tree-node[node]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './file-tree-node.component.html',
  styleUrls: ['../file-tree-node.base.scss', './file-tree-node.component.scss'],
  host: {
    class: 'file-tree-node',
  },
})
export class FileTreeNodeComponent implements OnInit, OnDestroy {
  constructor(
    public parent: FileTreeComponent,
    private _cdr: ChangeDetectorRef
  ) {}

  @Input() node!: FileTreeNode;

  @HostBinding('class') classAttribute!: string;

  private _sub = new Subscription();

  ngOnInit() {
    this._updateClasses();
    this._sub.add(
      this.parent.treeControl.modelChanged().subscribe(() => {
        this._updateClasses();
      })
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private _updateClasses() {
    this.classAttribute = `${
      this.parent.treeControl.isSelected(this.node) ? 'node-selected' : ''
    } ${'node-lock-state-' + this.node.lockState} ${
      this.parent.treeControl.hasError(this.node) ? 'node-error' : ''
    }`;
    this._cdr.markForCheck();
  }
}
