import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export const regulationTourSteps: IMdStepOption[] = [
  {
    stepId: 'regulation-1',
    anchorId: 'new-regulation.supported-model',
    title: 'New Regulation Panel Available',
    content: 'Use the new panel to get a complete overview of your model.',
    isAsync: true,
  },
];
