import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { FontsModule } from '@app/fonts/fonts.module';
import { HasAbilityDirective } from '@shared/directives';
import { RosettaOverlayModule } from '@shared/modules/rosetta-overlay/rosetta-overlay.modules';
import { ModelPipe } from '@shared/pipes';
import { ProjectLogoModule } from '../project-logo/project-logo.module';
import { ProjectTypeModule } from '../project-type/project-type.module';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { UpgradeIconModule } from '../upgrade-icon/upgrade-icon.module';
import { RecentMenuComponent } from './recent-menu/recent-menu.component';
import { WorkspaceRecentListComponent } from './workspace-recent-list/workspace-recent-list.component';
import { WorkspaceSettingsMenuComponent } from './workspace-settings-menu/workspace-settings-menu.component';

@NgModule({
  declarations: [
    WorkspaceRecentListComponent,
    RecentMenuComponent,
    WorkspaceSettingsMenuComponent,
  ],
  imports: [
    CommonModule,
    FontsModule,

    // UI Modules
    RosettaOverlayModule,
    ProjectLogoModule,
    ProjectTypeModule,
    UpgradeIconModule,

    // Material
    MatCheckboxModule,
    MatTableModule,
    MatButtonModule,

    // Standalone
    ModelPipe,
    HasAbilityDirective,
    RosettaMenuComponent,
  ],
  exports: [WorkspaceRecentListComponent, RecentMenuComponent],
})
export class RecentWorkspaceListModule {}
