import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationApiService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '@store/.';
import { catchError, concatMap, map, of } from 'rxjs';

import { NotificationActions } from './';

@Injectable()
export class NotificationEffects {
  constructor(
    private _actions$: Actions,
    private _apiService: NotificationApiService
  ) {}

  login$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(() => NotificationActions.loadNotifications())
    );
  });

  loadNotifications$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(NotificationActions.loadNotifications),
      concatMap(() =>
        this._apiService.load().pipe(
          map(notifications =>
            NotificationActions.loadNotificationsSuccess({
              notifications,
            })
          ),
          catchError((e: HttpErrorResponse) =>
            of(
              NotificationActions.loadNotificationsFailure({
                error: e.statusText,
              })
            )
          )
        )
      )
    );
  });

  dismissNotification$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(NotificationActions.dismissNotification),
      concatMap(({ id }) =>
        this._apiService.setProjectPlanToSeen().pipe(
          map(() => NotificationActions.dismissNotificationSuccess({ id })),
          catchError((e: HttpErrorResponse) =>
            of(
              NotificationActions.dismissNotificationFailure({
                error: e.statusText,
              })
            )
          )
        )
      )
    );
  });

  logout$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.logout),
      map(() => NotificationActions.logout())
    );
  });
}
