import { PipeTransform, Pipe } from '@angular/core';
import { isEmptyString, isString } from '@utils';

@Pipe({
  standalone: true,
  name: 'rosettaTitleCase',
})
export class RosettaTitleCasePipe implements PipeTransform {
  transform(str: string): string {
    if (!isString(str) || isEmptyString(str)) {
      return '';
    }
    return str
      .replace(/[-_]+/g, ' ') // Replace consecutive hyphens or underscores with a single space
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase letters
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/\b\w/g, char => char.toUpperCase()); // Uppercase the first letter of each word
  }
}
