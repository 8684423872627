import { ChangeDetectionStrategy, Component } from '@angular/core';
import { COLOR_DARKEST } from '@configs';
import { WorkspaceCommands } from '@models';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { combineLatest, filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-workspace-menu',
  templateUrl: './workspace-menu.component.html',
  styleUrls: ['./workspace-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'v2-style-wrapper',
  },
})
export class WorkspaceMenuComponent {
  constructor(private _store: Store) {}

  isLoading$ = this._store.select(WorkspaceSelectors.isWorkspaceLoading);
  workspaceInfo$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(filter(isNotNull));
  model$ = this.workspaceInfo$.pipe(
    switchMap(info =>
      this._store.select(WorkspaceSelectors.getDomainModel(info?.modelId))
    )
  );
  modelColor$ = combineLatest([
    this.isLoading$,
    this.model$.pipe(map(model => model?.primaryColor)),
  ]).pipe(
    map(([isLoading, primaryColor]) => {
      if (isLoading || !primaryColor) {
        return COLOR_DARKEST;
      }
      return primaryColor;
    })
  );

  onAction(action: WorkspaceCommands) {
    this._store.dispatch(WorkspaceActions.workspaceAction({ action }));
  }
}
