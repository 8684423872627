import { animate, style, transition, trigger } from '@angular/animations';
import { ANIMATION_EASING_MEDIUM } from '@configs';

export const SlideInAnimation = trigger('slideIn', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(100%)' }),
    animate(
      ANIMATION_EASING_MEDIUM,
      style({ opacity: 1, transform: 'translateX(0)' })
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }),
    animate(
      ANIMATION_EASING_MEDIUM,
      style({ opacity: 0, transform: 'translateX(100%)' })
    ),
  ]),
]);
