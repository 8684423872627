import { StatusResponse } from '@models';
import { createAction, props } from '@ngrx/store';

export const serverStatusResponse = createAction(
  '[Server Status] Read Only Status Request Response',
  props<{ statusResponse: StatusResponse; key: string }>()
);

export const startPollingServerStatus = createAction(
  '[Server Status] Start Polling Server Status'
);
export const stopPollingServerStatus = createAction(
  '[Server Status] Stop Polling Server Status'
);
