import { animate, style, transition, trigger } from '@angular/animations';
import { ANIMATION_EASING_MEDIUM } from '@configs';

export const SlideUpAnimation = trigger('slideUp', [
  transition(':enter', [
    style({ transform: 'translateY(20%)', visibility: 'hidden' }),
    animate(
      ANIMATION_EASING_MEDIUM,
      style({ transform: 'translateY(0%)', visibility: 'visible' })
    ),
  ]),
]);
