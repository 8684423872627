import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  NgModule,
} from '@angular/core';
import { ROLLBAR_CONFIG } from '@configs';
import Rollbar from 'rollbar';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private _rollbar: Rollbar) {}

  handleError(err: any): void {
    this._rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(ROLLBAR_CONFIG);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
})
export class RollbarModule {}
