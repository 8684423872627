import { APP_DOCUMENTS, RosettaConfig } from '@configs';
import { UserLoginResponse } from '@features/auth/login/models/login.model';
import { LoadingState, PackagePlan, UserDocumentMap } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import * as AuthReducers from '@store/reducers/auth.reducer';
import { isNotNull, stringToColour } from '@utils';
import { first, pipe } from 'rxjs';

import { getFormConfig } from './auth.selector.helper';

const selectAuthFeature = createFeatureSelector<AuthReducers.State>(
  AuthReducers.featureKey
);

const selectAuthUser = createSelector(
  selectAuthFeature,
  (state: AuthReducers.State) => state?.user || null
);

const selectDocumentAcceptState = createSelector(
  selectAuthFeature,
  (state: AuthReducers.State) => state.documentAcceptState.state
);

const selectUserInitials = createSelector(
  selectAuthFeature,
  state => state.userInitials
);

const generateUserColour = createSelector(selectAuthFeature, state =>
  stringToColour(state.user?.rosettaAuth?.rosettaUser.email)
);

const selectRosettaUser = createSelector(
  selectAuthUser,
  user => user?.rosettaAuth?.rosettaUser || null
);

const selectUserData = createSelector(
  selectRosettaUser,
  user => user?.metaData
);

const selectDocumentsToAccept = createSelector(selectAuthFeature, state => {
  if (!state.user || !state.userDocuments[APP_DOCUMENTS]) {
    return RosettaConfig.appDocuments;
  }
  return RosettaConfig.appDocuments.filter(
    documentName => !state.userDocuments[APP_DOCUMENTS][documentName]
  );
});

const isLoggedIn = createSelector(selectAuthUser, user => user !== null);

const selectUserId = createSelector(
  selectRosettaUser,
  rosettaUser => rosettaUser?.userId || null
);

const selectOwnedModelIds = createSelector(
  selectAuthFeature,
  state => state.ownedProjects
);

const selectUserDocuments = createSelector(
  selectAuthFeature,
  state => state.userDocuments
);

const selectUserDocument = (
  domainModel: ModelInstanceId | typeof APP_DOCUMENTS
) =>
  createSelector(
    selectUserDocuments,
    (userDocuments: UserDocumentMap) => userDocuments[domainModel] || {}
  );

const getPackagePlanDetails = createSelector(
  selectAuthFeature,
  (state: AuthReducers.State) => state?.userPackagePlanDetails || null
);

const getPackagePlan = createSelector(
  getPackagePlanDetails,
  userPackagePlanDetails => userPackagePlanDetails?.packagePlan || ''
);

const getUserProjectLimit = createSelector(
  selectAuthFeature,
  ({ userProjectLimit }) => userProjectLimit
);

const selectFormConfig = createSelector(getPackagePlanDetails, packagePlan => {
  return getFormConfig(packagePlan);
});

const isCommunityLicense = createSelector(
  getPackagePlanDetails,
  packagePlanDetails =>
    packagePlanDetails === null ||
    packagePlanDetails?.packagePlan === PackagePlan.Community
);

const selectErrorMessage = createSelector(
  selectAuthFeature,
  ({ loginState }) => loginState.errorMessage
);

const selectRegisterErrorMessage = createSelector(
  selectAuthFeature,
  ({ registerState }) => registerState.errorMessage
);

const selectRegisterState = createSelector(
  selectAuthFeature,
  ({ registerState }) => registerState.state
);

const isLoggingIn = createSelector(
  selectAuthFeature,
  state =>
    state.loginState.state === LoadingState.LOADING || state.user !== null
);

const selectApiToken = createSelector(
  selectAuthUser,
  user => user?.rosettaAuth?.apiToken || null
);

const waitToLoadUser = pipe(
  // timeout(DEFAULT_API_TIMEOUT),
  select<any, UserLoginResponse | null>(selectAuthUser),
  first(isNotNull)
);

export const AuthSelectors = {
  generateUserColour,
  getPackagePlan,
  getPackagePlanDetails,
  getUserProjectLimit,
  isCommunityLicense,
  isLoggedIn,
  isLoggingIn,
  selectApiToken,
  selectAuthFeature,
  selectAuthUser,
  selectDocumentAcceptState,
  selectDocumentsToAccept,
  selectErrorMessage,
  selectFormConfig,
  selectOwnedModelIds,
  selectRegisterErrorMessage,
  selectRegisterState,
  selectRosettaUser,
  selectUserData,
  selectUserDocument,
  selectUserDocuments,
  selectUserId,
  selectUserInitials,
  waitToLoadUser,
};
