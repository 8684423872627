import { Directive, ElementRef, Output } from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
} from 'rxjs';

@Directive({
  standalone: true,
  selector: 'input[appInputValue]',
})
export class InputValueDirective {
  constructor(private _elementRef: ElementRef<HTMLInputElement>) {}

  @Output() appInputValue = fromEvent(
    this._elementRef.nativeElement,
    'keyup'
  ).pipe(
    filter(event => event.target instanceof HTMLInputElement),
    debounceTime(250),
    distinctUntilChanged(),
    map(event => (event.target as HTMLInputElement).value)
  );
}
