import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_DOCUMENTS, RosettaConfig, StorageKey } from '@configs';
import { GaService } from '@core/modules/ga/ga.service';
import { RollbarService } from '@core/modules/rollbar/rollbar.module';
import { NotificationService } from '@core/modules/snack-bar';
import {
  AuthService,
  DomainModelApiService,
  LocalStorageService,
} from '@core/services';
import { IntercomService } from '@core/services/intercom.service';
import { RosettaAuth } from '@features/auth/login';
import { LoginApiService } from '@features/auth/login/services/login-api.service';
import { RegisterApiService } from '@features/auth/register/register-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';
import { AppActions, AuthActions } from '@store/.';
import * as RouterActions from '@store/router/router.actions';
import * as RouterSelectors from '@store/router/router.selector';
import * as WorkspaceActions from '@store/workspace/actions';
import { isNotNull } from '@utils';
import { CookieService } from 'ngx-cookie-service';
import Rollbar from 'rollbar';
import {
  catchError,
  exhaustMap,
  filter,
  first,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AppSelectors, AuthSelectors } from '../selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _router: Router,
    private _api: LoginApiService,
    private _registerApi: RegisterApiService,
    private _modelApi: DomainModelApiService,
    private _authService: AuthService,
    private _notify: NotificationService,
    private _intercomService: IntercomService,
    private _storageService: LocalStorageService,
    private _cookieService: CookieService,
    private _dialog: MatDialog,
    private _gaService: GaService,
    @Inject(RollbarService) @Optional() private _rollbar?: Rollbar
  ) {}

  login$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap(({ userCredentials }) =>
        this._api.login(userCredentials).pipe(
          map(user =>
            user && user.success
              ? AuthActions.loginSuccess({ user })
              : AuthActions.loginError({
                  message: user?.message || 'Login Failed',
                })
          ),
          catchError(() =>
            of(
              AuthActions.loginError({
                message: RosettaConfig.text.somethingWentWrong,
              })
            )
          )
        )
      )
    );
  });

  refreshUser$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.refreshUser),
      exhaustMap(() =>
        of(this._authService.userIsAuthenticated()).pipe(
          map(userIsAuthenticated => {
            if (!userIsAuthenticated) {
              throw new Error();
            }

            const userLoginResponse = this._authService.getLoginDetails();
            if (!userLoginResponse?.rosettaAuth) {
              throw new Error();
            }
            return userLoginResponse.rosettaAuth.accessToken;
          }),
          map(accessToken => AuthActions.loginWithToken({ accessToken })),
          catchError(() => of(AuthActions.loginFailure()))
        )
      )
    );
  });

  autoLogin$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.autoLogin, AuthActions.loginWithToken),
      exhaustMap(({ accessToken }) =>
        this._api.loginAccessToken(accessToken).pipe(
          first(),
          map(user =>
            user && user.success
              ? AuthActions.loginSuccess({ user })
              : AuthActions.loginFailure({ errorMessage: user.message })
          ),
          catchError(() => of(AuthActions.loginFailure()))
        )
      )
    );
  });

  loginSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loginSuccess, AuthActions.saveUpdatedPasswordSuccess),
      map(({ user }) => this._loginSuccessHandler(user.rosettaAuth)),
      mergeMap(() => [
        WorkspaceActions.loadWorkspaces(), // TODO: Remove this so that we only load workspaces when we need them
        AuthActions.loadOwnedModels(),
        AuthActions.checkInitNavigation(),
        AuthActions.checkRefreshRequired(),
      ])
    );
  });

  loginError$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AuthActions.loginError),
        tap(({ message }) => this._notify.showError({ message }))
      );
    },
    {
      dispatch: false,
    }
  );

  loginFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loginFailure),
      tap(({ errorMessage }) =>
        this._notify.showError({ message: errorMessage })
      ),
      map(() => AuthActions.cleanupSessionState())
    );
  });

  logout$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => this._api.logout().pipe(catchError(() => of(null)))),
      tap(() => this._notify.dismiss()),
      map(() => AuthActions.cleanupSessionState())
    );
  });

  checkRefreshRequired$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.checkRefreshRequired),
      switchMap(() =>
        this._store.select(AppSelectors.refreshRequired).pipe(first())
      ),
      filter(Boolean),
      map(() => AppActions.appRefreshUpdate.refresh())
    );
  });

  cleanupSessionState$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AuthActions.cleanupSessionState),
        tap(() => this._logoutHandler())
      );
    },
    { dispatch: false }
  );

  resetPassword$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap(({ userName }) => this._api.resetPassword(userName)),
      map(() => AuthActions.resetPasswordSuccess())
    );
  });

  checkUser$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.checkUserIsAuthenticated),
      switchMap(() =>
        this._store.select(AuthSelectors.isLoggedIn).pipe(first())
      ),
      filter(isLoggedIn => isLoggedIn && !this._authService.validSession()),
      tap(() =>
        this._notify.showWarning({
          message: RosettaConfig.text.sessionExpired,
        })
      ),
      map(() => AuthActions.logout())
    );
  });

  register$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.register),
      switchMap(({ request }) =>
        this._registerApi.register(request).pipe(
          map(response =>
            response.success
              ? AuthActions.registerSuccess({ registerUserResponse: response })
              : AuthActions.registerFailure({ registerUserResponse: response })
          ),
          catchError(() =>
            of(
              AuthActions.registerFailure({
                registerUserResponse: {
                  success: false,
                  message: RosettaConfig.text.somethingWentWrong,
                },
              })
            )
          )
        )
      )
    );
  });

  checkInitNavigation$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.checkInitNavigation),
      switchMap(() =>
        forkJoin([
          this._store
            .select(AuthSelectors.selectDocumentsToAccept)
            .pipe(first()),
          this._store
            .select(RouterSelectors.selectRouter) // We select the router state to ensure it has been loaded
            .pipe(first(isNotNull)),
        ])
      ),
      mergeMap(([documents, router]) => {
        const actions: Action[] = [AuthActions.checkInitNavigationSuccess()];

        if (documents.length > 0) {
          return [...actions, RouterActions.goto({ path: ['documents'] })];
        }

        if (!router.state.data?.ignoreLastUsedWorkspace) {
          return [...actions, WorkspaceActions.loadLastWorkspace()];
        }

        return actions;
      })
    );
  });

  loadOwnedModels$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loadOwnedModels),
      switchMap(() => this._modelApi.getOwned()),
      map(modelIds => AuthActions.loadOwnedModelsSuccess({ modelIds }))
    );
  });

  acceptedUpdatedAppDocuments$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.acceptedUpdatedAppDocuments),
      switchMap(() =>
        this._store.select(AuthSelectors.selectDocumentsToAccept).pipe(
          first(),
          switchMap(documents =>
            this._api.acceptDocuments({ [APP_DOCUMENTS]: documents as any })
          ),
          mergeMap(() => [
            AuthActions.acceptedUpdatedAppDocumentsSuccess(),
            WorkspaceActions.loadLastWorkspace(),
          ]),
          catchError((e: HttpErrorResponse) =>
            of(
              AuthActions.acceptedUpdatedAppDocumentsFailure({
                errorMessage: e.message,
              })
            )
          )
        )
      )
    );
  });

  private _loginSuccessHandler({ rosettaUser }: RosettaAuth): void {
    this._notify.dismiss();
    this._intercomService.load(rosettaUser);
    this._gaService.init(rosettaUser);
    this._rollbar?.configure({
      payload: {
        id: rosettaUser.authId,
      },
    });
  }

  private _logoutHandler(): void {
    this._storageService.clear();
    this._intercomService.stop();
    this._cookieService.delete(StorageKey.AccessToken);
    this._dialog.closeAll();
    this._router.navigate(['/']);
  }
}
