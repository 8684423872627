import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FileTreeNode } from '@models';
import { debounceTime, merge } from 'rxjs';

export class FileTreeControl extends NestedTreeControl<FileTreeNode, string> {
  constructor(
    getChildren: (dataNode: FileTreeNode) => FileTreeNode[] | undefined
  ) {
    super(getChildren, {
      trackBy: dataNode => dataNode.id,
    });
  }

  private _selectionModel = new SelectionModel<string>(true);
  private _errorModel = new SelectionModel<string>(true);
  private _nodeBranchMap = new Map<string, FileTreeNode[]>();

  setNodes(nodes: FileTreeNode[]) {
    this.dataNodes = nodes;
    this._createNodeBranchMap(nodes);
  }

  selectNodeByUri(uri: string) {
    // Find the node with the given uri
    const nodeList = this._nodeBranchMap.get(uri);

    // If we found the node, select it
    if (nodeList) {
      this._selectionModel.clear();
      this._selectionModel.select(
        ...nodeList.map(value => this._trackByValue(value))
      );
      this._expandAncestors(nodeList);
    }
  }

  setNodeErroredByUris(uris: string[]) {
    const erroredNodes: FileTreeNode[] = [];
    // Find the nodes with the given uris
    uris.forEach(uri => {
      const nodeList = this._nodeBranchMap.get(uri);
      if (nodeList) {
        erroredNodes.push(...nodeList);
      }
    });

    // If we found the nodes, select them
    this._errorModel.clear();
    this._errorModel.select(
      ...erroredNodes.map(value => this._trackByValue(value))
    );
  }

  isSelected(node: FileTreeNode) {
    return this._selectionModel.isSelected(this._trackByValue(node));
  }

  hasError(node: FileTreeNode) {
    return this._errorModel.isSelected(this._trackByValue(node));
  }

  clearData() {
    this._selectionModel.clear();
    this._errorModel.clear();
    this.expansionModel.clear();
    this._nodeBranchMap.clear();
  }

  modelChanged() {
    return merge(this._selectionModel.changed, this._errorModel.changed).pipe(
      debounceTime(150)
    );
  }

  private _expandAncestors(nodes: FileTreeNode[]) {
    // If the node has a parent, expand it and expand its parent
    nodes.forEach(node => node.children && this.expand(node));
  }

  private _createNodeBranchMap(nodes: FileTreeNode[]) {
    const traverse = (node: FileTreeNode, path: FileTreeNode[] = []) => {
      if (node) {
        path.push(node);
      }

      if (node.children) {
        node.children.forEach(item => {
          traverse(item, path.slice());
        });
      }
      if (node.uri) {
        this._nodeBranchMap.set(node.uri, path);
      }
    };

    nodes.forEach(node => traverse(node));
  }
}
