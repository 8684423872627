import {
  DefaultUrlSerializer,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';

export const createUrl = (path = '', options: { ws?: boolean } = {}): URL => {
  const url = new URL(path, location.origin);
  if (options.ws) {
    url.protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
  }
  return url;
};

export const collectUrlTreeSegments = (url: string): UrlSegment[] => {
  const serializer = new DefaultUrlSerializer();
  const urlTree = serializer.parse(url);
  const segments: UrlSegment[] = [];

  (function mergeParamsFromSnapshot(group: UrlSegmentGroup) {
    const primary = group.children['primary'];

    if (primary) {
      segments.push(...primary.segments);
      mergeParamsFromSnapshot(primary);
    }
  })(urlTree.root);

  return segments;
};

type Join<T extends string[]> = T extends [
  infer First extends string,
  ...infer Rest extends string[],
]
  ? Rest extends []
    ? First
    : `${First}/${Join<Rest>}`
  : '';

export const sanitizeUrlPaths = <T extends string[]>(...paths: T): Join<T> => {
  return paths.map(path => encodeURIComponent(path)).join('/') as Join<T>;
};
