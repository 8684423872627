import { animate, style, transition, trigger } from '@angular/animations';
import { ANIMATION_EASING_MEDIUM } from '@configs';

export const InOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ height: 0, opacity: 0, overflow: 'hidden' }),
    animate(ANIMATION_EASING_MEDIUM, style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ height: '*', opacity: 1, overflow: 'hidden' }),
    animate(ANIMATION_EASING_MEDIUM, style({ height: 0, opacity: 0 })),
  ]),
]);
