import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CustomParams, QueryParams } from '@models';
import { redirectPathSanitizer } from '@store/effects/auth.effects.helper';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard {
  constructor(private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const pathParam = next.queryParams['path'];

    if (!pathParam) {
      return true;
    }

    const pathToPanel = pathParam.split('/').length > 3;
    const newPath = redirectPathSanitizer(pathParam);
    const queryParams: CustomParams = {
      [QueryParams.VIEW]: 'textual',
      [QueryParams.TOP_PANEL]: !pathToPanel,
      [QueryParams.BOTTOM_PANEL]: pathToPanel,
    };

    return this._router.createUrlTree(newPath, {
      queryParams,
    });
  }
}
