import { UiPanel } from './ui-panel.model';

export enum ServiceName {
  Static = 'STATIC',
  Rosetta = 'ROSETTA',
  Ingestion = 'INGESTION',
  Regulations = 'REGULATIONS',
  IngestActivation = 'INGEST_ACTIVATION',
  GridView = 'GRIDVIEW',
  Model = 'MODEL',
}

export class WorkspaceIdPanelStates {
  constructor(
    readonly workspaceId: string,
    readonly panelStates: PanelStates
  ) {}
}

export class PanelStates {
  constructor(states: Map<string, ServiceState[]>) {
    this._states = states;
  }

  private _states: Map<string, ServiceState[]>;

  public static none() {
    return new PanelStates(new Map());
  }

  public isPanelReady(panelName?: string, panel?: UiPanel): boolean {
    const dependencies = this._states.get(this._getName(panelName, panel));
    if (dependencies) {
      return dependencies
        .map(serviceState => serviceState.success)
        .reduce((acc, curr) => acc && curr);
    }
    return false;
  }

  public updatePanelStates(newState: ServiceState) {
    return new PanelStates(
      new Map(
        Array.from(this._states).map(
          ([key, value]: [string, ServiceState[]]) => [
            key,
            value.map(oldState =>
              newState.service === oldState.service ? newState : oldState
            ),
          ]
        )
      )
    );
  }

  /**
   * A proxy on whether the server is ready. If any panel is ready then the workspace should also be ready
   */
  public isAnyPanelReady(): boolean {
    return (
      Array.from(this._states.values())
        // a panel is ready if all of its dependencies are ready
        .map((sss: ServiceState[]) =>
          sss.every((ss: ServiceState) => ss.success)
        )
        .reduce((acc, curr) => acc || curr)
    );
  }

  private _getName(panelName?: string, panel?: UiPanel): string {
    if (panelName) {
      return panelName;
    }
    return panel ? panel.toLowerCase() : 'unknown';
  }
}

export class ServiceState {
  public static create(input: any): ServiceState {
    if (input !== 'undefined') {
      if (input.service && input.success !== 'undefined') {
        return new ServiceState(input.service, input.success);
      }
      throw new Error(
        'Unable to create ServiceState from input: ' + JSON.stringify(input)
      );
    } else {
      throw new Error('Unable to create ServiceSate from input: undefined');
    }
  }

  constructor(
    readonly service: string,
    readonly success: boolean
  ) {}
}
