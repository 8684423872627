import { Directive, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RenderTemplateDirective } from '@shared/directives';
import { AuthSelectors } from '@store/selectors';
import { first, tap } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appShowUpgradeOffer]',
})
export class ShowUpgradeOfferDirective
  extends RenderTemplateDirective
  implements OnInit
{
  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store
      .select(AuthSelectors.selectFormConfig)
      .pipe(
        first(),
        tap(({ offer }) => this.render(offer))
      )
      // eslint-disable-next-line @ngrx/no-store-subscription
      .subscribe();
  }
}
