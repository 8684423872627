import { Inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { DOCUMENT_FALLBACK } from '@configs';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import { first, map, Observable, zip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AllDocumentsAcceptedGuard {
  constructor(
    private _store: Store,
    @Inject(DOCUMENT_FALLBACK) private _fallbackRoute: UrlTree
  ) {}

  /*
   * Checks that a user has accepted all
   * Rosetta documents before letting them enter.
   */

  canActivateChild(): Observable<boolean | UrlTree> {
    return this._checkDocuments();
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this._checkDocuments();
  }

  private _checkDocuments(): Observable<boolean | UrlTree> {
    return zip(
      this._store.select(AuthSelectors.isLoggedIn),
      this._store.select(AuthSelectors.selectDocumentsToAccept)
    ).pipe(
      first(([isLoggedIn]) => isLoggedIn),
      map(([_, documents]) => {
        if (documents.length === 0) {
          return true;
        }
        return this._fallbackRoute;
      })
    );
  }
}
