import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export const editorTourSteps: IMdStepOption[] = [
  {
    stepId: 'editor-1',
    anchorId: 'rosetta-editor.document-format',
    title: 'Document Format',
    content: 'Automatically formats the current document.',
    isAsync: true,
  },
  {
    stepId: 'editor-2',
    anchorId: 'rosetta-editor.expand',
    title: 'Collapse / Expand',
    content:
      'Condense all top level code blocks to make it easier to scan files.',
    isAsync: true,
  },
  {
    stepId: 'editor-3',
    anchorId: 'rosetta-editor.dropdown',
    title: 'Editor Options',
    content:
      'Set editor preferences which will be used each time you login, open options to see whats new.',
    isAsync: true,
    nextOnAnchorClick: true,
  },
];
