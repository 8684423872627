import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import * as recentWorkspacesReducer from './recent-workspaces.reducers';

const selectFeature = createFeatureSelector<recentWorkspacesReducer.State>(
  recentWorkspacesReducer.featureKey
);

export const selectRecentWorkspaces = createSelector(
  selectFeature,
  WorkspaceSelectors.selectWorkspaceId,
  (state, workspaceId) => {
    const list = state.data.filter(
      workspace => workspace.id.name !== workspaceId?.name
    );
    return list.length ? list : null;
  }
);
