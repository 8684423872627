import { UserCredentials, UserLoginResponse } from '@features/auth/login';
import {
  IRegisterUserResponse,
  UserRegistrationCredentials,
} from '@features/auth/register/models/register.model';
import { IModelDocument } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Auth Service] Login',
  props<{ userCredentials: UserCredentials }>()
);
export const loginSuccess = createAction(
  '[Auth Service] Login Success',
  props<{ user: UserLoginResponse }>()
);
export const loginFailure = createAction(
  '[Auth Service] Login Failure',
  (payload: { errorMessage?: string } = {}) => payload
);
export const refreshUser = createAction('[Auth Service] Refresh User');

export const saveUpdatedPasswordSuccess = createAction(
  '[Auth Service] Save Updated Password Success',
  props<{ user: UserLoginResponse }>()
);
export const loginError = createAction(
  '[Auth Service] Login Error',
  props<{ message: string }>()
);

export const autoLogin = createAction(
  '[Auth Service] Auto Login',
  props<{ accessToken: string }>()
);

export const loginWithToken = createAction(
  '[Auth Service] Login With Token',
  props<{ accessToken: string }>()
);

export const logout = createAction('[Auth Service] Logout');

export const cleanupSessionState = createAction(
  '[Auth Service] Cleanup Session State'
);

export const resetPassword = createAction(
  '[Auth Service] Reset Password',
  props<{ userName: string }>()
);
export const resetPasswordSuccess = createAction(
  '[Auth Service] Reset Password Success'
);

export const checkUserIsAuthenticated = createAction(
  '[Auth Service] Check User Is Authenticated'
);

export const register = createAction(
  '[Auth Service] Register',
  props<{ request: UserRegistrationCredentials }>()
);
export const registerSuccess = createAction(
  '[Auth Service] Register Success',
  props<{ registerUserResponse: IRegisterUserResponse }>()
);
export const registerFailure = createAction(
  '[Auth Service] Register Failure',
  props<{ registerUserResponse: IRegisterUserResponse }>()
);
export const registerReset = createAction('[Auth Service] Register Reset');

export const loadOwnedModels = createAction('[Auth Service] Load Owned Models');
export const loadOwnedModelsSuccess = createAction(
  '[Auth Service] Load Owned Models Success',
  props<{ modelIds: string[] }>()
);

export const checkRefreshRequired = createAction(
  '[Auth Service] Check Refresh Required'
);

export const checkInitNavigation = createAction(
  '[Auth Service] Check Init Navigation'
);
export const checkInitNavigationSuccess = createAction(
  '[Auth Service] Check Init Navigation Success'
);

export const acceptedUpdatedAppDocuments = createAction(
  '[Auth Service] Accept Updated App Documents'
);
export const acceptedUpdatedAppDocumentsSuccess = createAction(
  '[Auth Service] Accept Updated App Documents Success'
);
export const acceptedUpdatedAppDocumentsFailure = createAction(
  '[Auth Service] Accept Updated App Documents Failure',
  props<{ errorMessage: string }>()
);

export const acceptModelDocumentsSuccess = createAction(
  '[Auth Service] Accept Model Documents Success',
  props<{ modelId: ModelInstanceId; documents: IModelDocument[] }>()
);
