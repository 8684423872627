<mat-toolbar appBannerise>
  <button
    class="small-button-icon mr-8"
    mat-icon-button
    mat-dialog-close
    tabindex="-1"
  >
    <fa-icon icon="times" />
  </button>

  <h2>Issue Tracker</h2>
</mat-toolbar>

<div class="content">
  <mat-card appearance="outlined" class="theme-bg">
    <mat-card-actions>
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        aria-label="Issue Tracker Options"
      >
        <mat-button-toggle checked value="tracker"
          >Open Issues</mat-button-toggle
        >
        <mat-button-toggle value="resolved">Resolved Issues</mat-button-toggle>
        <mat-button-toggle value="form">Issue Submit</mat-button-toggle>
      </mat-button-toggle-group>
      <a
        mat-stroked-button
        href="https://airtable.com/shrFPVYrrJ0QjlCX2/tbl6AgtP7ohkUodIh"
        target="_blank"
        rel="noopener"
      >
        <fa-icon icon="external-link-alt" />
        See full Issue Tracker
      </a>
    </mat-card-actions>
    <mat-card-content>
      <div [hidden]="group.value !== 'tracker'">
        <iframe
          #tracker
          title="Issue Tracker"
          class="airtable-embed"
          src="https://airtable.com/embed/shrUClvGGcqSQFJUn?backgroundColor=cyan&layout=card&viewControls=on"
          onmousewheel=""
          width="100%"
          height="533"
          style="background: transparent; border: 1px solid #ccc"
          loading="lazy"
        ></iframe>
      </div>
      <div [hidden]="group.value !== 'resolved'">
        <iframe
          title="Issue Tracker Resolved"
          class="airtable-embed"
          src="https://airtable.com/embed/shrQVXvUbI5Ogyh92?backgroundColor=cyan&layout=card&viewControls=on"
          onmousewheel=""
          width="100%"
          height="533"
          style="background: transparent; border: 1px solid #ccc"
          loading="lazy"
        ></iframe>
      </div>
      <div [hidden]="group.value !== 'form'">
        <iframe
          #form
          title="Issue Tracker Form"
          class="airtable-embed"
          src="https://airtable.com/embed/shrJM6FWeBbL4KrqO?backgroundColor=cyan"
          onmousewheel=""
          width="100%"
          height="533"
          style="background: transparent; border: 1px solid #ccc"
          loading="lazy"
        ></iframe>
      </div>
    </mat-card-content>
  </mat-card>
</div>
