import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { CanDeactivateCheck } from '../../models';
import * as AppAction from '../../store/actions';

@Injectable({
  providedIn: 'root',
})
export class SavingGuard {
  constructor(private _store: Store) {}

  canDeactivate(component: CanDeactivateCheck): Observable<boolean> {
    if (!component.canLeave) {
      throw new Error(
        'Route Component must implement CanDeactiveCheck to use SavingGuard!'
      );
    }
    return component.canLeave().pipe(
      tap(canNavigate => {
        if (!canNavigate) {
          this._store.dispatch(
            AppAction.showBasicWarningMsg({
              message: 'Unable to leave page while data is saving!',
            })
          );
        }
      })
    );
  }
}
