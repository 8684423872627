import { ColDef } from '@ag-grid-community/core';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { CellSampleNameComponent } from '../components/cell-sample-name/cell-sample-name.component';
import { cellClassRulesUtil } from '../utils/helpers';

export const SAMPLE_NAME_FIELD = 'sampleName';
export const sampleNameColDef = (): ColDef<transform.DataViewerRow> => ({
  field: SAMPLE_NAME_FIELD,
  headerName: 'Sample',
  pinned: 'left',
  flex: 1,
  minWidth: 210, // Width set to ensure inline actions are not cropped
  initialSort: 'asc',
  cellRenderer: CellSampleNameComponent,
  icons: {
    menu: `<i class="cursor-pointer ag-icon ag-icon-filter" />`,
  },
  filter: true,
  filterParams: {
    filterOptions: ['contains', 'notContains'],
    maxNumConditions: 1,
  },
  tooltipValueGetter: ({ data }) => data?.errorMessage ?? '',
  cellClassRules: cellClassRulesUtil(),
});
