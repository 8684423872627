// ?NOTE - This is a CONST enum which means that it's not available at runtime https://www.typescriptlang.org/docs/handbook/enums.html#const-enums
import { IconProp } from '@fortawesome/fontawesome-svg-core';
export const enum WorkspaceType {
  User = 'USER',
  Project = 'PROJECT',
  Contribution = 'CONTRIBUTION',
  Guest = 'GUEST',
}

export const WorkspaceTypeIconMap: Record<WorkspaceType, IconProp> = {
  [WorkspaceType.User]: 'user',
  [WorkspaceType.Project]: 'building',
  [WorkspaceType.Contribution]: 'code-merge',
  [WorkspaceType.Guest]: 'eye',
};
