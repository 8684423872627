import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelLogoPipe } from '@shared/pipes';
import { ProjectLogoComponent } from './project-logo.component';

@NgModule({
  declarations: [ProjectLogoComponent],
  imports: [CommonModule, ModelLogoPipe],
  exports: [ProjectLogoComponent],
})
export class ProjectLogoModule {}
