import { LoadingState } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as actions from './server-status.actions';

export const featureKey = 'serverStatus';

export interface State {
  serverStatus: Record<string, LoadingState>;
}

export const initialState: Readonly<State> = {
  serverStatus: {},
};

export const reducer = createReducer(
  initialState,
  on(actions.serverStatusResponse, (state, { statusResponse, key }) => ({
    ...state,
    serverStatus: {
      ...state.serverStatus,
      [key]: statusResponse.ReadinessCheck.healthy
        ? LoadingState.LOADED
        : LoadingState.ERROR,
    },
  }))
);
