import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { InOutAnimation } from '@shared/animations';

const EMPTY_VALUE = '';

@Component({
  selector: 'app-filter-field',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
  template: `
    <div
      class="flex-row-center small-button-wrapper"
      @inOutAnimation
      (@inOutAnimation.done)="animationDone()"
      *ngIf="opened"
    >
      <fa-icon class="fa-icon-button" icon="search" [fixedWidth]="true" />
      <input
        #searchInput
        matInput
        class="spacer"
        placeholder="{{ placeholder || 'Filter' }}"
        (appInputValue)="updateInputValue($event)"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
      <button
        class="mr-8"
        [style.visibility]="searchInput.value.length > 0 ? 'visible' : 'hidden'"
        mat-icon-button
        appStopPropagation
        (click)="clear()"
      >
        <fa-icon icon="times" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      button {
        padding: 11px;
      }

      input {
        border: 0;
        outline: none;
        background-color: transparent;
      }
    `,
  ],
  host: {
    class: 'filter-field',
  },
})
export class FilterFieldComponent {
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  @Input() opened = false;
  @Output() inputChanged = new EventEmitter<string>();

  @HostBinding('class.filter-field-opened') get openedClass() {
    return this.opened;
  }

  inputValue = EMPTY_VALUE;

  constructor(@Attribute('placeholder') public placeholder: string) {}

  updateInputValue(value: string) {
    this.inputValue = value;
    this.inputChanged.emit(value);
  }

  focusInput() {
    this.searchInput?.nativeElement.focus();
  }

  clear() {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = EMPTY_VALUE;
    }
    this.updateInputValue(EMPTY_VALUE);
    this.focusInput();
  }

  animationDone() {
    if (this.opened) {
      this.focusInput();
    } else {
      this.clear();
    }
  }
}
