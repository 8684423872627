export interface UserSession {
  id: string;
  email: string;
  eventTime: string;
  eventType: EventType;
  metaData: Record<string, any>;
}

export enum EventType {
  login = 'LOGIN',
  logout = 'LOGOUT',
  logoutBrowserClose = 'LOGOUT_BROWSER_CLOSE',
  logoutWorkspaceClose = 'LOGOUT_WORKSPACE_CLOSE',
  workspaceOpen = 'WORKSPACE_OPEN',
  workspaceClose = 'WORKSPACE_CLOSE',
}

export const eventMappings = {
  [EventType.login]: 'Active',
  [EventType.workspaceClose]: 'Active',
  [EventType.workspaceOpen]: 'Workspace',
  [EventType.logout]: 'Inactive',
  [EventType.logoutBrowserClose]: 'Inactive',
  [EventType.logoutWorkspaceClose]: 'Inactive',
};

export const activeEventTypes = [EventType.login, EventType.workspaceClose];
export const inactiveEventTypes = [
  EventType.logout,
  EventType.logoutBrowserClose,
  EventType.logoutWorkspaceClose,
];
