import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';
import { CustomRouteReuseStrategy } from '@app/app-routing-reuse-strategy';
import { SUPPORT_VIEW } from '@configs';
import {
  ActiveServerGuard,
  AuthGuardFunc,
  LoginGuard,
  PermissionGuardFunc,
  RedirectGuard,
} from '@core/guards';
import { NavbarComponent } from '@core/modules/navbar/pages/navbar.component';
import { AllDocumentsAcceptedGuard } from '@features/auth/login';
import { CustomRoutes } from '@models';
import { LandingComponent } from '@shared/modules/landing/landing.component';

const routes: CustomRoutes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: LandingComponent,
    canActivateChild: [LoginGuard, ActiveServerGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./features/auth/login/login-page.module').then(
            m => m.LoginPageModule
          ),
      },
      {
        path: 'password-reset',
        loadChildren: () =>
          import('./features/auth/password-reset/password-reset.module').then(
            m => m.PasswordResetModule
          ),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./features/auth/register/register.module').then(
            m => m.RegisterModule
          ),
      },
    ],
  },

  // Public
  {
    path: 'redirect',
    title: 'Redirect - Rosetta',
    canActivate: [LoginGuard, RedirectGuard],
    component: LandingComponent,
  },
  {
    path: 'error',
    title: 'Error Page - Rosetta',
    loadChildren: () =>
      import('./features/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: 'documents',
    title: 'Documents - Rosetta',
    loadChildren: () =>
      import('./features/documents/documents.module').then(
        m => m.DocumentsModule
      ),
  },

  // Authenticated Routes
  {
    path: '',
    canLoad: [AuthGuardFunc],
    canActivateChild: [AllDocumentsAcceptedGuard],
    component: NavbarComponent,
    children: [
      {
        path: 'support',
        title: 'Rosetta',
        canLoad: [PermissionGuardFunc],
        data: {
          abilityName: SUPPORT_VIEW,
          ignoreLastUsedWorkspace: true,
        },
        loadChildren: () =>
          import('./features/support/support.module').then(
            m => m.SupportModule
          ),
      },
      {
        path: 'settings',
        title: 'Settings - Rosetta',
        data: { ignoreLastUsedWorkspace: true },
        canLoad: [AuthGuardFunc],
        loadChildren: () =>
          import('./features/settings/settings.module').then(
            m => m.SettingsModule
          ),
      },
      {
        path: 'workspace-manager',
        title: 'Workspace Manager - Rosetta',
        data: { ignoreLastUsedWorkspace: true },
        canLoad: [AuthGuardFunc],
        pathMatch: 'full',
        loadChildren: () =>
          import('./features/workspace-manager/workspace-manager.module').then(
            m => m.WorkspaceManagerModule
          ),
      },
      {
        path: 'workspaces/:workspaceName',
        title: 'Workspace - Rosetta',
        data: { ignoreLastUsedWorkspace: true },
        canLoad: [AuthGuardFunc],
        loadChildren: () =>
          import('./features/workspace/workspace.module').then(
            m => m.WorkspaceModule
          ),
      },
    ],
  },

  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
