<app-info-panel panelTitle="Let's go.">
  <div class="flex-space-between">
    <h1 class="mat-h1">Your account</h1>
    <a
      class="mat-small theme-color-primary font-text-link"
      [routerLink]="internalLink.PASSWORD_RESET"
      >Forgotten password?</a
    >
  </div>

  <form class="flex-column" [formGroup]="loginForm">
    <mat-form-field class="mb-16" color="accent">
      <mat-label>Email address</mat-label>
      <input
        matInput
        name="email"
        formControlName="username"
        autocomplete="email"
        required
        autofocus
      />
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Password</mat-label>
      <input
        matInput
        name="password"
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        autocomplete="password"
        required
      />

      <button
        type="button"
        mat-icon-button
        matIconSuffix
        (click)="hidePassword = !hidePassword"
      >
        <fa-icon
          class="theme-color-primary"
          [icon]="['fas', hidePassword ? 'eye-slash' : 'eye']"
        />
      </button>
    </mat-form-field>

    <app-link-with-arrow
      [disabled]="loginForm.invalid"
      [isLoading]="isLoggingIn$ | async"
      (click)="login()"
      >Sign in</app-link-with-arrow
    >
  </form>

  <div info-panel-footer>
    Not registered yet?
    <a
      class="theme-color-primary font-text-link"
      [routerLink]="internalLink.REGISTER"
      >Sign up for free.</a
    >
  </div>
</app-info-panel>
