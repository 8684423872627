export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export const isNotNull = <T>(item: T | null): item is NonNullable<T> =>
  item !== null && item !== undefined;

// Get Keys of an Object Where Values Are of a Given Type
// https://www.totaltypescript.com/get-keys-of-an-object-where-values-are-of-a-given-type
export type KeysOfValue<T, TCondition> = {
  [K in keyof T]: T[K] extends TCondition ? K : never;
}[keyof T];

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export const prefixResourcePaths = <
  TPrefix extends string,
  TObj extends Record<string, string>,
>(
  prefix: TPrefix,
  stringsObject: TObj
): { [K in keyof TObj]: `${TPrefix}/${TObj[K]}` } => {
  return Object.keys(stringsObject).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `${prefix}/${stringsObject[key].replaceAll(/^\/|\/$/g, '')}`,
    }),
    {} as { [K in keyof TObj]: `${TPrefix}/${TObj[K]}` }
  );
};
