import { inject } from '@angular/core';
import { StatusPollingService } from '@core/services/status-polling.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '@store/.';
import { tap } from 'rxjs';
import {
  startPollingServerStatus,
  stopPollingServerStatus,
} from './server-status.actions';

export const startPolling = createEffect(
  (actions$ = inject(Actions), service = inject(StatusPollingService)) => {
    return actions$.pipe(
      ofType(startPollingServerStatus),
      tap(() => {
        service.start();
      })
    );
  },
  { functional: true, dispatch: false }
);

export const stopPolling = createEffect(
  (actions$ = inject(Actions), service = inject(StatusPollingService)) => {
    return actions$.pipe(
      ofType(stopPollingServerStatus),
      tap(() => {
        service.stop();
      })
    );
  },
  { functional: true, dispatch: false }
);

export const cleanupSessionState = createEffect(
  (actions$ = inject(Actions), service = inject(StatusPollingService)) => {
    return actions$.pipe(
      ofType(AuthActions.cleanupSessionState),
      tap(() => {
        service.clear();
      })
    );
  },
  { functional: true, dispatch: false }
);
