import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { checkFileFactory } from '@app/utils/file-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UploadType } from '@models/domain-models';
import { LoadingSpinnerModule } from '@shared/modules';

@Component({
  standalone: true,
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FontsModule,
    ReactiveFormsModule,
    MatButtonModule,
    LoadingSpinnerModule,
  ],
  host: {
    class: 'file-upload',
  },
})
export class FileUploadComponent {
  @Input() multiple = false;
  @Input() accept = '.json';
  @Input() disabled = false;
  @Input() showSpinner = false;
  @Input() label = 'Upload';
  @Input() icon: IconProp = 'upload';
  @Input() isUploading = false;
  @Input() uploadType: UploadType = 'WEBSOCKET';

  @Output() fileDropped = new EventEmitter<FileList>();

  inputFormControl = new UntypedFormControl();

  private _notifyFileTooBig = checkFileFactory();

  onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (!target.files) {
      return;
    }

    if (
      !this._notifyFileTooBig(target.files, this.uploadType) &&
      target.files.length
    ) {
      this.fileDropped.emit(target.files);
    }

    this.reset();
  }

  reset(): void {
    this.inputFormControl.reset();
  }
}
