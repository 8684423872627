import { LoadingState } from '@models';
import { SupportReducers } from '@features/support/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectFeature = createFeatureSelector<SupportReducers.State>(
  SupportReducers.featureKey
);

const selectWorkspaceCopyState = createSelector(
  selectFeature,
  state => state.workspaceCopyState
);

const selectWorkspaceDownloadState = createSelector(
  selectFeature,
  state => state.workspaceDownloadState
);

const selectWorkspaceUploadState = createSelector(
  selectFeature,
  state => state.workspaceUploadState
);

const isCopyReadyToSubmit = createSelector(
  selectWorkspaceCopyState,
  copyState => copyState !== LoadingState.LOADING
);

const canTriggerDownload = createSelector(
  selectWorkspaceDownloadState,
  downloadState => downloadState !== LoadingState.LOADING
);

const isWorkspaceDownloading = createSelector(
  selectWorkspaceDownloadState,
  downloadState => downloadState === LoadingState.LOADING
);

const isWorkspaceUploading = createSelector(
  selectWorkspaceUploadState,
  uploadState => uploadState === LoadingState.LOADING
);

export const SupportSelectors = {
  selectFeature,
  selectWorkspaceCopyState,
  selectWorkspaceDownloadState,
  selectWorkspaceUploadState,
  isCopyReadyToSubmit,
  canTriggerDownload,
  isWorkspaceDownloading,
  isWorkspaceUploading,
};
