import { debounce as debounceFunc } from 'lodash-es';

export function debounce(milliseconds = 0, options = {}) {
  return function (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const map = new WeakMap();
    const originalMethod = descriptor.value;
    descriptor.value = function (...params: unknown[]) {
      let debounced = map.get(this);
      if (!debounced) {
        debounced = debounceFunc(originalMethod, milliseconds, options).bind(
          this
        );
        map.set(this, debounced);
      }
      debounced(...params);
    };
    return descriptor;
  };
}
