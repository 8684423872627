import { isDevMode } from '@angular/core';
import { MonoTypeOperatorFunction, tap } from 'rxjs';

/*
This is a debugging utility and should not be used in prod
*/
export function LoggingDebuggerOperator<T>(
  prefix?: string
): MonoTypeOperatorFunction<T> {
  prefix = prefix ? `${prefix}: ` : '';
  return tap(
    isDevMode()
      ? {
          // eslint-disable-next-line no-console
          next: value => console.log(`${prefix}Logging debugger NEXT: `, value),
          error: error =>
            // eslint-disable-next-line no-console
            console.log(`${prefix}Logging debugger ERROR: `, error),
          // eslint-disable-next-line no-console
          complete: () => console.log(`${prefix}Logging debugger COMPLETE`),
        }
      : undefined
  );
}
