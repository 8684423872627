import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import { AppSelectors } from '@store/selectors';

@Component({
  selector: 'app-workspace-settings-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'small-button-wrapper' },
  template: `
    <button class="ml-8" mat-icon-button [appRosettaOverlayTarget]="overlay">
      <fa-icon class="mr-0" icon="cog" />
    </button>

    <app-rosetta-overlay #overlay>
      <app-rosetta-menu>
        <mat-checkbox
          tabIndex="-1"
          (change)="togglePresenting()"
          [checked]="hideProprietaryModels$ | async"
          >Hide proprietary models
        </mat-checkbox>
      </app-rosetta-menu>
    </app-rosetta-overlay>
  `,
  styles: [
    `
      fa-icon {
        color: rgb(white, 0.25) !important;
      }
    `,
  ],
})
export class WorkspaceSettingsMenuComponent {
  private _store = inject(Store);
  hideProprietaryModels$ = this._store.select(
    AppSelectors.selectHideProprietaryModels
  );

  togglePresenting() {
    this._store.dispatch(AppActions.togglePresenting());
  }
}
