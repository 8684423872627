import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RosettaConfig } from '@configs';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull, WorkspaceUrlPrefixOperator } from '@utils';
import { concat, first, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  INGEST_CONFIG,
  PipelineDef,
  PipelineRunInfo,
} from '@shared/modules/transform/models';
import { TestPackGridSelection } from '@shared/modules/transform/models/test-pack-grid-selection.model';
import { TransformDTO } from '@shared/modules/transform/models/transform-dto.model';
import { runPipelineBuster$ } from './transform-server-storage.service';

@Injectable()
export class IngestApiService {
  constructor(
    private _store: Store,
    private _http: HttpClient
  ) {}

  private _generatedAndCompiledSynonymSourceMap = new Set<string>();
  private _baseResourceUrl$ = this._store.pipe(
    WorkspaceUrlPrefixOperator(RosettaConfig.resourcePaths.ingest)
  );

  preRunCheck(selection: TestPackGridSelection): Observable<PipelineRunInfo> {
    return this._store.select(WorkspaceSelectors.isReadonlyWorkspace).pipe(
      first(isNotNull),
      switchMap(isReadOnly => {
        if (isReadOnly) {
          // Always call endpoint in RO workspace
          return this._checkSynonymSourceCompiled(
            selection.pipelineDef.id
          ).pipe(
            switchMap(({ compiled }) =>
              this._handleSynonymSourceGeneration(compiled, selection)
            )
          );
        }

        // Use client session map in RW workspace
        // TODO: Optimise RW workspaces to call the endpoint
        return this._handleSynonymSourceGeneration(
          this._checkSynonymSourceMap(selection.pipelineDef.id),
          selection
        );
      })
    );
  }

  clearSynonymSourceMap(): void {
    this._generatedAndCompiledSynonymSourceMap.clear();
  }

  private _checkSynonymSourceMap(pipelineId: PipelineDef['id']): boolean {
    return this._generatedAndCompiledSynonymSourceMap.has(pipelineId);
  }

  private _updateSynonymSourceMap(pipelineId: PipelineDef['id']): void {
    this._generatedAndCompiledSynonymSourceMap.add(pipelineId);
  }

  private _handleSynonymSourceGeneration(
    synonymSourceCompiled: boolean,
    selection: TestPackGridSelection
  ): Observable<PipelineRunInfo> {
    if (synonymSourceCompiled) {
      return of({
        result: {
          data: null,
          details: {
            testPackGridSelection: selection,
            description: INGEST_CONFIG.COMPILATION_DESCRIPTION,
            currentPipeline: INGEST_CONFIG.COMPILATION_STEP,
            totalPipelines: INGEST_CONFIG.TOTAL_STEPS,
          },
        },
        errorMessage: null,
      });
    }

    return this._generateAndCompileSynonymSource(selection);
  }

  private _generateAndCompileSynonymSource(
    testPackGridSelection: TestPackGridSelection
  ): Observable<PipelineRunInfo> {
    const getPipelineRunResult = (
      currentPipeline: number,
      description: string,
      data: any = null
    ): PipelineRunInfo => ({
      result: {
        data,
        details: {
          testPackGridSelection,
          description,
          currentPipeline,
          totalPipelines: INGEST_CONFIG.TOTAL_STEPS,
        },
      },
      errorMessage: null,
    });

    return concat(
      // start generate
      of(
        getPipelineRunResult(
          INGEST_CONFIG.GENERATE_STEP,
          INGEST_CONFIG.GENERATE_DESCRIPTION
        )
      ),
      this._generateSynonymSource(testPackGridSelection.pipelineDef.id).pipe(
        switchMap(generateResponse =>
          concat(
            // End generate
            of(
              getPipelineRunResult(
                INGEST_CONFIG.GENERATE_STEP,
                INGEST_CONFIG.GENERATE_DESCRIPTION,
                true
              )
            ),
            // start compile
            of(
              getPipelineRunResult(
                INGEST_CONFIG.COMPILATION_STEP,
                INGEST_CONFIG.COMPILATION_DESCRIPTION
              )
            ),
            this._compileSynonymSource(
              testPackGridSelection.pipelineDef.id,
              generateResponse
            ).pipe(
              // End compile
              map(() =>
                getPipelineRunResult(
                  INGEST_CONFIG.COMPILATION_STEP,
                  INGEST_CONFIG.COMPILATION_DESCRIPTION,
                  true
                )
              ),
              // Update synonym source map after generate & compile finishes so that it doesn't get run again
              tap(() =>
                this._updateSynonymSourceMap(
                  testPackGridSelection.pipelineDef.id
                )
              )
            )
          )
        )
      )
    );
  }

  private _compileSynonymSource(
    pipelineId: PipelineDef['id'],
    generateResponse: TransformDTO.GeneratedSynonymSource
  ): Observable<void> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.post<TransformDTO.SynonymSourceCompilationResult>(
          `${url}/${pipelineId}/compile`,
          generateResponse
        )
      ),
      map(response => {
        if (response.success === false) {
          throw new Error(response.compileErrors.userMessage);
        }
        return null;
      })
    );
  }

  private _generateSynonymSource(
    pipelineId: PipelineDef['id']
  ): Observable<TransformDTO.GeneratedSynonymSource> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.GeneratedSynonymSource>(
          `${url}/${pipelineId}/generate`
        )
      )
    );
  }

  private _checkSynonymSourceCompiled(
    pipelineId: PipelineDef['id']
  ): Observable<TransformDTO.SynonymSourceState> {
    return this._baseResourceUrl$.pipe(
      switchMap(url =>
        this._http.get<TransformDTO.SynonymSourceState>(
          `${url}/${pipelineId}/check-synonym-source-compiled`
        )
      ),
      tap(({ compiled }) => {
        if (!compiled) {
          runPipelineBuster$.next();
        }
      })
    );
  }
}
