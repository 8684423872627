import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { InOutAnimation } from '@shared/animations';
import { deepEquals } from '@utils';
import {
  combineLatest,
  distinctUntilChanged,
  startWith,
  Subscription,
  tap,
} from 'rxjs';

import { IRosettaSelector } from './rosetta-selector.model';

@Component({
  selector: 'app-rosetta-selector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
  templateUrl: './rosetta-selector.component.html',
  styles: [
    `
      form {
        display: flex;
        column-gap: 0.5rem;
      }
    `,
  ],
  host: {
    class: 'rosetta-selector',
  },
})
export class RosettaSelectorComponent implements OnChanges, OnDestroy {
  @ViewChildren(MatSelect) selectInputs: QueryList<MatSelect>;

  constructor(private _fb: FormBuilder) {}

  private _sub = new Subscription();

  form = this._fb.group({
    selectors: this._fb.array<FormControl<string | null>>([]),
  });

  // TODO: Refactor this component to take in a single Rosetta selector rather than a list
  @Input() selectors!: IRosettaSelector[];
  @Output() update = new EventEmitter<unknown[]>();

  get formSelectors() {
    return this.form.controls.selectors.controls;
  }

  ngOnChanges({ selectors }: SimpleChanges) {
    if (selectors?.currentValue) {
      this._setupForm(selectors.currentValue);
      this._setupSelectorChangeListeners(selectors.currentValue);
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  onOpenChanged(isOpen: boolean, index: number) {
    const selector = this.selectors[index];
    selector.onToggle(isOpen);
  }

  onValueChanged(value: string, index: number) {
    const selector = this.selectors[index];
    selector.applyOnChange(value);

    /* SetTime is a workaround for the selectInputs retrieving the old options component */
    setTimeout(() => {
      /* After setting new value get selector components */
      const currentSelectorComponent = this.selectInputs.get(index);
      const nextSelectorComponent = this.selectInputs.get(index + 1);

      if (!nextSelectorComponent) {
        return;
      }

      /* Deselect current */
      this._blur(currentSelectorComponent);
      this._handleEmptySelection(nextSelectorComponent);
    }, 200);
  }

  private _blur(selectorComponent: MatSelect): void {
    selectorComponent.panel?.nativeElement.blur();
    selectorComponent.close();
  }

  private _handleEmptySelection(selectorComponent: MatSelect): void {
    if (selectorComponent.options.length === 1) {
      /* Auto select when single item */
      selectorComponent.options.first.select();
    } else {
      /* Select and open selector  */
      selectorComponent.focus();
      selectorComponent.open();
      selectorComponent.panel?.nativeElement.click();
    }
  }

  private _setupSelectorChangeListeners(selectors: IRosettaSelector[]) {
    this._sub.add(
      combineLatest(
        selectors.map(selector =>
          selector.selectedValue$.pipe(startWith(undefined))
        )
      )
        .pipe(
          distinctUntilChanged(deepEquals),
          tap(changeEvent => this.update.emit(changeEvent))
        )
        .subscribe()
    );

    this._sub.add(
      this.update.subscribe((selectedValues: any[]) =>
        this._handleSelections(selectedValues)
      )
    );
  }

  private _handleSelections(selectedValues: any[]): void {
    /* SetTime is a workaround for the selectInputs retrieving the old options component */
    setTimeout(() => {
      selectedValues.forEach((selectedValue, index) => {
        const selector = this.selectInputs?.get(index);
        if (selector) {
          !selectedValue
            ? this._handleEmptySelection(selector)
            : this._blur(selector);
        }
      });
    }, 200);
  }

  private _setupForm(selectors: IRosettaSelector[]) {
    this.form.controls.selectors.clear();
    selectors.forEach(selector => {
      const control = this._fb.control<string | null>({
        value: null,
        disabled: false,
      });
      selector.registerControl(control);
      return this.form.controls.selectors.push(control);
    });
  }
}
