import {
  MonacoToProtocolConverter,
  ProtocolToMonacoConverter,
  MonacoCommands,
  MonacoLanguages,
  MonacoWorkspace,
  ConsoleWindow,
  Disposable,
  DisposableCollection,
  Services,
  FormattingOptions,
  MonacoServices,
} from 'monaco-languageclient';
import { languages } from 'monaco-editor-core';
import { FORMATTING_CONFIG } from '@configs';

declare const monaco: any;

/**
 * A customization of `MonacoServices.install` to replace the
 * MonacoToProtocolConverter with our custom implementation.
 */
export const rosettaServicesInstall = (
  options: MonacoServices.Options = {}
): Disposable => {
  const disposableCollection = new DisposableCollection();

  // Use our custom `MonacoToProtocolConverter` to insert the
  // `maxLineWidth` formatting option.
  const m2p = new MonacoToRosettaProtocolConverter(monaco);
  const p2m = new ProtocolToMonacoConverter(monaco);
  const services: MonacoServices = {
    commands: new MonacoCommands(monaco),
    languages: new MonacoLanguages(monaco, p2m, m2p),
    workspace: new MonacoWorkspace(monaco, p2m, m2p, options.rootUri),
    window: new ConsoleWindow(),
  };
  disposableCollection.push(services.workspace);
  disposableCollection.push(Services.install(services));

  return disposableCollection;
};

class MonacoToRosettaProtocolConverter extends MonacoToProtocolConverter {
  override asFormattingOptions(
    options: languages.FormattingOptions
  ): FormattingOptions {
    const lspFormattingOptions = super.asFormattingOptions(options);
    lspFormattingOptions['maxLineWidth'] = FORMATTING_CONFIG.maxLineLength;
    lspFormattingOptions['conditionalMaxLineWidth'] =
      FORMATTING_CONFIG.conditionalMaxLineWidth;
    return lspFormattingOptions;
  }
}
