import { HttpClient } from '@angular/common/http';
import { catchError, Observable, OperatorFunction } from 'rxjs';

export function deleteHttpPostFallback<T>(
  http: HttpClient,
  body?: any
): OperatorFunction<T, T> {
  return (source: Observable<T>) =>
    source.pipe(
      catchError(error => {
        if (error.status === 403 || error.status === 405) {
          return http.post<T>(error.url + '/delete', body);
        }
        throw error;
      })
    );
}
