export const isElementVerticallyInView = (
  element: Element,
  parent: Element
): boolean => {
  const elementRect = element.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  const isVerticalInView =
    elementRect.top >= parentRect.top &&
    elementRect.bottom <= parentRect.bottom;

  return isVerticalInView;
};
