import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  animateChild,
  group,
} from '@angular/animations';
import { ANIMATION_EASING_FAST } from '@configs';

export const fullLogoAnimation = trigger('fullLogoAnimation', [
  state(
    'full, animate-full',
    style({
      width: '*',
    })
  ),
  state(
    'logo, animate-logo',
    style({
      width: '21px',
    })
  ),
  transition('* => animate-logo, * => animate-full', [
    group([
      query('@stoneAnimation', animateChild()),
      animate(ANIMATION_EASING_FAST),
    ]),
  ]),
]);

export const stoneAnimation = trigger('stoneAnimation', [
  state(
    'full, animate-full',
    style({
      marginLeft: 0,
    })
  ),
  state(
    'logo, animate-logo',
    style({
      marginLeft: '-18px',
    })
  ),
  transition('* => animate-logo, * => animate-full', [
    animate(ANIMATION_EASING_FAST),
  ]),
]);
