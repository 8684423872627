import { UserPermissions } from '@features/auth/login';
import { ApplicationPermission, PackagePlan } from '@models';

export const ALPHA_ACCESS = 'ros.alpha.access';
export const BETA_ACCESS = 'ros.beta.access';
export const CREATE_NAMESPACE_SYNO = 'ros.create-namespace.syno';
export const CREATE_WORKSPACE_VERSION = 'ros.create-workspace.version';
export const ENGINE_ACCESS = 'ros.engine.access';
export const PRESENTER_TOGGLE = 'ros.presenter.toggle';
export const PROJECT_ADMIN = 'ros.project-admin.view';
export const SUPPORT_VIEW = 'ros.support.access';
export const USER_ADMIN_ACCESS = 'ros.user-admin.access';

export type UserAbilities =
  | typeof ALPHA_ACCESS
  | typeof BETA_ACCESS
  | typeof CREATE_NAMESPACE_SYNO
  | typeof CREATE_WORKSPACE_VERSION
  | typeof ENGINE_ACCESS
  | typeof PRESENTER_TOGGLE
  | typeof PROJECT_ADMIN
  | typeof SUPPORT_VIEW
  | typeof USER_ADMIN_ACCESS;

export const permissionsMapper = ({
  modelPermissions,
  applicationPermissions,
  packagePlan,
}: UserPermissions): Set<UserAbilities> => {
  const OWNER_SUFFIX = '_owner';
  const abilities = new Set<UserAbilities>();
  const has = (permission: ApplicationPermission) => {
    return applicationPermissions.includes(permission);
  };
  const addPermissions = (permissions: Array<[UserAbilities, boolean]>) => {
    permissions.forEach(
      ([permission, enabled]) => enabled && abilities.add(permission)
    );
  };
  const minProPackagePlan = [PackagePlan.Pro, PackagePlan.Enterprise].includes(
    packagePlan
  );
  const minEssentialPackagePlan = [
    PackagePlan.Essentials,
    PackagePlan.Pro,
    PackagePlan.Enterprise,
  ].includes(packagePlan);

  addPermissions([
    [ALPHA_ACCESS, has(ApplicationPermission.AlphaAccessUser)],
    [BETA_ACCESS, has(ApplicationPermission.BetaAccessUser)],
    [CREATE_NAMESPACE_SYNO, minProPackagePlan],
    [CREATE_WORKSPACE_VERSION, minEssentialPackagePlan],
    [ENGINE_ACCESS, minEssentialPackagePlan],
    [PRESENTER_TOGGLE, has(ApplicationPermission.RosettaPresenter)],
    [SUPPORT_VIEW, has(ApplicationPermission.RosettaSupport)],
    [USER_ADMIN_ACCESS, has(ApplicationPermission.RosettaSupport)],
    /// TODO: Remove this once we have moved the owned projects to the permission model
    [
      PROJECT_ADMIN,
      modelPermissions.some(modelPermission =>
        modelPermission.endsWith(OWNER_SUFFIX)
      ),
    ],
  ]);

  return abilities;
};
