import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ANIMATION_EASING_MEDIUM } from '@configs';

export const OpenCloseAnimation = trigger('openCloseAnimation', [
  state('open', style({ height: '*', opacity: 1 })),
  state('closed', style({ height: 0, opacity: 0, display: 'none' })),
  transition('closed => open', [
    query('h5', style({ top: 0 }), { optional: true }),
    style({ overflow: 'hidden', display: 'initial' }),
    animate(ANIMATION_EASING_MEDIUM, style({ height: '*', opacity: 1 })),
  ]),
  transition('open => closed', [
    query('h5', style({ top: 0 }), { optional: true }),
    style({ overflow: 'hidden' }),
    animate(ANIMATION_EASING_MEDIUM, style({ height: 0, opacity: 0 })),
  ]),
]);
