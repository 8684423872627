import { formatDate } from '@angular/common';
import { LOCALE } from '@configs';
import {
  compareAsc,
  differenceInCalendarDays,
  formatDistanceToNow,
  isValid,
} from 'date-fns';

export const getDaysRemaining = (
  targetDate: string | null | undefined
): number => {
  let numberDays = 0;

  if (targetDate) {
    numberDays = differenceInCalendarDays(new Date(targetDate), new Date());

    if (isNaN(numberDays) || numberDays < 1) {
      numberDays = 0;
    }
  }

  return numberDays;
};

export const futureDate = (target: Date | string | null | undefined) => {
  if (!target) {
    return false;
  }
  const targetDate = new Date(target);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return compareAsc(targetDate, currentDate) > -1;
};

export const displayDateFormatter = (
  date: number | string | Date | null | undefined,
  formatStrings = 'mediumDate'
): string => {
  if (!date || !isValid(new Date(date))) {
    return null;
  }
  return formatDate(date, formatStrings, LOCALE);
};

export const distanceFromNowInWords = (
  date: Date | number | string
): string => {
  if (!date || !isValid(new Date(date))) {
    return null;
  }
  return formatDistanceToNow(date, { addSuffix: true });
};
