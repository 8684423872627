<section class="sub-page-header">
  <div class="max-width-container">
    <h1>{{ title }}</h1>
    <h3>{{ subtitle }}</h3>
  </div>
</section>

<main>
  <router-outlet />
</main>
