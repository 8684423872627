export enum QueryParams {
  VIEW = 'view',
  TOP_PANEL = 'topPanel',
  BOTTOM_PANEL = 'bottomPanel',
}

const ALL_VIEW_OPTIONS = [
  'textual',
  'definition',
  'graphical',
  'regulation',
] as const;
type ViewOptionsTuple = typeof ALL_VIEW_OPTIONS;
export type ViewOptions = ViewOptionsTuple[number];

export function isViewOption(value: string): value is ViewOptions {
  return ALL_VIEW_OPTIONS.includes(value as ViewOptions);
}
