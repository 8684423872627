import { RecentWorkspace } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as actions from './recent-workspaces.actions';
import { updateRecentlyViewedWorkspaceList } from './recent-workspaces.helpers';

export const featureKey = 'recentWorkspaces';

export interface State {
  data: RecentWorkspace[];
}

export const initialState: Readonly<State> = {
  data: [],
};

export const reducer = createReducer(
  initialState,
  on(actions.addRecentWorkspace, (state, { add }) => ({
    data: updateRecentlyViewedWorkspaceList(state.data, add, null),
  })),

  on(actions.removeRecentWorkspace, (state, { remove }) => ({
    data: updateRecentlyViewedWorkspaceList(state.data, null, remove),
  })),
  on(actions.checkRecentWorkspaces, (state, { modelIds }) => ({
    data: state.data.filter(recentWorkspace =>
      modelIds.some(id => id === recentWorkspace.modelInfo.modelId)
    ),
  }))
);
