import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { combineLatest, first, map, switchMap, tap } from 'rxjs';

import { CodeViewSearchService } from './code-view-search.service';

@Component({
  selector: 'app-code-view-search',
  templateUrl: './code-view-search.component.html',
  styleUrls: ['./code-view-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeViewSearchComponent {
  constructor(private _searchService: CodeViewSearchService) {}

  @Output() changes = new EventEmitter<string>();

  searchTerm = '';

  numberResult$ = this._searchService.current().pipe(
    switchMap(searchItem =>
      combineLatest([
        this._searchService.position(),
        this._searchService.count(),
      ]).pipe(
        map(([position, count]) => (count ? `${position} of ${count}` : null)),
        tap(() => this._change(searchItem?.key))
      )
    )
  );

  scrollToCurrent() {
    this._searchService
      .current()
      .pipe(first())
      .subscribe(searchItem => this._change(searchItem?.key));
  }

  search(searchTerm: string) {
    this.searchTerm = searchTerm;
    this._searchService.search(searchTerm);
  }

  next() {
    this._searchService.next();
  }

  prev() {
    this._searchService.prev();
  }

  clear() {
    this.search('');
    this.searchTerm = '';
  }

  private _change(value: string) {
    if (value) {
      this.changes.emit(value);
    }
  }
}
