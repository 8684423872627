import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotesViewerDialogComponent } from '@core/modules/release-notes/release-notes-viewer-dialog/release-notes-viewer-dialog.component';
import { ReleaseNoteDialogService } from '@core/modules/release-notes/services/release-notes-dialog.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DialogActions from '@store/actions/dialog.actions';
import { map, tap } from 'rxjs';

@Injectable()
export class DialogEffects {
  constructor(
    private _actions$: Actions,
    private _releaseNoteDialogService: ReleaseNoteDialogService,
    private _dialog: MatDialog
  ) {}

  openDialog$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(DialogActions.openReleaseNoteDialog),
        tap(() =>
          this._dialog.open(
            ReleaseNotesViewerDialogComponent,
            ReleaseNotesViewerDialogComponent.options()
          )
        )
      );
    },
    { dispatch: false }
  );

  openModelDialog$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DialogActions.openModelReleaseNoteDialog),
      tap(({ modelId }) =>
        this._releaseNoteDialogService.getModelReleaseNotes(modelId)
      ),
      map(() => DialogActions.openReleaseNoteDialog())
    );
  });

  getCoreReleaseNotes$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DialogActions.openAppReleaseNoteDialog),
      tap(() => this._releaseNoteDialogService.getCoreReleaseNotes()),
      map(() => DialogActions.openReleaseNoteDialog())
    );
  });
}
