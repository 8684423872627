<header>
  <mat-toolbar class="mat-elevation-z8 theme-bg-darkest">
    <div class="content-wrap">
      <ng-container appViewContainer />
    </div>

    <app-logo
      mode="logo"
      [showInfo]="true"
      [enableAnimationHover]="true"
      [forceTheme]="appThemes.LIGHT"
    />

    <div class="spacer"></div>

    <app-refresh-button class="mr-4" />

    <button
      class="theme-bg-10-invert"
      mat-button
      *appShowUpgradeOffer
      appClickToOpenUpgradeDialog
    >
      <fa-icon icon="tag" class="theme-fa-icon-accent" />
      <span class="theme-color-accent font-uppercase"
        >Limited <strong>offer</strong></span
      >
    </button>

    <div class="menu">
      <app-help-menu />
      <app-notification-menu />
      <app-user-menu />
    </div>
  </mat-toolbar>
</header>

<section>
  <router-outlet />
</section>
