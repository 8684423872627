import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { WorkspaceApiService } from '@core/services';
import { SupportApiService } from '@features/support/services/support-api.service';
import { debounceTime, first, map, switchMap } from 'rxjs';

export const duplicateWorkspaceName = (
  wsApi: WorkspaceApiService,
  DEBOUNCE_TIME = 300
): AsyncValidatorFn => {
  return control =>
    control.valueChanges.pipe(
      debounceTime(DEBOUNCE_TIME),
      first(),
      switchMap(value => wsApi.workspaceExists(value)),
      map(exists => (exists ? { workspaceExists: true } : null))
    );
};

export const duplicateWorkspaceNameForUser = (
  wsApi: SupportApiService,
  fieldNames: {
    getUserField: (control: AbstractControl) => string;
    getWorkspaceField: (control: AbstractControl) => string;
  }
): AsyncValidatorFn => {
  return control =>
    wsApi
      .workspaceExistsForUser(
        fieldNames.getUserField(control),
        fieldNames.getWorkspaceField(control)
      )
      .pipe(map(exists => (exists ? { workspaceExists: true } : null)));
};
