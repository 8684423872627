import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ENGINE_CHILDREN_ROUTE_DATA } from '@features/workspace/workspace-routing.module';
import { CustomRoutes } from '@models';
import { TRANSFORM_ROUTE_PARAMS } from './models/transform.const';
import { TransformDetailsComponent } from './pages/transform-details/transform-details.component';
import { TransformListComponent } from './pages/transform-list/transform-list.component';

const routes: CustomRoutes = [
  {
    path: '',
    component: TransformListComponent,
    data: ENGINE_CHILDREN_ROUTE_DATA,
  },
  {
    path: `:${TRANSFORM_ROUTE_PARAMS.pipelineId}/:${TRANSFORM_ROUTE_PARAMS.testPackId}/:${TRANSFORM_ROUTE_PARAMS.sampleId}`,
    component: TransformDetailsComponent,
    data: ENGINE_CHILDREN_ROUTE_DATA,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransformRoutingModule {}
