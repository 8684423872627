import { inject } from '@angular/core';
import { Task } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TaskActions } from '@store/.';
import { filter, tap } from 'rxjs';
import { TransformService } from '../services/transform.service';
import { IngestApiService } from '@shared/modules/transform/services/ingest-api.service';
import { runPipelineBuster$ } from '../services/transform-server-storage.service';

export const clearTransformCache = createEffect(
  (actions = inject(Actions), apiService = inject(IngestApiService)) => {
    return actions.pipe(
      ofType(TaskActions.taskStarted),
      filter(({ name }) => name === Task.ExecutionEngineInitialisation),
      tap(() => {
        apiService.clearSynonymSourceMap();
        runPipelineBuster$.next();
      })
    );
  },
  { dispatch: false, functional: true }
);

export const runTransformData = createEffect(
  (actions = inject(Actions), service = inject(TransformService)) => {
    return actions.pipe(
      ofType(TaskActions.taskFinished),
      filter(({ name }) => name === Task.ExecutionEngineInitialisation),
      tap(() => service.rerun())
    );
  },
  { dispatch: false, functional: true }
);
