<button mat-button [appRosettaOverlayTarget]="overlay">
  <fa-icon icon="caret-down" size="sm" />
  <span class="mat-h2">Workspace Manager</span>
  <app-workspace-settings-menu *appHasAbility="'ros.presenter.toggle'" />
</button>

<app-rosetta-overlay #overlay>
  <app-rosetta-menu>
    <app-workspace-recent-list />
  </app-rosetta-menu>
</app-rosetta-overlay>
