import { READ_ONLY_PREFIX } from '@configs';
import {
  IDomainModelDetails,
  IDomainModelResponse,
  IModelDocument,
  SelectedWorkspaceItemId,
  UserDocumentMap,
  WorkspaceGroup,
  WorkspaceInfo,
  WorkspaceItemState,
  WorkspaceMenuItem,
  WorkspaceType,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { stringToColour } from '@utils';
import { groupBy } from 'lodash-es';
import {
  createWorkspaceItemId,
  generateModelNameFromResponse,
} from '../reducers/workspace.reducers.helper';
import { setUpgradeWarning } from '../workspace.helper';

export const domainModelMapper = (
  domainModels: IDomainModelResponse[],
  userDocuments: UserDocumentMap
): IDomainModelDetails[] => {
  return domainModels
    .filter(model => !model.fixedVersionId)
    .map(model => ({
      id: model.id,
      name: model.name,
      shortname: model?.shortname || model.id.substring(0, 3).toUpperCase(),
      primaryColor: model?.primaryColor || stringToColour(model.id),
      versions: model.versions,
      documents: model.documents,
      accepted: Object.values(model.documents).every(documents =>
        documents.every(
          document =>
            userDocuments[document.modelId] &&
            userDocuments[document.modelId][document.documentId]
        )
      ),
      ...(model.proprietary !== undefined
        ? { proprietary: model.proprietary }
        : {}),
      ...(model.deprecationMessage
        ? { deprecationMessage: model.deprecationMessage }
        : {}),
      ...(model.links ? { links: model.links } : {}),
    }));
};

export const mapToWorkspaceGroups = (
  domainModels: IDomainModelResponse[]
): WorkspaceGroup[] => {
  const grouped = groupBy(
    domainModels.filter(domainModel => domainModel.readOnlyAvailable),
    'id'
  );

  return Object.values(grouped).map(group => ({
    modelId: group[0].id,
    workspaces: group.map(g => ({
      name: `${READ_ONLY_PREFIX}-${generateModelNameFromResponse(g.id, g.fixedVersionId)}`,
      userId: 'system',
    })),
  }));
};

export const createWorkspaceMenuItems = (
  workspaceInfo: WorkspaceInfo,
  model?: IDomainModelDetails | IDomainModelResponse
): WorkspaceMenuItem => {
  const { upgradeWarning, upgradeVersion, isProdVersion } = setUpgradeWarning(
    workspaceInfo.modelVersion,
    model
  );

  return {
    id: workspaceInfo.id,
    displayName: workspaceInfo.id.name,
    modelId: workspaceInfo.modelId,
    modelName: workspaceInfo.modelName,
    modelVersion: workspaceInfo.modelVersion,
    modelShortname: model?.shortname || workspaceInfo.modelId,
    fixedVersionId: workspaceInfo.fixedVersionId,
    owner: workspaceInfo.owner,
    name: workspaceInfo.name,
    readOnly: workspaceInfo.readOnly,
    corrupt: workspaceInfo.corrupt,
    creationDate: workspaceInfo.creationDate,
    developmentVersion: !isProdVersion,
    disabled: false,
    isDeleted: false,
    workspaceType: WorkspaceType.User,
    upgradeWarning,
    ...(model?.deprecationMessage
      ? { deprecationMessage: model.deprecationMessage }
      : {}),
    ...(!workspaceInfo.readOnly && upgradeVersion
      ? { canUpgrade: upgradeVersion }
      : {}),
  };
};

export const createDocumentIdMap = (
  modelDocuments: IModelDocument[]
): Record<string, string[]> => {
  return modelDocuments.reduce<Record<string, string[]>>((acc, doc) => {
    acc[doc.modelId] = (acc[doc.modelId] || []).concat(doc.documentId);
    return acc;
  }, {});
};

export const workspaceNameFormatted = (workspaceInfo: WorkspaceInfo | null) => {
  const workspaceName = workspaceNameIfNotAvailable(workspaceInfo);
  return `${
    workspaceInfo !== null ? workspaceName : workspaceName.toLowerCase()
  }.`;
};

export const workspaceNameIfNotAvailable = (
  workspaceInfo: WorkspaceInfo | null
) => {
  if (workspaceInfo === null) {
    return 'The workspace';
  }
  return workspaceInfo.description;
};

export const getModelInitialFileId = (
  items: WorkspaceItemState[] | null,
  modelId?: ModelInstanceId
): SelectedWorkspaceItemId | undefined => {
  const itemInfo = (items || []).find(
    ({ info }) => info.modelName === modelId && !info.name.includes('synonym')
  )?.info;
  return createWorkspaceItemId(itemInfo);
};
