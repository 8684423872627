import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { rewriteDynamicUrlPath } from '@core/interceptors/helper-interceptor';
import { StatusResponse, WorkspaceMenuItem } from '@models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getCoreVersion(): Observable<string> {
    return this._http.get(`${this._config.resourcePaths.statusCore}/version`, {
      responseType: 'text',
    });
  }

  getStatus(info?: WorkspaceMenuItem): Observable<StatusResponse> {
    const url = info
      ? rewriteDynamicUrlPath(this._config.resourcePaths.status, info)
      : this._config.resourcePaths.statusCore;
    return this._http.get<StatusResponse>(url);
  }
}
