import { Directive, OnInit } from '@angular/core';
import { DAYS_REMAINING_THRESHOLD } from '@configs';
import { PackagePlanDetails } from '@features/auth/login';
import { Store } from '@ngrx/store';
import { RenderTemplateDirective } from '@shared/directives';
import { AuthSelectors } from '@store/selectors';
import { getDaysRemaining, isNotNull } from '@utils';
import { first, tap } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appTrialEndingSoon]',
})
export class TrialEndingSoonDirective
  extends RenderTemplateDirective
  implements OnInit
{
  constructor(private _store: Store) {
    super();
  }

  ngOnInit() {
    this._store
      .select(AuthSelectors.getPackagePlanDetails)
      .pipe(
        first(isNotNull),
        tap(packagePlanDetails =>
          this.render(this._isTrialEndingSoon(packagePlanDetails))
        )
      )
      // eslint-disable-next-line @ngrx/no-store-subscription
      .subscribe();
  }

  private _isTrialEndingSoon(packagePlanDetails: PackagePlanDetails): boolean {
    return (
      packagePlanDetails.trial &&
      getDaysRemaining(packagePlanDetails.endDate) <= DAYS_REMAINING_THRESHOLD
    );
  }
}
