<div
  *ngIf="logoName | modelLogo | async as url; else shortnameTemplate"
  class="wrapper wrapper__logo"
>
  <img [src]="url" loading="lazy" />
</div>

<ng-template #shortnameTemplate>
  <div class="wrapper wrapper__shortname" [ngStyle]="shortnameStyle">
    <h3 class="text-ellipsis">{{ data?.shortname || 'N/A' }}</h3>
  </div>
</ng-template>
