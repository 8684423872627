import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-curve-top-svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<svg width="100%" viewBox="0 0 737 236">
    <path
      fill="#EF4E9E"
      d="M0.689941 0C126.229 75.6694 238.158 143.456 297.97 180.441C463.047 282.458 532.137 224.156 596.217 155.959C627.632 122.537 683.301 60.2456 736.637 0H0.689941Z"
    />
  </svg>`,
})
export class CurveTopComponent {}
