import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export const transformTourSteps: IMdStepOption[] = [
  {
    stepId: 'transform-1',
    anchorId: 'transform.add-sample',
    title: 'Adding Samples to a Test Pack',
    content: `Samples will be added to the selected Pipeline and Test Pack,
              appearing in all connected ones. Deleting a sample removes it
              from all connected Pipelines and Test Packs.`,
    isAsync: true,
  },
  {
    stepId: 'transform-2',
    anchorId: 'transform.synonym-source',
    title: 'Selecting a Synonym Source',
    content: `Selecting a synonym will select the first available test 
              pack and run ingest automatically. Editing the model or changing
               synonyms will rerun ingest.`,
    isAsync: true,
  },
  {
    stepId: 'transform-3',
    anchorId: 'transform.test-pack',
    title: 'Selecting a Test Pack',
    content: `The first available test pack is selected and run automatically.
              Editing the model or changing test packs will rerun ingest.`,
    isAsync: true,
  },
];
