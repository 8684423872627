import { MONACO_DARK_THEME, MONACO_LIGHT_THEME } from './monaco-themes.config';
import {
  MONACO_LEGACY_LIGHT_THEME,
  MONACO_LEGACY_DARK_THEME,
} from './monaco-themes-legacy.config';

export const TM_LANGUAGE_JSON_FILENAME = 'rosetta.tm-language.json';
export const TOP_LEVEL_SCOPES: string[] = [
  'comment.block.documentation.rosetta',
];

export interface MonacoThemeConfig {
  isDark: boolean;
  themeName: string;
}

export enum MonacoThemes {
  ROSETTA = 'rosetta',
  LEGACY = 'legacy',
}

export const MONACO_THEMES = {
  [MonacoThemes.ROSETTA]: {
    light: MONACO_LIGHT_THEME,
    dark: MONACO_DARK_THEME,
  },
  [MonacoThemes.LEGACY]: {
    light: MONACO_LEGACY_LIGHT_THEME,
    dark: MONACO_LEGACY_DARK_THEME,
  },
};
