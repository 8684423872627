// Portal compatibility string conversion
// TODO: call the correct path in Portal and remove this replace

export const redirectPathSanitizer = (path: string): string[] | null =>
  !path
    ? null
    : path
        .replace(
          /\/system\/read-only(\-COMMON-DOMAIN-MODEL)?/,
          '/workspaces/read-only-COMMON-DOMAIN-MODEL'
        )
        .replace(/^\//, '')
        .replace(/\/$/, '')
        .split('/');
