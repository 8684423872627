import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG, StorageKey } from '@configs';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService<K = unknown> {
  constructor(
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig,
    @Inject(WA_LOCAL_STORAGE) private _localStorage: Storage
  ) {}

  get length() {
    return this._localStorage.length;
  }

  setItem<T = K>(localStorageKey: StorageKey, state: T) {
    this._localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getItem<T = K>(localStorageKey: StorageKey): T | null {
    const item = this._localStorage.getItem(localStorageKey);
    return item && JSON.parse(item);
  }

  removeItem(localStorageKey: StorageKey) {
    this._localStorage.removeItem(localStorageKey);
  }

  removeItems(localStorageKey: StorageKey[]) {
    localStorageKey.forEach(key => {
      this.removeItem(key);
    });
  }

  clear({
    includeWhiteList,
    condition,
  }: {
    includeWhiteList?: boolean;
    condition?: (key: string) => boolean;
  } = {}) {
    if (includeWhiteList) {
      this._localStorage.clear();
      return;
    }

    const keysToRemove = Array.from({
      length: this._localStorage.length,
    }).reduce<StorageKey[]>((acc, _, idx) => {
      const key = this._localStorage.key(idx) as StorageKey;
      if (key && this._config.storage.whitelist.indexOf(key) < 0) {
        if (!condition || condition(key)) {
          return acc.concat(key);
        }
      }
      return acc;
    }, []);
    this.removeItems(keysToRemove);
  }
}
