import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appHoverClass]',
})
export class HoverClassDirective {
  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {}

  @Input({ required: true, alias: 'appHoverClass' }) hoverClass: string;

  @HostListener('mouseover') onMouseOver(): void {
    this._renderer.addClass(this._el.nativeElement, this.hoverClass);
  }

  @HostListener('mouseout') onMouseOut(): void {
    this._renderer.removeClass(this._el.nativeElement, this.hoverClass);
  }
}
