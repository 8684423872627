import { inject, InjectionToken } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { InternalLink } from '@configs';

export const LOGGED_IN_FALLBACK = new InjectionToken<UrlTree>(
  'LoggedInFallBack',
  {
    factory: () => {
      const router = inject(Router);
      return router.createUrlTree([InternalLink.WORKSPACE_MANAGER]);
    },
  }
);

export const DOCUMENT_FALLBACK = new InjectionToken<UrlTree>(
  'DocumentFallback',
  {
    factory: () => {
      const router = inject(Router);
      return router.createUrlTree([InternalLink.DOCUMENTS]);
    },
  }
);

export const NOT_AUTHENTICATED_FALLBACK = new InjectionToken<UrlTree>(
  'NotAuthFallback',
  {
    factory: () => {
      const router = inject(Router);
      return router.createUrlTree(['/']);
    },
  }
);

export const SERVER_ERROR_FALLBACK = new InjectionToken<UrlTree>(
  'ServerErrorFallback',
  {
    factory: () => {
      const router = inject(Router);
      return router.createUrlTree([InternalLink.ERROR, '503']);
    },
  }
);
