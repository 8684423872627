import { PackagePlan } from '@models';

export interface FormConfig {
  formId: string;
  endDate?: string;
}

const offerOne: FormConfig = {
  endDate: '2023-02-28',
  formId: '10475f6e-b2d4-4120-9a7a-3a9cac0234e8',
};

export const HBSPT_CONFIG = {
  portalId: '4975376',
  region: 'na1',
  scriptSrc: 'https://js.hsforms.net/forms/embed/v2.js',
  standardFormId: 'ef236667-fe58-4bd9-8ce7-94782259aea8',
  offer: {
    [PackagePlan.Community + '-' + false]: offerOne,
    [PackagePlan.Essentials + '-' + true]: offerOne,
  },
} as const;
