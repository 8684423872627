<div
  class="collapsible-panel-closed"
  *ngIf="isClosed$ | async"
  (click)="toggle()"
>
  <app-collapsible-panel-toggle-button class="small-panel-area-button" />
  <small class="collapsible-panel-closed-title mb-0">{{ panelTitle }}</small>
</div>
<div class="collapsible-panel-opened" [@fadeIn]="isOpen$ | async">
  <ng-content select="app-collapsible-panel-header"></ng-content>
  <ng-content select="app-collapsible-panel-body"></ng-content>
</div>
