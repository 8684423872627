import { NotificationMessagePayload } from '@models';
import { createAction, props } from '@ngrx/store';

export const notificationBell = createAction(
  '[Notification Socket] Bell Notification',
  props<{ payload: NotificationMessagePayload }>()
);

export const notificationError = createAction('[Notification Socket] Error');

const notificationWebsocketMap = {
  'Bell Notification': notificationBell,
};

export const socketActionFactory = (
  type: string,
  payload: NotificationMessagePayload
) => {
  const action =
    notificationWebsocketMap[type as keyof typeof notificationWebsocketMap];
  if (action) {
    return action({ payload });
  }
  return null;
};
