import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InternalLink } from '@configs';
import { CustomNavigationExtras, CustomParams } from '@models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RosettaNavigationService {
  constructor(
    private _store: Store,
    private _router: Router
  ) {}

  to(path: string[] = [], extras: CustomNavigationExtras = {}): void {
    const defaultExtra: CustomNavigationExtras = {
      queryParamsHandling: 'merge',
    };
    this._router.navigate(path, { ...defaultExtra, ...extras });
  }

  workspaceManager(): void {
    this.to([InternalLink.WORKSPACE_MANAGER], {
      queryParamsHandling: null,
    });
  }

  closeBottomPanel(): void {
    const queryParams: CustomParams = {
      topPanel: true,
      bottomPanel: false,
    };
    this._includeCurrentWorkspace([], { queryParams });
  }

  updateBottomPanel(path: string[]): void {
    const queryParams: CustomParams = {
      bottomPanel: true,
    };
    this._includeCurrentWorkspace(path, { queryParams });
  }

  openTopAndBottom(): void {
    const queryParams: CustomParams = {
      topPanel: true,
      bottomPanel: true,
    };
    this.to([], { queryParams });
  }

  maximiseBottomPanel(): void {
    const queryParams: CustomParams = {
      topPanel: false,
      bottomPanel: true,
    };
    this.to([], { queryParams });
  }

  gotoProjectAdmin(modelId: string): void {
    this.to([InternalLink.PROJECT_ADMIN], {
      queryParams: { 'model-id': modelId },
    });
  }

  private _includeCurrentWorkspace(
    path: string[] = [],
    extras?: CustomNavigationExtras
  ): void {
    this._store
      .select(WorkspaceSelectors.selectWorkspaceId)
      .pipe(first())
      .subscribe(({ name }) => {
        this.to([InternalLink.WORKSPACES, name, ...path], extras);
      });
  }
}
