import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { DataStorageWarningComponent } from './data-storage-warning.component';

@NgModule({
  declarations: [DataStorageWarningComponent],
  imports: [FontsModule, CommonModule],
  exports: [DataStorageWarningComponent],
})
export class DataStorageWarningModule {}
