import { Injectable, OnDestroy } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FileTreeEvent, FileTreeNode, WorkspaceItemState } from '@models';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { FileTreeLeafNodeComponent } from '@shared/modules/file-tree/file-tree-leaf-node/file-tree-leaf-node.component';
import { FileTreeControl } from '@shared/modules/file-tree/file-tree.control';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { createWorkspaceItemNamespaceTree } from '@store/workspace/selectors/workspace.selector.helper';
import { isElementVerticallyInView } from '@utils';
import { NgScrollbar } from 'ngx-scrollbar';
import { map, Observable, skip, Subscription } from 'rxjs';

@Injectable()
export class FileTreeService implements OnDestroy {
  constructor(private _store: Store) {}

  dataSource = new MatTreeNestedDataSource<FileTreeNode>();
  treeControl = new FileTreeControl(node => node.children);

  private _sub = new Subscription();
  private _nodeMap = new Map<string, FileTreeLeafNodeComponent>();
  private _scrollbar!: NgScrollbar;
  private _currentWorkspaceItemUri: string | undefined;
  private _nodeObserver?: MutationObserver;

  private _currentWorkspaceItemId$ = this._store.select(
    WorkspaceSelectors.selectCurrentWorkspaceItemId
  );

  private _currentWorkspaceId$ = this._store.select(
    WorkspaceSelectors.selectWorkspaceId
  );

  private _uriListWorkspaceItemsWithErrors$ = this._store
    .select(WorkspaceSelectors.selectDiagnostics)
    .pipe(map(diagnostics => Object.keys(diagnostics)));

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  init(
    scrollbar: NgScrollbar,
    workspaceItem$: Observable<WorkspaceItemState[]>
  ) {
    this._scrollbar = scrollbar;
    this._startObservables(workspaceItem$);
  }

  registerNode(fileTreeNode: FileTreeLeafNodeComponent) {
    if (!fileTreeNode.node.uri) {
      return;
    }
    this._nodeMap.set(fileTreeNode.node.uri, fileTreeNode);
  }

  unregisterNode(fileTreeNode: FileTreeLeafNodeComponent) {
    if (!fileTreeNode.node.uri) {
      return;
    }
    this._nodeMap.delete(fileTreeNode.node.uri);
  }

  onClick({ type, node }: FileTreeEvent) {
    if (!node.uri) {
      return;
    }
    const actionMap: Record<FileTreeEvent['type'], TypedAction<any>> = {
      discard: WorkspaceActions.revertWorkspaceItem({
        uri: node.uri,
        name: node.name,
      }),
      diff: WorkspaceActions.selectWorkspaceItem({
        uri: node.uri,
        openDiffEditor: true,
      }),
      open: WorkspaceActions.selectWorkspaceItem({
        uri: node.uri,
      }),
    };

    if (actionMap[type]) {
      this._store.dispatch(actionMap[type]);
    }
  }

  gotoCurrentFile() {
    this._scrollToSelectedNode(this._currentWorkspaceItemUri);
  }

  private _startObservables(workspaceItems$: Observable<WorkspaceItemState[]>) {
    this._sub.add(
      workspaceItems$
        .pipe(map(items => createWorkspaceItemNamespaceTree(items)))
        .subscribe(items => {
          this.dataSource.data = items;
          this.treeControl.setNodes(items);
        })
    );

    this._sub.add(
      this._currentWorkspaceItemId$.subscribe(id => {
        const uri = id?.uri;
        this._currentWorkspaceItemUri = uri;
        this._scrollToSelectedNode(uri);
      })
    );

    this._sub.add(
      this._uriListWorkspaceItemsWithErrors$.subscribe(uris => {
        this.treeControl.setNodeErroredByUris(uris);
      })
    );

    this._sub.add(
      // Skip the first value since it is the initial state
      this._currentWorkspaceId$.pipe(skip(1)).subscribe(() => {
        this.treeControl.clearData();
      })
    );
  }

  private async _scrollToSelectedNode(uri: string | undefined) {
    const fileTreeNode = uri && this._nodeMap.get(uri);

    if (!this._scrollbar || !fileTreeNode) {
      return;
    }

    // Select and expand the node
    this.treeControl.selectNodeByUri(uri);

    await this._waitForNodeToBeVisible(fileTreeNode.element);

    if (
      !isElementVerticallyInView(
        fileTreeNode.element,
        this._scrollbar.viewport.nativeElement
      )
    ) {
      const top = -(this._scrollbar.nativeElement.clientHeight / 3);
      this._scrollbar.scrollToElement(fileTreeNode.element, { top });
    }
  }

  private _waitForNodeToBeVisible(itemToScrollTo: HTMLElement): Promise<void> {
    return new Promise((resolve, reject) => {
      const parentElement = itemToScrollTo.parentElement?.parentElement;

      if (!parentElement) {
        return reject();
      }

      if (this._nodeObserver) {
        this._nodeObserver.disconnect();
      }

      if (this._isParentNodeVisible(parentElement)) {
        return resolve();
      }

      this._nodeObserver = new MutationObserver(
        (mutations: MutationRecord[]) => {
          mutations.forEach(() => {
            this._nodeObserver?.disconnect();
            resolve();
          });
        }
      );

      // Wait for the parent node class to be updated which means its visible
      this._nodeObserver.observe(parentElement, {
        attributes: true,
        childList: false,
        characterData: false,
      });
    });
  }

  private _isParentNodeVisible(
    parent: HTMLElement | null | undefined
  ): boolean {
    return !parent?.classList.contains('file-tree-invisible');
  }
}
