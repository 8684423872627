import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { UpgradeIconComponent } from './upgrade-icon.component';

@NgModule({
  declarations: [UpgradeIconComponent],
  imports: [CommonModule, FontsModule, MatTooltipModule],
  exports: [UpgradeIconComponent],
})
export class UpgradeIconModule {}
