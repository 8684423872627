import { Injectable } from '@angular/core';
import { HEART_BEAT, HEART_BEAT_INTERVAL } from '@configs';
import { createUrl } from '@utils';
import { Subscription, tap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private _sub?: Subscription;
  private _webSocketList = new Set<WebSocket>();

  startHeartbeat() {
    if (!this._sub) {
      this._sub = timer(0, HEART_BEAT_INTERVAL)
        .pipe(tap(() => this._sendHeartbeat()))
        .subscribe();
    }
  }

  stopHeartbeat() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = undefined;
    }
  }

  create(path: string): WebSocket {
    const webSocket = new WebSocket(createUrl(path, { ws: true }));
    this._webSocketList.add(webSocket);
    return webSocket;
  }

  private _sendHeartbeat() {
    this._webSocketList.forEach(socket => {
      // Socket state is equal to Closing (2) or Closed (3) deregister
      if (socket.readyState >= WebSocket.CLOSING) {
        this._webSocketList.delete(socket);
      } else if (socket.readyState === WebSocket.OPEN) {
        socket.send(HEART_BEAT);
      }
    });
  }
}
