import { Directive, Input, TemplateRef } from '@angular/core';
import { DiagnosticGroup } from '@shared/modules/diagnostic-panel/diagnostic.model';

@Directive({
  selector: '[appDiagnosticPanelGroupDef]',
})
export class DiagnosticPanelGroupDefDirective {
  @Input('appDiagnosticPanelGroupDef') groupDef!: DiagnosticGroup['def'];

  constructor(public templateRef: TemplateRef<unknown>) {}
}
