import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl,
    form: FormGroupDirective | NgForm
  ): boolean {
    return (control.dirty && form.invalid) || false;
  }
}
