<app-filtered-list
  [data]="(domainModels$ | async) ?? []"
  [filterFunc]="filterFunc"
>
  <ng-template #staticContent>
    <mat-checkbox
      (change)="$event ? masterToggle($event) : null"
      [checked]="isAllSelected()"
      [indeterminate]="isAnySelected()"
    >
      All
    </mat-checkbox>
  </ng-template>
  <ng-template #rowContent let-domainModel>
    <mat-checkbox
      appStopPropagation
      (change)="projectToggle(domainModel)"
      [checked]="isChecked(domainModel)"
    >
      <app-project-row [model]="domainModel" />
    </mat-checkbox>
  </ng-template>
</app-filtered-list>
