import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-curve-bottom-svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<svg width="100%" viewBox="0 0 470.98 121.14">
    <path
      fill="#EDF3CD"
      d="M0 65.24s21.09-25.41 25.22-29.85C31.3 28.83 46 14.22 62.64 6.82c20.88-9.29 42.09-7.66 59.82-3.52C134.91 6.2 471 114.9 471 114.9v6.24L0 108.48Z"
    />
    <path
      fill="#BFD858"
      d="M0 77.86S21.09 52.45 25.22 48C31.3 41.44 46 26.78 62.64 19.44c17.19-7.59 37.47-6.94 53.84-3.3C129 18.91 471 114.9 471 114.9v6.24H0Z"
    />
  </svg>`,
  styles: [
    `
      svg {
        display: block;
      }
    `,
  ],
})
export class CurveBottomComponent {}
