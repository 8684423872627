import { Injectable } from '@angular/core';
import { NotificationWebsocketService } from '@core/modules/notifications/notification-websocket.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '@store/.';
import { tap } from 'rxjs';

@Injectable()
export class NotificationWebsocketEffects {
  constructor(
    private _actions$: Actions,
    private _websocketService: NotificationWebsocketService
  ) {}

  login$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(() => this._websocketService.connect())
      );
    },
    {
      dispatch: false,
    }
  );

  logout$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this._websocketService.disconnect())
      );
    },
    {
      dispatch: false,
    }
  );
}
