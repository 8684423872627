import { Writeable } from '@utils';

export namespace ReleaseNotesApi {
  export interface ReleaseNote {
    readonly author: string;
    readonly bodyAsHtml: string;
    readonly bodyAsText: string;
    readonly bodyRaw: string;
    readonly createdAt: string;
    readonly id: number;
    readonly publishedAt: string;
    readonly tag: string;
  }
}

export type ReleaseNote = Writeable<ReleaseNotesApi.ReleaseNote> & {
  new: boolean;
};

export const ReleaseNoteMapper = (lastSeenVersion?: string) => {
  let newNote = true;
  return (note: ReleaseNotesApi.ReleaseNote): ReleaseNote => {
    if (newNote && note.tag === lastSeenVersion) {
      newNote = false;
    }
    return {
      ...note,
      new: newNote,
    };
  };
};
