import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InternalLink, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _errorHandlerService: ErrorHandlerService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.includes(this._config.resourcePaths.status) ||
      req.url.includes(this._config.resourcePaths.statusCore) ||
      this._router.url === InternalLink.WORKSPACE_MANAGER
    ) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          this._errorHandlerService.handleError(response);
        }

        return throwError(() => response);
      })
    );
  }
}
