<div>
  <button
    mat-icon-button
    class="edit"
    (click)="editRow()"
    *ngIf="!editMode; else submitAndCancel"
  >
    <fa-icon size="sm" icon="pen" />
  </button>
</div>

<ng-template #submitAndCancel>
  <button mat-icon-button class="submit" (click)="submit()">
    <fa-icon size="sm" icon="check" />
  </button>
  <button mat-icon-button class="cancel" (click)="cancel()">
    <fa-icon size="sm" icon="times" />
  </button>
</ng-template>
