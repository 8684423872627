import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FontsModule } from '@app/fonts/fonts.module';
import { HubspotModule } from '../hubspot/hubspot.module';
import { UpgradePlanDialogComponent } from './upgrade-plan-dialog.component';

@NgModule({
  declarations: [UpgradePlanDialogComponent],
  imports: [
    CommonModule,
    FontsModule,

    // UI Modules
    HubspotModule,

    // Material
    MatButtonModule,
    MatDialogModule,
  ],
  exports: [UpgradePlanDialogComponent],
})
export class UpgradePlanDialogModule {}
