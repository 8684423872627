<fa-icon class="snack-bar-icon error" icon="exclamation-triangle" size="lg" />
<fa-icon class="snack-bar-icon warning" icon="exclamation-circle" size="lg" />
<fa-icon class="snack-bar-icon success" icon="check-circle" size="lg" />

<p class="snack-bar-msg">{{ data.message | stripHtml }}</p>

<button
  class="action-btn"
  color="primary"
  mat-button
  *ngIf="data.button"
  (click)="close(snackBarActionType.LEGACY)"
>
  {{ data.button }}
</button>
<button
  class="action-btn"
  mat-button
  *ngIf="data.snackBarAction"
  (click)="close(snackBarActionType.ACTION)"
>
  {{ data.snackBarAction.label }}
</button>

<button
  class="dismiss-btn"
  mat-icon-button
  (click)="close(snackBarActionType.NONE)"
  tabindex="-1"
>
  <fa-icon icon="times" />
</button>
