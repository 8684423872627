import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { ProjectLogoModule } from '../project-logo/project-logo.module';
import { ProjectRowComponent } from './project-row.component';

@NgModule({
  declarations: [ProjectRowComponent],
  imports: [CommonModule, FontsModule, ProjectLogoModule],
  exports: [ProjectRowComponent],
})
export class ProjectRowModule {}
