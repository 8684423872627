import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { first } from 'rxjs';

export const closeWorkspaceGuard: CanDeactivateFn<unknown> = (
  _component: unknown,
  _currentRoute: ActivatedRouteSnapshot,
  _currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
) => {
  const store = inject(Store);
  store
    .select(WorkspaceSelectors.selectWorkspaceItemReadOnly)
    .pipe(first())
    .subscribe(readOnly => {
      if (routerContainsParam('workspaceName', nextState?.root)) {
        store.dispatch(
          WorkspaceActions.saveWorkspaceThenCloseWorkspace({
            unsetLastWorkspace: nextState?.url !== '/login',
            saveBeforeClose: !readOnly,
          })
        );
      }
    });
  return true;
};

const routerContainsParam = (
  paramName: string,
  state?: ActivatedRouteSnapshot
): boolean => {
  let closeWorkspace = true;

  while (closeWorkspace && state?.firstChild) {
    if (state.params[paramName] !== undefined) {
      closeWorkspace = false;
    }
    state = state.firstChild;
  }

  return closeWorkspace;
};
