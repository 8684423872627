import {
  DiagnosticGroup,
  DiagnosticGroupDef,
  DiagnosticResultKey,
} from '@shared/modules/diagnostic-panel/diagnostic.model';

export type DiagnosticSummary = Partial<Record<DiagnosticGroupDef, number>>;

export type DiagnosticsSummaryData = Map<
  DiagnosticResultKey,
  DiagnosticSummary
>;

export const toDiagnosticChartData = (
  diagnosticGroups: DiagnosticGroup[]
): DiagnosticSummary => {
  if (!diagnosticGroups?.length) {
    return undefined;
  }
  return diagnosticGroups.reduce<DiagnosticSummary>(
    (acc, group) => ({
      ...acc,
      [group.def]: group.actual,
    }),
    {}
  );
};
