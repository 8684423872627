import { TaskStatusKey } from '@models';
import { createAction, props } from '@ngrx/store';

export const taskStarted = createAction(
  '[Task Service] Task Started',
  props<{ name: TaskStatusKey }>()
);
export const taskFinished = createAction(
  '[Task Service] Task Finished',
  props<{ name: TaskStatusKey; payload: any }>()
);

export const submitTask = createAction(
  '[Task Service] Submit Task',
  props<{ data: any }>()
);
