<div class="node-body text-ellipsis pl-4">
  <fa-icon
    class="node-file-icon ml-4 mr-4"
    [icon]="['far', 'file-code']"
    [fixedWidth]="true"
  />
  <span class="text-ellipsis">{{ node.name }}</span>
  <fa-icon
    *ngIf="!('ros.engine.access' | hasAbility) && node.info"
    class="info"
    icon="info-circle"
    [matTooltip]="node.info"
  />
</div>

<div
  class="node-actions pr-8"
  appStopPropagation
  *ngIf="showOptions && node.modified"
>
  <button
    type="button"
    mat-icon-button
    class="discard-changes"
    *ngIf="node.lockState !== 'locked'"
    matTooltip="Discard Changes"
    (click)="onClick('discard')"
  >
    <fa-icon icon="undo" size="xs" />
  </button>

  <button
    type="button"
    mat-icon-button
    class="diff"
    matTooltip="Show Diff"
    (click)="onClick('diff')"
  >
    <fa-layers size="xs">
      <fa-layers-text content="M" transform="grow-4" />
    </fa-layers>
  </button>
</div>

<fa-icon
  class="node-lock-icon mr-16"
  *ngIf="node.lockState === 'locked'"
  icon="lock"
  [fixedWidth]="true"
/>

<fa-icon
  class="node-unlock-icon mr-16"
  *ngIf="node.lockState === 'unlocked'"
  icon="lock-open"
  [fixedWidth]="true"
  matTooltip="This overrides a parent namespace"
/>
