import { InjectionToken } from '@angular/core';
import { TaskNotification } from '@models';

export const TASK_MESSAGE_LISTENERS = new InjectionToken<TaskListeners>(
  'taskMessageListeners'
);

export interface TaskListeners<T = TaskNotification> {
  action(task: T): void;
}
