import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (e: unknown): string => {
  const errorMap: Record<number, string> = {
    400: 'Bad Request: The request could not be understood or was missing required parameters.',
    401: 'Unauthorized: Authentication failed or user lacks necessary permissions.',
    403: 'Forbidden: The server understood the request, but it refuses to authorize it.',
    404: 'Not Found: The requested resource could not be found on the server.',
    500: 'Internal Server Error: Something went wrong on the server side.',
    501: 'Not Implemented: The server does not support the functionality required to fulfil the request.',
  };

  if (e instanceof HttpErrorResponse) {
    const errorMessage = errorMap[e.status] || 'Unknown Error';
    return errorMessage;
  }

  return 'Oops! Something went wrong';
};

export enum ErrorCode {
  ERROR_404 = 404,
  ERROR_501 = 501,
  ERROR_503 = 503,
  ERROR_410 = 410,
}
