import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-warning-triangle',
  template: `<fa-icon class="warning-triangle" [icon]="icon" />`,
  styleUrls: ['./warning-triangle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningTriangleComponent {
  @Input() icon: IconProp = 'exclamation-triangle';
}
