import { Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';
import { addNumericSuffixToMakeUnique, deepCopy } from '@utils';
import { uniqBy } from 'lodash-es';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { JsonSample, TestPackSampleId } from '../models';
import { TestPackDef } from '../models/test-pack-def.model';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { TransientSample } from '../models/transient-sample.model';
import { TransientTestPack } from '../models/transient-test-pack.model';

export type TransactionSample = TransientSample & { transactionId?: number };

@Injectable()
export class TransformLocalStorageService extends BaseStorageService<TransactionSample> {
  override readonly namespace = 'transform';

  /*
  Find all samples and return all unique testPackDefs
  */
  getTestPacks(pipelineId: string): Observable<TestPackDef[]> {
    return this.getAll(pipelineId).pipe(
      map(samples =>
        uniqBy(
          samples.map(sample => sample.testPackDef),
          'id'
        )
      )
    );
  }

  /*
  Find all samples for a pipeline/testPack
   */
  getSamples({
    pipelineDef,
    testPackDef,
  }: TestPackGridSelection): Observable<TransientTestPack | undefined> {
    return this.getAll(
      this._createStorageKey(pipelineDef.id, testPackDef.id)
    ).pipe(
      map(samples => {
        if (samples.length === 0) {
          return undefined;
        }
        return {
          testPackName: testPackDef.name,
          samples: samples.map(({ sample }) => sample),
        };
      })
    );
  }

  /*
  Find a single sample in a pipeline/testPack
  */
  getSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<TransactionSample | undefined> {
    return this.get(
      this._createStorageKey(pipelineDef.id, testPackDef.id, sampleId)
    );
  }

  /*
  Add a single sample to pipeline/testPack
   */
  addSample(
    selection: TestPackGridSelection,
    sampleJson: JsonSample,
    transactionId?: number
  ): Observable<TestPackSampleId> {
    const { pipelineDef, testPackDef } = selection;
    return this._uniqueSampleJson(selection, sampleJson).pipe(
      switchMap(uniqueSampleJson =>
        this._set(
          this._createStorageKey(
            pipelineDef.id,
            testPackDef.id,
            uniqueSampleJson.sampleDef.id
          ),
          {
            testPackDef,
            sample: uniqueSampleJson,
            transactionId,
          }
        ).pipe(
          map(() => ({
            selection,
            sampleDef: uniqueSampleJson.sampleDef,
          }))
        )
      )
    );
  }

  /*
  Delete single sample from pipeline/testPack
  */
  deleteSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this.delete(
      this._createStorageKey(pipelineDef.id, testPackDef.id, sampleId)
    );
  }

  deleteDownstreamSamples(
    selection: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this.getSample(selection, sampleId).pipe(
      switchMap(sample => this._getTransactionGroup(sample?.transactionId)),
      switchMap(samples => {
        if (!samples) {
          return of(undefined);
        }
        return forkJoin(
          samples.map(({ sample }) => this.delete(sample.sampleDef.id))
        );
      }),
      map(() => undefined)
    );
  }

  private _getTransactionGroup(
    transactionId?: number
  ): Observable<TransactionSample[] | undefined> {
    if (!transactionId) {
      return of(undefined);
    }
    return this.getAll().pipe(
      map(samples =>
        samples.filter(sample => sample.transactionId === transactionId)
      )
    );
  }

  private _uniqueSampleJson(
    testPackGridSelection: TestPackGridSelection,
    sampleJson: JsonSample
  ): Observable<JsonSample> {
    return this.getSamples(testPackGridSelection).pipe(
      map(transientTestPacks => {
        const sampleJsonCopy = deepCopy(sampleJson);
        const uniqueName = addNumericSuffixToMakeUnique(
          sampleJsonCopy.sampleDef.name,
          name =>
            !transientTestPacks
              ? false
              : transientTestPacks.samples.some(
                  sample => sample.sampleDef.name === name
                )
        );

        sampleJsonCopy.sampleDef = {
          // NOTE: The id structure needs to be the same as the backend or else things break!
          // TODO: Fix this so that the backend uses the front ID
          id: `client:${uniqueName}`.replace(/\s+/g, '-').toLowerCase(),
          name: uniqueName,
        };

        return sampleJsonCopy;
      })
    );
  }

  private _createStorageKey(
    pipelineDefId: string,
    testPackDefId: string,
    sampleId?: string
  ): string {
    return `${pipelineDefId}-${testPackDefId}${sampleId ? `:${sampleId}` : ''}`
      .replace(/\s+/g, '-')
      .toLowerCase();
  }
}
