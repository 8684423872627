import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { ProjectCardMenuComponent } from './project-card-menu.component';

@NgModule({
  declarations: [ProjectCardMenuComponent],
  imports: [CommonModule, FontsModule, MatButtonModule, MatMenuModule],
  exports: [ProjectCardMenuComponent],
})
export class ProjectCardMenuModule {}
