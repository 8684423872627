import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ANIMATION_EASING_FAST } from '@configs';

export const SlideInOutAnimations = trigger('slideInOut', [
  transition('hide <=> show', animate(ANIMATION_EASING_FAST)),
  state(
    'void, hide',
    style({
      marginLeft: '-{{ leftIndent }}px',
    }),
    { params: { leftIndent: '28' } }
  ),
  state(
    'show',
    style({
      marginLeft: '8px',
    })
  ),
]);
