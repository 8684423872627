import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { editorDefaultOptions } from '@configs';
import { EditorStateService } from '@core/services/editor-state.service';
import { LanguageServerService } from '@core/services/language-server.service';
import { WorkspaceItem } from '@models';
import { NavigateDirection } from '@features/workspace/models/rosetta-core.model';
import { LANGUAGE_ROSETTA_ORIGINAL } from '@workspace-design/textual/models/editor.const';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  distinctUntilChanged,
  filter,
  first,
  Subscription,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'app-diff-editor',
  template: '',
  styleUrls: ['./diff-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaDiffEditorComponent implements AfterViewInit, OnDestroy {
  constructor(
    private _languageServerService: LanguageServerService,
    private _diffEditorContainer: ElementRef,
    private _editorStateService: EditorStateService,
    private _store: Store
  ) {}

  private _sub = new Subscription();
  private _modifiedUri?: string;
  private _editor$ = this._languageServerService.editorSubject$.pipe(
    filter(isNotNull),
    distinctUntilChanged((prev, next) => prev.getId() === next.getId())
  );

  ngAfterViewInit(): void {
    this._languageServerService.loadMonaco().then(() => {
      this._initComponent();
      this._setupListeners();
    });
  }

  ngOnDestroy(): void {
    if (this._languageServerService.diffEditor) {
      this._modifiedUri &&
        this._editorStateService.save(
          this._languageServerService.diffEditor,
          this._modifiedUri
        );
      this._languageServerService.diffEditor.setModel(null);
      this._languageServerService.diffEditor.dispose();
      this._languageServerService.diffEditor = undefined;
    }
    if (this._languageServerService.diffNav) {
      this._languageServerService.diffNav.dispose();
      this._languageServerService.diffNav = undefined;
    }

    this._sub.unsubscribe();
  }

  private _setupListeners(): void {
    this._sub.add(this._editor$.subscribe(editor => this._addActions(editor)));

    this._sub.add(
      this._store
        .select(WorkspaceSelectors.selectCurrentWorkspaceItemUri)
        .pipe(
          filter(isNotNull),
          switchMap(uri =>
            this._store
              .select(WorkspaceSelectors.selectWorkspaceItemFromUri(uri))
              .pipe(first(isNotNull))
          )
        )
        .subscribe(item => {
          this._openDiffEditor(item);
        })
    );
  }

  private _openDiffEditor(item: WorkspaceItem): void {
    if (!this._languageServerService.diffEditor) {
      throw new Error('Rosetta: Missing diff editor!');
    }

    const { uri, originalUri } = item.info;

    const modified = monaco.editor.getModel(monaco.Uri.parse(uri));
    let original = monaco.editor.getModel(monaco.Uri.parse(originalUri || ''));

    if (!original) {
      original = monaco.editor.createModel(
        item.originalContents,
        LANGUAGE_ROSETTA_ORIGINAL,
        monaco.Uri.parse(item.info.originalUri)
      );
    }

    if (!modified || !original) {
      return;
    }

    this._modifiedUri = modified.uri.toString();
    this._languageServerService.diffEditor.setModel({
      original,
      modified,
    });

    const hasRestored = this._editorStateService.restore(
      this._languageServerService.diffEditor,
      uri
    );

    this._languageServerService.diffNav = monaco.editor.createDiffNavigator(
      this._languageServerService.diffEditor,
      {
        followsCaret: true,
        ignoreCharChanges: false,
        alwaysRevealFirst: !hasRestored,
      }
    );
  }

  private _initComponent(): void {
    if (this._languageServerService.diffEditor) {
      return;
    }
    this._store
      .select(WorkspaceSelectors.selectWorkspaceItemReadOnly)
      .pipe(first())
      .subscribe(readOnly => {
        this._languageServerService.diffEditor = monaco.editor.createDiffEditor(
          this._diffEditorContainer.nativeElement,
          {
            ...editorDefaultOptions,
            originalEditable: false,
            renderSideBySide: true,
            readOnly,
          }
        );
      });
  }

  private _addActions(editor: monaco.editor.IStandaloneCodeEditor): void {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    const keyMod = isMacLike ? monaco.KeyMod.CtrlCmd : monaco.KeyMod.Alt;

    editor.addAction({
      id: 'editor.action.nextDiff',
      label: 'Next diff',
      keybindings: [keyMod | monaco.KeyCode.DownArrow],
      run: () =>
        this._languageServerService.diffNavigate({
          direction: NavigateDirection.Next,
          modifiedItems: [],
        }),
    });

    editor.addAction({
      id: 'editor.action.prevDiff',
      label: 'Prev diff',
      keybindings: [keyMod | monaco.KeyCode.UpArrow],
      run: () =>
        this._languageServerService.diffNavigate({
          direction: NavigateDirection.Previous,
          modifiedItems: [],
        }),
    });
  }
}
