import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  notificationError,
  socketActionFactory,
} from '@core/modules/notifications/store/notification-websocket.actions';
import { isNotificationMessage } from '@models/notification.model';
import { Store } from '@ngrx/store';
import { createUrl } from '@utils';

//TODO: need to work out a way to test this. Ideally with a shared WebSocket wrapper that we use across the whole app
@Injectable({
  providedIn: 'root',
})
export class NotificationWebsocketService {
  constructor(
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _socket!: WebSocket;

  connect() {
    this._socket = new WebSocket(
      createUrl(this._config.sockets.notifications, { ws: true })
    );

    this._socket.onerror = () => {
      // We need to decide how we want to handle errors
      this._store.dispatch(notificationError());
    };

    this._socket.onclose = () => {
      // Do we want to reconnect when the socket closes unexpectedly?
    };

    this._socket.onopen = () => {};

    this._socket.onmessage = (event: MessageEvent) => {
      if (typeof event.data !== 'string') {
        return event;
      }

      // We should validate the data inbound so we avoid runtime exceptions
      const parsedData = JSON.parse(event.data);
      if (!isNotificationMessage(parsedData)) {
        return null;
      }
      const { type, payload } = parsedData;

      const action = socketActionFactory(type, payload);
      if (action) {
        this._store.dispatch(action);
      }

      return payload;
    };
  }

  send(type: string, payload: any) {
    const socketMessage = JSON.stringify({
      type,
      payload,
    });
    this._socket.send(socketMessage);
  }

  disconnect() {
    // Should we send a disconnection message
    // before we close the socket?
    this._socket?.close(1000);
  }
}
