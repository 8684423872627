<ng-container *appShowSpinner="vm$ | async as vm; flexCentre: true">
  <app-action-panel
    [actionIcon]="['fas', 'arrow-left']"
    tooltip="Go back to transform listing"
    class="action-panel"
    (action)="goBack()"
  >
    <div class="summary-text mr-16">
      <p>
        {{ vm.selection.pipelineDef.name }} /
        <small>{{ vm.selection.testPackDef.name }}</small>
      </p>
      <h3>{{ vm.sampleDef.name }}</h3>
    </div>

    <app-run-status
      class="mr-16"
      [runResultState]="status$ | async"
      (rerun)="rerun()"
    />

    <app-diagnostics-summary
      [diagnosticsSummary]="diagnosticsSummary"
      showExcluded="true"
    />
  </app-action-panel>

  <app-collapsible-panel-group>
    <app-collapsible-panel key="transform-input" panelTitle="Input">
      <app-collapsible-panel-body>
        <ng-container
          *appShowSpinner="
            codeViewInput$ | async as codeViewInput;
            flexCentre: true
          "
        >
          <app-collapsible-panel-header contentAlign="end">
            <app-code-view-switch
              class="panel-header-switch"
              [showNative]="inputEditor.showNative"
              (viewChange)="inputEditor.showNative = $event"
              (download)="inputEditor.downloadNativeCode()"
            />
          </app-collapsible-panel-header>

          <app-code-view
            #inputEditor
            [codeView]="codeViewInput.codeView"
            [modelContent]="true"
            [showNative]="true"
            [showSelectedCodeView]="sampleInput.showSelected"
            [language]="inputSerialisation$ | async"
            [generateId]="false"
            [name]="vm.sampleDef.id + '-input'"
            (clickLinkId)="outputCodeView?.scrollTo($event)"
          />
        </ng-container>
      </app-collapsible-panel-body>
    </app-collapsible-panel>

    <app-collapsible-panel key="transform-output" panelTitle="Output">
      <app-collapsible-panel-body>
        <ng-container
          *appShowSpinner="
            codeViewOutput$ | async as codeViewOutput;
            flexCentre: true
          "
        >
          <app-collapsible-panel-header contentAlign="end">
            <app-code-view-switch
              class="panel-header-switch"
              [showNative]="outputEditor.showNative"
              (viewChange)="outputEditor.showNative = $event"
              (download)="outputEditor.downloadNativeCode()"
            />
          </app-collapsible-panel-header>

          <app-code-view
            #outputEditor
            [codeView]="codeViewOutput.codeView"
            [modelContent]="true"
            [showNative]="true"
            [showSelectedCodeView]="sampleOutput.showSelected"
            [language]="outputSerialisation$ | async"
            [generateId]="false"
            [name]="vm.sampleDef.id + '-output'"
            (clickLinkId)="inputCodeView?.scrollTo($event)"
          />
        </ng-container>
      </app-collapsible-panel-body>
    </app-collapsible-panel>

    <app-collapsible-panel key="transform-diagnostics" panelTitle="Diagnostics">
      <app-collapsible-panel-body>
        <ng-container
          *appShowSpinner="
            diagnosticData$ | async as diagnosticData;
            flexCentre: true
          "
        >
          <app-collapsible-panel-header />
          <app-filter-field
            (inputChanged)="filterDiagnostics($event)"
            [opened]="true"
          />
          <app-diagnostic [data]="diagnosticData" />
        </ng-container>
      </app-collapsible-panel-body>
    </app-collapsible-panel>
  </app-collapsible-panel-group>
</ng-container>
