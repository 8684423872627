import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { HBSPT_CONFIG } from '@configs';

declare const hbspt: any;
let scriptLoaded = false;

@Component({
  selector: 'app-hubspot-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
  host: {
    class: 'hubspot-form',
    id: 'hubspot-form-target',
  },
})
export class HubspotFormComponent implements AfterViewInit {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  @Input() formId?: string;

  ngAfterViewInit() {
    if (!this.formId) {
      throw Error('Rosetta Hubspot: Missing form ID.');
    }

    this._loadScript().then(() => {
      hbspt.forms.create({
        formId: this.formId,
        portalId: HBSPT_CONFIG.portalId,
        region: HBSPT_CONFIG.region,
        target: '#hubspot-form-target',
        isInsideFrame: true, // Remove iFrame
      });
    });
  }

  private async _loadScript() {
    return scriptLoaded
      ? Promise.resolve()
      : new Promise(resolve => {
          scriptLoaded = true;
          const script = document.createElement('script');
          script.src = HBSPT_CONFIG.scriptSrc;
          script.type = 'text/javascript';
          script.onload = resolve;
          this._document.body.appendChild(script);
        });
  }
}
