export enum Task {
  // General
  BspInitialised = 'BspInitialised',
  /* Once per workspace load */ RootTypeCache = 'RootTypeCache',
  PojoCompilation = 'PojoCompilation',
  StaticJavaCompilation = 'StaticJavaCompilation',
  GridViewGeneration = 'GridViewGeneration',
  ExecutionEngineInitialisation = 'ExecutionEngineInitialisation',

  // Panel specific tasks
  IngestionExport = 'IngestionExport',
  JsonValidation = 'JsonValidation',
}
