import { ErrorStateMatcher } from '@angular/material/core';

import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class DirtyFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    _form: FormGroupDirective | NgForm | null
  ): boolean {
    return (control?.dirty && control?.invalid) || false;
  }
}
