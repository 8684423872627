<div class="theme-bg"></div>
<app-curve-top-svg />
<app-curve-bottom-svg />

<app-theme-toggle />

<ng-content />
<router-outlet />

<div class="spacer"></div>

<footer>
  <ul>
    <li>
      <a
        class="font-text-link"
        [href]="externalLink.PRIVACY_STATEMENT"
        target="_blank"
        >Privacy Statement</a
      >
    </li>
    <li>
      <a
        class="font-text-link"
        [routerLink]="internalLink.TERMS_OF_SERVICE"
        routerLinkActive="active"
        >Terms of Service</a
      >
    </li>
    <li>
      <a
        class="font-text-link"
        [routerLink]="internalLink.SUBSCRIPTION_FORM"
        routerLinkActive="active"
        >Subscription Form</a
      >
    </li>
    <li>
      <a
        class="font-text-link"
        [routerLink]="internalLink.AVAILABILITY_AND_SUPPORT"
        routerLinkActive="active"
        >Availability and Support</a
      >
    </li>
  </ul>
</footer>
